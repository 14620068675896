import "./App.css";
import React, { useState, useEffect } from "react";
import macbook_borrower from "../../../assets/img/macbook_borrower.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import SgIcon from "./img/197496.png";
import CloseIcon from "@mui/icons-material/Close";
import singpassLogo from "./img/singpass_logo.png";
import myInfoLogo from "./img/myinfo_logo.png";
import bank_image from "../../../assets/img/bank_image.png";
import IphoneImage from "./img/iphone_mockup.png";
import verified from "../../../assets/img/verified.png";
import disbursement from "../../../assets/img/macbook_mockup2.png";
import mutual_agreement from "../../../assets/img/mutual_agreement.png";
import one from "../../../assets/img/one.png";
import two from "../../../assets/img/two.png";
import three from "../../../assets/img/three.png";
import four from "../../../assets/img/four.png";
import { Modal, Button, message } from "antd";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";
import HomeHeader from "./Headesr.jsx";
import singpassmobilepage from "../../../assets/img/Singpasslogin-screenshot.png";

const Homepage = () => {
  // const navigate = useHistory();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
    }, 2500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer:
        "Premelo is designed for anyone who needs to manage tasks, projects, and teams, including freelancers, small businesses, and large enterprises.",
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 7,
      title: "How much does Premelo cost?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 8,
      title: "What is Workflow?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 9,
      title: "What is Platform Customization?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 10,
      title: "What does the custom domain and hosting do?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 11,
      title: "11.	What is Social Media connect?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 12,
      title: "What is Centralized Messaging system meant?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 13,
      title: "How many projects can I create from Premelo and Premelo Plus? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 14,
      title: "How many tasks can I create per project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 15,
      title: "How many assignees can I assign per task? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 16,
      title: "I can’t use the workflow function why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 17,
      title: "My calendar is not getting updated with the task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 18,
      title: "I am not getting notify by my notification. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 19,
      title: "My profile is not being updated. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 20,
      title: "I am unable to add attachments to a task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
  ];

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [emailSubscription, setEmailSubscription] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailSubscription = async (event) => {
    event.preventDefault();

    try {
      let item = {
        email: emailSubscription,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/guest/subscribeEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      let data;
      if (
        response.ok &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        data = await response.json();
        // Display success message
        message.success(data.message);
        setEmailSubscription("");
      } else {
        // If response is not ok or not JSON, consider it as an email already subscribed case
        message.error("Your email already subscribed!");
        // throw new Error('Your email already subscribed!');
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      message.error(error);
    }
  };
  // Simple email validation function
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle input change
  const handleInputChange = (e) => {
    const email = e.target.value;
    setEmailSubscription(email);
    setIsEmailValid(validateEmail(email));
  };

  const FAQItem = ({ id, title, answer, image }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    const [hoveredState, setHoveredState] = useState(false);

    return (
      <div
        onMouseOver={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
        style={{
          width: "22%",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          marginRight: "2.5%",
          marginBottom: "2.5%",
          cursor: "pointer",
        }}
      >
        <div style={{}} onClick={handleToggle} className="mui-button">
          {/* <IconButton onClick={handleToggle}>   
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton> */}
          <img
            src={image}
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "20px 20px 0px 0px",
              outline: "none",
              border: "none",
            }}
          />
          <div
            style={{
              padding: "0% 5%",
              fontWeight: "bold",
              margin: "10px 0px -5px 0px",
            }}
          >
            {title}
          </div>
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "300",
            padding: "0% 5%",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          {answer.length > 200 ? answer.substring(0, 200) + " ..." : answer}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "300",
              marginTop: "10px",
              marginBottom: "20px",
              textAlign: "right",
              opacity: hoveredState ? 1 : 0,
              background: "#256199",
              color: "white",
              borderRadius: "10px",
              padding: "7px 15px",
              transition: "all 0.3s ease-in-out",
            }}
          >
            Read more ...
          </div>
        </div>
      </div>
    );
  };

  const [privacyBar, setPrivacybar] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
    if (screenWidth > 1200) {
      setPrivacybar(true);
    }
  }, []);
  useEffect(() => {
    if (screenWidth > 1200) {
      setPrivacybar(false);
    }
  }, [screenWidth]);

  return (
    <div style={{ width: "100%", overflowX: "hidden", position: "relative" }}>
      <div
        style={{
          background: "white",
          position: "fixed",
          width: "100%",
          height: "40px",
          display: privacyBar ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: "49",
          background: "rgb(37, 97, 153)",
        }}
      >
        <div
          style={{
            marginLeft: "20px",
            color: "rgba(0,0,0,0.7)",
            fontSize: "14px",
            color: "white",
          }}
        >
          Information is collected in accordance with our Privacy Policy. By
          continuing you agree to our terms.
        </div>
        <CloseIcon
          style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
          onClick={() => setPrivacybar(false)}
        />
      </div>
      {/* Header bar */}
      <div
        style={{
          position: "fixed",
          background: "white",
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
          width: "100%",
          top: privacyBar ? "40px" : "0px",
          zIndex: "50",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "35px",
                color: "#256199",
              }}
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              SmartLend.
            </div>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{ fontWeight: "700", color: "#256199" }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Why SmartLend?
            </div>

            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              News & Events
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Contact Us
            </div>
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Sign In
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "none",
                background: "white",
                padding: "5px 15px 5px 5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={SgIcon}
                style={{ height: "18px", marginRight: "10px" }}
              />
              <div>SG</div>
            </div>
          </div>
        </div> */}
        <HomeHeader />
      </div>

      {/* First section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: screenWidth > 1200 ? "90px" : "140px",
          height: screenWidth > 1200 ? "90vh" : "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: screenWidth > 1200 ? "row" : "column",
            justifyContent: "space-between",
            width: "80%",
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: screenWidth > 1200 ? "45%" : "100%",
              textAlign: "left",
              marginLeft: pageLoad ? "0px" : "-300px",
              opacity: pageLoad ? "1" : "0",
              transition: "all 0.8s ease-in-out",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "40px",
                  fontWeight: "800",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                }}
              >
                Need a
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "40px",
                  fontWeight: "800",
                  color: "#256199",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                  position: "absolute",
                  transition: "0.3s all ease-in-out",
                  opacity: currentIndex === 0 ? 1 : 0,
                  marginLeft:
                    currentIndex === 2
                      ? "-200px"
                      : currentIndex === 1
                      ? "200px"
                      : "0px",
                }}
              >
                Business Loan,
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "40px",
                  fontWeight: "800",
                  color: "#256199",
                  lineHeight: screenWidth > 1200 ? "55px" : "40px",
                  position: "absolute",
                  transition: "0.3s all ease-in-out",
                  opacity: currentIndex === 1 ? 1 : 0,
                  marginLeft:
                    currentIndex === 0
                      ? "-200px"
                      : currentIndex === 2
                      ? "200px"
                      : "0px",
                }}
              >
                Working Capital,
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "30px",
                  fontWeight: "800",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: screenWidth > 1200 ? "55px" : "30px",
                  marginTop: screenWidth > 1200 ? "55px" : "45px",
                }}
              >
                We bring the{" "}
                <span style={{ color: "#256199", fontWeight: 800 }}>
                  Capital
                </span>
              </div>
              <div
                style={{
                  fontSize: screenWidth > 1200 ? "50px" : "30px",
                  fontWeight: "800",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: screenWidth > 1200 ? "55px" : "30px",
                }}
              >
                to you.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  marginTop: "30px",
                }}
              >
                Smart financing for smart businesses. Find your ideal lender with SmartLend.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  marginTop: "10px",
                }}
              >
                Elevate your business's financial potential with SmartLend.
                Simplify your application process seamlessly, unlocking a
                variety of loan possibilities with minimal data input. Your
                choices, your empowerment – and all at no cost. Apply now to
                define the success of your business.
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: "2px solid #256199",
                    background: "white",
                    fontSize: screenWidth > 1200 ? "17px" : "14px",
                    padding: screenWidth > 1200 ? "5px 25px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "30px",
                    fontWeight: "800",
                  }}
                >
                  Apply now
                </div>
                <div
                  className="menu_button"
                  onClick={() => {
                    window.location.href = "https://wa.me/6588288147";
                  }}
                  style={{
                    background: "#256199",
                    color: "white",
                    fontSize: screenWidth > 1200 ? "17px" : "14px",
                    padding: screenWidth > 1200 ? "5px 25px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "30px",
                    fontWeight: "800",
                  }}
                >
                  Talk to an Expert
                </div>
              </div>
              <div
                style={{
                  background: "#2561993A",
                  borderRadius: 10,
                  marginTop: 35,
                  padding: 15,
                  color: "rgba(0,0,0,0.7)",
                  width: "280px",
                }}
              >
                <div style={{ fontWeight: 600 }}>Now enabled by:</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={myInfoLogo}
                    style={{ height: 70, width: 100, objectFit: "cover" }}
                  />
                  <div style={{ height: 50, width: 2, background: "grey" }} />
                  <img
                    src={singpassLogo}
                    style={{ height: 70, width: 100, objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {screenWidth > 1200 ? (
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={macbook_borrower}
                style={{
                  objectFit: "contain",
                  width: "150%",
                  opacity: pageLoad ? "1" : "0",
                  marginRight: pageLoad ? "-200px" : "-500px",
                  transition: "all 0.8s ease-in-out",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: screenWidth > 1200 ? "80px" : "0px",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: "0px 10%",
              marginTop: screenWidth > 1200 ? "30px" : "0px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: screenWidth > 1200 ? "30px" : "0px",
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={one}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "40px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Unlock the Power of Smart Borrowing
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Say goodbye to the headaches of dealing with Relationship
                    Managers and brokers for your business loan needs. We get it
                    – navigating the financing landscape can be a maze. How can
                    you be certain you're securing the best deals?
                    <br />
                    <br />
                    Introducing{" "}
                    <span
                      style={{
                        fontWeight: 800,
                        color: "rgba(0,0,0,1)",
                        fontSize: 17,
                      }}
                    >
                      SMARTLEND
                    </span>
                    . , your compass in the world of SME financing. We've
                    revolutionized the lending game, matching you with
                    alternative lending options tailored for your business. No
                    more guessing, no more endless negotiations.
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: " 2px solid #256199",
                    background: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "15px",
                    padding: "7px 35px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                >
                  Register as Borrower
                </div>
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "40%" : "100%",
                marginTop: screenWidth > 1200 ? "0px" : "70px",
              }}
            >
              <img
                src={verified}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={mutual_agreement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  marginTop: screenWidth > 1200 ? "0px" : "70px",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // marginTop: "30px",
                // alignItems: "flex-start",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      // background: "#256199",
                      // color: "white",
                      // height: "60px",
                      // width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // fontSize: "25px",
                      // borderRadius: "10px",
                      // marginRight: "15px",
                    }}
                  >
                    <img
                      src={two}
                      style={{
                        height: "600px",
                        position: "absolute",
                        objectFit: "contain",
                        marginTop: "-250px",
                        right: "600px",
                      }}
                    />
                    {/* 1 */}
                  </div>
                  <div
                    style={{
                      width: "90%",
                      marginTop: screenWidth > 1200 ? "0px" : "20px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "30px",
                        fontWeight: "800",
                        color: "#256199",
                      }}
                    >
                      Empowering Your Business: Bringing Negotiating Power Back
                      to You!
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,0.8)",
                        marginTop: "5px",
                      }}
                    >
                      Tired of feeling at the mercy of lenders and brokers? We
                      hear you. It's time to take control of your business
                      financing.
                      <br /> <br />
                      SMARTLEND. is here to revolutionize your borrowing
                      experience, putting the negotiating power back where it
                      belongs – in your hands. No more one-sided deals, no more
                      uncertainty.
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    className="menu_button"
                    onClick={() => {
                      history.push("/auth/signin");
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      color: "#256199",
                      border: " 2px solid #256199",
                      background: "white",
                      fontSize: screenWidth > 1200 ? "20px" : "15px",
                      padding: "7px 35px",
                      margin: screenWidth > 1200 ? "0px 0px 0px 0px" : "0px",
                      borderRadius: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Register as Borrower
                  </div>
                  {/* <div
            style={{
              background: "#256199",
              color: "white",
              fontSize: "20px",
              padding: "7px 35px",
              margin: "0px 20px 0px 0px",
              borderRadius: "30px",
            }}
          >
            Be a Lender
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "50px",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "50%" : "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={three}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "-200px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div
                  style={{
                    width: screenWidth > 1200 ? "90%" : "100%",
                    marginTop: screenWidth > 1200 ? "0px" : "20px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Mutual Agreement
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Your Loan Experience Elevated.
                    <br /> <br />
                    Introducing our revolutionary single dashboard, designed to
                    simplify and elevate your loan journey. Now, effortlessly
                    track your loan application progress, monitor monthly
                    installments, and enjoy the convenience of timely reminders
                    for upcoming repayments.
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div
                      className="menu_button"
                      onClick={() => {
                        history.push("/auth/signin");
                        window.scrollTo(0, 0);
                      }}
                      style={{
                        color: "#256199",
                        border: " 2px solid #256199",
                        background: "white",
                        fontSize: screenWidth > 1200 ? "20px" : "15px",
                        padding: "7px 35px",
                        margin: screenWidth > 1200 ? "0px 20px 0px 0px" : "0px",
                        borderRadius: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Register as Borrower
                    </div>
                    {/* <div
style={{
background: "#256199",
color: "white",
fontSize: "20px",
padding: "7px 35px",
margin: "0px 20px 0px 0px",
borderRadius: "30px",
}}
>
Be a Lender
</div> */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: screenWidth > 1200 ? "50%" : "100%" }}>
              <img
                src={disbursement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "120%",
                  objectFit: "contain",
                  marginTop: screenWidth > 1200 ? "-190px" : "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Moving Image Section*/}
      <div
        style={{
          width: "100%",
          marginTop: "50px",
          backgroundColor: "#ADD8E6",
          padding: "70px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            color: "#338BA8",
            fontWeight: "600",
            fontSize: "25px",
            paddingTop: "15px",
          }}
        >
          We Compare Singapore’s Most Reputable Alternative Lenders
        </div>

        <div
          className="move-right"
          style={{ display: "flex", width: "200%", marginTop: "10px" }}
        >
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
          <img
            src={bank_image}
            alt={bank_image}
            style={{
              borderRadius: "30px",
              height: "100px",
              width: "100%",
              marginLeft: "20px",
              objectFit: "cover",
              marginRight: "100px",
            }}
          />
        </div>
      </div>

      {/* Second section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "80px",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "85%" : "90%" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: screenWidth > 1200 ? "55px" : "50px",
              fontWeight: "700",
              color: "rgba(0,0,0,0.8)",
              lineHeight: screenWidth > 1200 ? "100px" : "50px",
            }}
          >
            How SmartLend Works
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: screenWidth > 1200 ? "0px" : "30px",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "70%" : "100%",
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "400",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              <span
                style={{
                  fontWeight: 800,
                  color: "rgba(0,0,0,1)",
                  fontSize: 17,
                }}
              >
                5 Step Seamless application.
              </span>{" "}
              Our goal is to reduce the need for users to manually input
              information that can be obtained through Myinfo Business.
            </div>
          </div>
          <div
            style={{
              padding: "0px 10%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  1
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Register your account:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Create your SmartLend account quickly and securely with 2FA
                    to begin your seamless borrowing journey.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  2
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Apply via Singpass*:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Utilize the convenience of Singpass for a swift and secure
                    application process, ensuring your data is handled with
                    utmost care. Streamline your application by reducing manual
                    information filling, making the process even more
                    convenient.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  3
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Submit basic financial documents:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Attached your business's latest 6 months bank statements and
                    Financial Statements (If not filed with ACRA
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  4
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Submit your Credit Report
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Access your free Personal Credit Report seamlessly through
                    our secure platform.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    opacity: ".6",
                    background: "#256199",
                    color: "white",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                    borderRadius: "10px",
                    marginRight: "15px",
                  }}
                >
                  5
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "15px",
                    }}
                  >
                    Viola! Choose your offer:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Enjoy the simplicity of selecting from multiple tailored
                    loan offers, empowering you to make the best financial
                    decision for your business with SmartLend.
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: screenWidth > 1200 ? "flex-start" : "center",
                  width: "100%",
                }}
              >
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    color: "#256199",
                    border: " 2px solid #256199",
                    background: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "13px",
                    padding: screenWidth > 1200 ? "7px 35px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "20px",
                    fontWeight: "600",
                  }}
                >
                  Apply now
                </div>
                <div
                  className="menu_button"
                  onClick={() => {
                    history.push("/auth/signin");
                    window.scrollTo(0, 0);
                  }}
                  style={{
                    background: "#256199",
                    color: "white",
                    fontSize: screenWidth > 1200 ? "20px" : "13px",
                    padding: screenWidth > 1200 ? "7px 35px" : "7px 20px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "20px",
                  }}
                >
                  Be a Borrower
                </div>
              </div>
            </div>
            {screenWidth > 1200 ? (
              <div
                style={{
                  width: "30%",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={singpassmobilepage}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    maxHeight: "700px",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          background: "rgba(245,245,245,1)",
          padding: "60px 0px",
          marginTop: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: screenWidth > 1200 ? "25px" : "20px",
              fontWeight: "700",
              color: "rgba(0,0,0,0.8)",
              marginBottom: "10px",
            }}
          >
            Weekly newsletter subscription
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              fontSize: screenWidth > 1200 ? "inherit" : "14px",
            }}
          >
            Subscibe to Smartlend's weekly newsletter for exclusive insights and
            tips to help your SME unlock financing opportunities!
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              marginRight: "0px",
              width: screenWidth > 1200 ? "15%" : "50%",
            }}
          >
            <input
              type="email"
              placeholder="abc@gmail.com"
              id="promoCode"
              value={emailSubscription}
              onChange={handleInputChange}
              style={{
                borderColor: isEmailValid ? "initial" : "red",
                outlineColor: isEmailValid ? "initial" : "red",
                borderRadius: "15px 0px 0px 15px",
                padding: "5px 10px",
                fontSize: "14px",
                width: "100%",
              }} // Change border color based on validation
            />
          </div>
          <div
            className="menu_button"
            onClick={handleEmailSubscription}
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "white",
              border: "2px solid #256199",
              background: "#256199",
              padding: "5px 40px",
              borderRadius: "0px 15px 15px 0px",
            }}
          >
            Subscribe
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: screenWidth > 1200 ? "inherit" : "14px",
          }}
        >
          {!isEmailValid && (
            <p style={{ color: "red" }}>
              Please enter a valid email address. "@gmail.com"
            </p>
          )}{" "}
          {/* Show error message if email is invalid */}
          {/* {isResponseStatusValid && <p style={{ color: 'red' }}>{responseErrorMessage}</p>} */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: screenWidth > 1200 ? "inherit" : "14px",
          }}
        >
          By clicking subscribe, I agree to SmartLend terms and conditions.
        </div>
      </div>

      {/* Section three */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "150px",
        }}
      >
        <div
          style={{
            width: "85%",
            background: "#256199",
            borderRadius: "20px",
            padding: "40px 0px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "55px",
              fontWeight: "700",
              color: "white",
              lineHeight: "100px",
            }}
          >
            How does it works?
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "40px",
              textAlign: "center",
              padding: "30px 40px",
            }}
          >
            <div style={{ width: "24%" }}>
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 1
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 2
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 3
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "24%" }}>
              {" "}
              <div
                style={{ fontSize: "25px", color: "white", fontWeight: "700" }}
              >
                Step 4
              </div>
              <div
                style={{ fontSize: "15px", color: "white", fontWeight: "400" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                color: "#256199",
                border: " 2px solid #256199",
                background: "white",
                fontSize: "20px",
                padding: "7px 35px",
                margin: "0px 20px 0px 0px",
                borderRadius: "30px",
                fontWeight: "700",
              }}
            >
              Get Started
            </div>
          </div>
        </div>
      </div> */}

      {/* Section four testimonial */}
      {/* <div
        style={{
          background: "#f2f2f2",
          padding: "40px 0px",
          marginTop: "50px",
          width: "100%",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "55px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            lineHeight: "100px",
          }}
        >
          Don't take our word for it.
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "60%",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "400",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "800",
                fontSize: "25px",
                color: "rgba(0,0,0,0.8)",
                padding: "0px 10px",
              }}
            >
              #SmartLend
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "rgba(0,0,0,0.2)",
                padding: "0px 10px",
              }}
            >
              #SmartBorrowers
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "rgba(0,0,0,0.2)",
                padding: "0px 10px",
              }}
            >
              #SmartLenders
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
            <div style={{ width: "20%", padding: "2.5%", textAlign: "center" }}>
              <img style={{ width: "100%", height: "300px" }} src={""} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "100px",
                }}
              >
                Ryan Alley
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  //   lineHeight: "0px",
                  marginTop: "-30px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Blog section */}
      {/* <div style={{ padding: "50px 0px" }}> */}
      {/* first segment */}
      {/* <div
          style={{
            padding: "35px 0px",
            textAlign: "center",
            background: "#256199",
            padding:"60px 0px"
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "55px",
              fontWeight: "700",
              color: "white",
            //   lineHeight: "100px",
            }}
          >
            Our articles
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%", marginTop:"10px" }}
          >
            <div style={{ fontSize: "15px", fontWeight: "300", width: "70%", color:"white", width:"60%" }}>
              Here you will find answers to some of the most commonly asked
              questions about our work management platform, Premelo. If you have
              any additional questions that are not covered here, please do not
              hesitate to contact our support team for further assistance.
            </div>
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="🔍 Search by title, news and more"
            style={{
              border: "none",
              outline: "none",
              padding: "8px 20px",
              textAlign: "center",
              marginTop: "20px",
              borderRadius: "25px",
              height: "30px",
              width: "30%",
            }}
          />
        </div> */}
      {/* <div
          style={{
            textAlign: "center",
            fontSize: "55px",
            fontWeight: "700",
            color: "rgba(0,0,0,0.8)",
            lineHeight: "100px",
          }}
        >
          Latest news
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filteredFaqs.slice(0, 8).map((faq) => (
              <FAQItem
                key={faq.id}
                id={faq.id}
                title={faq.title}
                answer={faq.answer}
              />
            ))}
          </div>
        </div> */}
      {/* </div> */}

      {/* Footer section */}
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            Smart Towkay Ventures Pte. Ltd., SmartLend Financial Pte. Ltd., are
            part of the Smart Towkay Brand.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <img
              className="menu_button"
              src={tiktokIcon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Enterprise
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
            >
              SmartLend for Business
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              SmartAds
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              Express Loan
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "30%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default Homepage;
