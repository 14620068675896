import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import cookies from "js-cookie";
import moment from "moment";
import {
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

const TinyLineChartAnalytics = ({ title, subtitle, dateRange }) => {
  const [data, setData] = useState([]);
  const [averageInterestRate, setAverageInterestRate] = useState(null);
  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/analyticsMarketInterestRate",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const allInterestRate = json["All Interest Rate"];
      const filteredData = combineData(dateRange, allInterestRate);
      const totalInterestRate = filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.interest_rate,
        0
      );
      const averageInterestRate = totalInterestRate / allInterestRate.length;
      setData(filteredData);
      setAverageInterestRate(averageInterestRate.toFixed(2));
    };

    fetchData();
  }, []);

  function combineData(dateRange, allInterestRate) {
    // Create a new array of objects that includes both amount_receive and amount properties
    const combinedData = [];

    allInterestRate.forEach((item) => {
      combinedData.push({
        Date: moment(item.Date).format("YYYY-MM-DD"),
        interest_rate: item.interest_rate,
      });
    });

    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      // Filter the combined data based on the date range
      filteredData = combinedData
        .filter((item) => {
          return moment(item.Date).isBetween(startDate, endDate, "day", "[]");
        })
        .sort(
          (a, b) => moment(a.Date, "YYYY-MM-DD") - moment(b.Date, "YYYY-MM-DD")
        );
    } else {
      filteredData = allInterestRate;
    }

    return filteredData;
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "white",
            color: "rgba(0,0,0,0.4)",
            padding: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Interest Rate
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#142C44CC",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            {`${payload[0].value}%`}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "grey",
        border: "1px solid none",
        backgroundColor: "#FFFF",
        borderRadius: "10px",
        height: screenWidth > 1200 ? "200px" : "210px",
        justifyContent: "left",
        alignItems: "left",
        position: "relative",
        padding: "15px",
      }}
    >
      <div style={{ display: "flex", gap: 10 }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          Market Interest Rate
        </h3>
        <tooltip
          style={{}}
          placement="topRight"
          title="The current average interest rate offered by lenders on our platform, stated as X% per month. This rate signifies the
          borrowing costs within our marketplace and acts as a benchmark for your reference, aiding you in understanding pricing
          dynamics."
        >
          <button
            className="all_button"
            style={{
              borderRadius: "15px",
              height: "15px",
              width: "15px",
              background: "transparent",
              border: "1px solid rgba(0,0,0,0.6)",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QuestionOutlined style={{ fontSize: "8px" }} />
          </button>
        </tooltip>
      </div>
      <div
        style={{ marginTop: "5px", marginBottom: "0px", marginLeft: "10px" }}
      >
        <ResponsiveContainer
          width="85%"
          height={100}
          margin={{ top: -10, bottom: 10 }}
        >
          <LineChart data={data} margin={{ top: 30, bottom: 10 }} height={120}>
            <defs>
              <linearGradient id="line-gradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="rgba(138, 156, 197, 0.8)" />
                <stop offset="20%" stopColor="rgba(190, 201, 227, 0.8)" />
                <stop offset="100%" stopColor="rgba(21,87,169,1)" />
              </linearGradient>
            </defs>
            <Line
              type="linear"
              dataKey="interest_rate"
              stroke="#115CA6"
              strokeWidth={5}
              dot={{ r: 3 }}
              label={{
                position: "top",
                fontSize: 12,
                fontWeight: 600,
                fill: "#142C44CC",
              }}
            />

            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          textAlign: "left",
          position: "absolute",
          bottom: 15,
          left: 15,
        }}
      >
        <h5
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "22px",
            fontWeight: "800",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          {averageInterestRate
            ? `${
                averageInterestRate.toLocaleString() === "NaN"
                  ? 0
                  : averageInterestRate.toLocaleString()
              }%`
            : "-"}
        </h5>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            width: "200px",
            margin: "0px",
            lineHeight: "12px",
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default TinyLineChartAnalytics;
