import { Space, Table, Tag, Radio, Button, Modal, DatePicker, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { format } from "date-fns";







const TableLenderPayment = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [modalStates, setModalStates] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");


  const handleGenerateRepay = async (loan_id) => {
    try {
      // format the date from dateRange state
      const loan_receive = dateRange && dateRange.format('YYYY-MM-DD');
      let item = {
       loan_id: loan_id,
      loan_receive: loan_receive,
      };
      const response = await fetch(`https://www.smart-lend.com/api/loan/createRepay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
        body: JSON.stringify(item)
      });
      const data = await response.json();

      if (response.status === 200) {
      // Close the modal here
      setModalStates(prevStates => ({
        ...prevStates,
        [loan_id]: false
      }));
        message.success("Repay created successfully");
        
    }  else if (response.status === 400) {
        message.error("Repayment schedule for the loan ID already exists.")
      }

      // Refresh your table data here
      console.log("User deleted successfully");

    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  const formatDate = (dateString) => {
    try {
        if (!dateString) throw new Error("No date string provided");

        const date = new Date(dateString);
        if (isNaN(date.getTime())) throw new Error("Invalid date string");

        const formattedDate = format(date, "dd MMMM yyyy");

        return formattedDate;
    } catch (error) {
        console.error(error);
        return "-";
    }
};
 

 

  useEffect(() => {
    const initialStates = {};


    data.forEach(item => {
      initialStates[item.loan_id] = false;

    });
    setModalStates(initialStates);

  }, [data]);


  const showModal = (loan_id) => {
    setModalStates(prevStates => ({
      ...prevStates,
      [loan_id]: true
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.smart-lend.com/api/agreement/viewListAgreement?status=approved', {
          headers: {
            "Authorization": "Bearer " + Cookies.get('token'),
            "Accept": "application/json",
          }
        });
        const json = await response.json();
        setData(json['Accepted Loan']);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  function handleDateRangeChange(date) {
    setDateRange(date);
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [

 
       {
           title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Lender Name</span>,
           dataIndex: 'lender',
           key: 'lender',
           render: (text) => (
             <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text}` : '-'}</span>
           ),
         },
         {
           title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Date</span>,
           dataIndex: 'created_at',
           key: 'created_at',
           render: (text) => (
             <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${formatDate(text)}` : '-'}</span>
           ),
         },
       {
         title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Amount</span>,
         dataIndex: 'amount',
         key: 'amount',
         render: (text) => (
           <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text.toLocaleString('en-US', { style: 'currency', currency: 'USD'})}` : '-'}</span>
         ),
       },
       {
         title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Status</span>,
         dataIndex: 'status',
         key: 'status',
        
         render: (text) => (
            <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : '-'}</span>
            ),
       },
       {

        key: 'action',
        width: "2%",
        render: (text, record) => (
          <Button
            type="primary"
            onClick={() => showModal(record.loan_id)}
           
          >
            Create Repay
          </Button>
        ),
      },
    
     ];

  return (<div>

    <Table
      rowKey="loan_id"
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 5 }}
      scroll={{
        x: 1000,
        
      }}
      onRow={(record) => ({
        onClick: (event) => {
          if (event.target.type !== "checkbox") {
            onRowClick(record);
          }
        },
      })}
      style={{
        borderRadius: '10px 10px 10px 10px',
        cursor: 'pointer',
        margin: screenWidth > 1200  ?'0px 20px' : "0px",
        width: screenWidth > 1200 ? 'auto' : '100%',
      }}
    />
    {data && data.length > 0 && data
      .map((item) => (
        <Modal
          style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin:"10% auto" }}
          open={modalStates[item.loan_id]}
          onCancel={() => setModalStates(prevStates => ({
            ...prevStates,
            [item.loan_id]: false
          }))}
          footer={[

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
              <Button type="primary" onClick={() => handleGenerateRepay(item.loan_id)}>Generate Repay</Button>
              <Button type="default" onClick={() => setModalStates(prevStates => ({
                ...prevStates,
                [item.loan_id]: false
              }))}>Cancel</Button>
            </div>
          ]}
        >
          <div style={{marginTop:"10px", display:"flex", paddingTop:"10px"}}>
         <p style={{marginTop:"20px", fontSize:"14px", fontWeight:600, fontFamily:"Manroppe, sans-serif"}}> Please select the date:</p>   <DatePicker style={{marginLeft:"10px", height:"3vh", marginTop:"18px"}} onChange={handleDateRangeChange}  />
          </div> 
        </Modal>
      ))
    }
  </div>)
};

export default TableLenderPayment;