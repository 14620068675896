import { Space, Table, Tag, Radio, Button } from "antd";
import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { message } from "antd";
import SearchContext from "../admin/search-context";

const TableOngoingAgreement = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const { search } = useContext(SearchContext);
  const [screenWidth, setScreenWidth] = useState("1920");

  const handleDownloadDocument = async (loan_id, agreement_id) => {
    console.log("agreement id value", agreement_id);
    if (agreement_id === null) {
      message.error("Agreement Document are not generate yet");
    } else if (agreement_id !== null) {
      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/agreement/getAgreementDocument?loan_id=${loan_id}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const blob = await response.blob();
        const pdfBlob = new Blob([blob], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Agreement.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/viewListAgreement?status=ongoing",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const filteredData = json["Accepted Loan"].filter(
          (item) =>
            item.lender.toLowerCase().includes(search.toLowerCase()) ||
            item.borrower.toLowerCase().includes(search.toLowerCase())

          // Add other fields that you want to include in the search
        );
        setData(filteredData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchLoanAgreement = async (loan_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/getAgreementDocument?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading Agreement");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Agreement.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const fetchReceipt = async (loan_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewPaymentReceipt?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading receipt");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "receipt.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Lender
        </span>
      ),
      dataIndex: "lender",
      key: "lender",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Borrower
        </span>
      ),
      dataIndex: "borrower",
      key: "borrower",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Admin Fee
        </span>
      ),
      dataIndex: "admin_fee",
      key: "admin_fee",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Type
        </span>
      ),
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Transfer Slip
        </span>
      ),
      fixed: "right",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Button type="primary" onClick={() => fetchReceipt(record.loan_id)}>
          Download
        </Button>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Agreement
        </span>
      ),
      fixed: "right",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => fetchLoanAgreement(record.loan_id)}
        >
          Download
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="loan_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
      />
    </div>
  );
};

export default TableOngoingAgreement;
