import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import { Form, Input, Select, Col, Row, Avatar, Upload, Card, Tabs, notification, DatePicker, Space, InputNumber, Modal, Button } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { message } from "antd";
import TableApproveAgreement from "../../components/TableApproveAgreement";




const PendingSign = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [error, setError] = useState('');
  const [screenWidth, setScreenWidth] = useState("1920");



  const handleRowClick = async (record) => {
    const loan_id = record.loan_id;
    try {
      const response = await fetch(`https://www.smart-lend.com/api/agreement/viewAgreement?loan_id=${loan_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });
      const json = await response.json();
      setData(json['Accepted Loan']);

    } catch (error) {
      console.error('Error retrieving Accepted Loan Data:', error);
    }
  };

  const handleCreateLender = async () => {
    if (password !== passwordConfirmation) {
      setError('Passwords do not match');
    } else {
    try {
      // let path = '';
      let item = {
        email: email,
        user_account_type: 'lender',
        password: password,
        password_confirmation: passwordConfirmation,
      };
      const response = await fetch('https://www.smart-lend.com/api/user/createLender', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
        body: JSON.stringify(item)
      });
      const data = await response.json();
      console.log('Response status:', data); // Log the status code
      console.log('Lender created:', data);

      if (response.status === 200) {
        message.info("Lender Created Successfully");
    } else if (response.status === 302){
      message.info("Lender Already Created");
    }
    } catch (error) {
      console.error('Error creating Lender', error);
    }
  }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log('Selected loan IDs:', selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };




  const handleSubmit = async (borrower_id) => {
    console.log("loanIds value in handleSubmit", borrower_id); // Debug statement

    try {
      let item = {
        user_id: borrower_id.join(','),
        channel: channel,
        subject: subject,
        message: message,
      };
      const response = await fetch('https://www.smart-lend.com/api/user/notification/sendNotification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
        body: JSON.stringify(item)
      });
      const data = await response.json();
      console.log('Offer created:', data);

    } catch (error) {
      console.error('Error creating offer:', error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get('token');
    const user_account_type = Cookies.get('user_account_type');

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === 'lender') {
        history.push("/dashboard");
    } else if (user_account_type === 'borrower') {
        history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    const initialStates = {};
    data.forEach(item => {
      initialStates[item.borrower_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const [modalStates, setModalStates] = useState({});
  const [open, setOpen] = useState(false);
  const [openBorrowerIds, setOpenBorrowerIds] = useState([]);

  const showModal = (selectedBorrowerIds, event) => {
    event.preventDefault();
    console.log("loanIds modal", selectedBorrowerIds);
    setOpenBorrowerIds(prevOpenBorrowerIds => [...prevOpenBorrowerIds, ...selectedBorrowerIds]);
  };

  const handleClose = (selectedBorrowerIds) => {
    setOpenBorrowerIds(prevOpenBorrowerIds => prevOpenBorrowerIds.filter(id => id !== selectedBorrowerIds));
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };



  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div style={{ width: "100%", textAlign: "left", marginBottom: "10px", backgroundColor: "#FFFFFFB3" }}>
      <div style={{ display: "flex", alignItems: "left", color: "grey", marginTop: screenWidth > 1200 ? "0px" : "15px" }}>
        <img
          src={icon}
          alt={title}
          style={{ height: "40px", borderRadius: "20px", marginRight: "10px" }}
        />
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "17px",
              color: "#142C44CC",
              flex: 1,
              marginTop: "3px"

            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "400",
              fontSize: "11px",
              color: "#142C44CC",
              marginTop: "-9px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "45%" : "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginLeft: "30px"
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "17px",
            color: "#142C44CC",
            flex: 1,
            marginTop: "5px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "20px",
            color: "#435669",
            marginTop: "5px",
            width:"100%"
          }}
        >
          {label}
        </p>

      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (

    <>
    {screenWidth > 1200 ? (
  <Box m="4px 20px 20px 20px">
  <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px"
  >
    <Box
      gridColumn="span 9"
      gridRow="span 2"
      height="100vh"
      width="100%"
      borderRadius="20px"
      border="0px solid "
      opacity="0.95"
      style={{
        overflowY: "scroll",
        overflowX: "scroll",
        background: "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderRadius="10px"
        margin="20px 20px 20px 20px"
      >
        <Typography
          color={colors.grey[920]}
          variant="h5"
          fontWeight="800"
          fontFamily="Manrope, sans-serif"
          fontSize="20px"
        >
         Approve Agreement
        </Typography>
        <div style={{ display: "flex", alignItems: "center", marginRight: "38px" }}>
          <label
            htmlFor="sort-by"
            style={{
              width: "50%",
              color: "#142C44CC",
              fontWeight: "800",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
              marginRight: "-5px"
            }}
          >
            Sort by:
          </label>
          <div style={{ width: "80%", marginRight: "10px" }}>
            <select
              id="sort-by"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              style={{
                width: "100%",
                borderRadius: "30px",
                color: "#0E5AA5",
                backgroundColor: "#ECF0F5",
                border: "1px solid #3E536733",
                marginRight: "15px",
                fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px"
              }}
            >
              <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="default">Date & Time</option>
              <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="loan-amount">Loan Amount</option>
              <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="date">Date</option>
            </select>
          </div>
          {/* <button onClick={(event) => showModal(selectedBorrowerIds, event)} style={{ backgroundColor: "white", borderRadius: "10px", color: "#B13434", border: "1px solid #B13434", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px", width:"80%" }}>Warn Borrower</button> */}
        </div>


      </Box>

      <Box>

        <TableApproveAgreement onRowClick={handleRowClick} onSelectionChange={handleSelectionChange}

        />
      </Box>





    </Box>

    <Box
      gridColumn="span 3"
      gridRow="span 2"
      height="89vh"
      borderRadius="20px"
      border="0px solid white"
      style={{
        background: "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
       
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // borderBottom={`4px solid ${colors.primary[500]}`}
        colors={colors.grey[910]}
        marginTop="-15px"
        marginBottom="10px"
        p="15px"
      >
        <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px" marginLeft="-10px">
          Agreement Details
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="#FFFFFF"
        borderRadius="20px"
        margin="-10px 10px"
        p="15px"
        height="83.7vh"
        style={{
          overflowX: "scroll",
          overflowY: "scroll",
        }}
      >
        {data && data.length > 0 ? (
        data.map((item) => (
          <Box
            key={item.id}
            display="flex"
            justifyContent="center"
            alignItems="flex-start" // Updated to align to the top
            colors={colors.grey[910]}
            // borderBottom={`1px solid ${colors.primary[500]}`}
            width="45%"
            textAlign="center"
            margin="1px 1px 1px 30px"
            flexDirection="column"
          >
            <ContainerActivity
              icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
              title={item.loan_id || "-"}
              subtitle= {item.type.charAt(0).toUpperCase() + item.type.slice(1) || "-"}
            />
            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3" }} />
          </Box>
        ))
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          colors={colors.grey[910]}
          width="100%"
          textAlign="center"
          margin="1px 1px 1px 0px"
          flexDirection="column"
          height="80vh"
        >
          <ContainerActivity
            icon={warn_ico}
            title="Please Click on Table Row to View Details"
          />
        </Box>
      )}
        {!data && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            colors={colors.grey[910]}
            width="45%"
            textAlign="center"
            margin="1px 1px 1px 30px"
            flexDirection="column"
          >
            <ContainerActivity
              icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
              title="NO VALUE HERE"
              subtitle="NO VALUE HERE"
            />
            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3" }} />
          </Box>
        )}



        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Lender"

              label={item.lender || "-"} />
          ))}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Lender ID"

              label={item.lender_id || "-"}
            />
          ))}
        </Box> */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Borrower"

              label={item.borrower || "-"}
            />
          ))}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Borrower ID"

              label={item.borrower_id || "-"}
            />
          ))}
        </Box> */}

      
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Status"
              label= {item.status.charAt(0).toUpperCase() + item.status.slice(1) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Amount"
              label={item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Interest Rate"
              label={item.interest_rate + ' %' || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Admin Fee"
              label={item.admin_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Monthly Installment"
              label={item.monthly_installment.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Period"
              label={item.period + ' Months' || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Principal"
              label={item.principal.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Revenue"
              label={item.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        {data && data.length > 0 ? (
          data.map((item) => (
            <div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "30px" }}>

              {/* <button onClick={(event) => showModal(item.loan_id, event)} open_modal={modalStates[item.loan_id]} style={{ marginRight: "10px", backgroundColor: "#ECF0F5", borderRadius: "10px", color: "#0E5AA5", border: "none", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "12px" }}>Remind Borrower</button> */}
            </div>
          ))) : (<></>)}

        {/* {data && data.length > 0 ? (
          data.map((item) => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start" // Updated to align to the top
              colors={colors.grey[910]}
              width="45%"
              textAlign="center"
              // margin="30px 1px 1px 30px"
              flexDirection="column"
              marginTop="10px"
              marginLeft="25px"
            >
              <div style={{ borderBottom: "2px solid grey", width: "190%", marginTop: "7px", }} />
              <Typography color={colors.grey[920]} variant="h5" fontWeight="600" fontFamily="Manrope, sans-serif" fontSize="12px" marginTop="5px" >
                Report Borrower Issue
              </Typography>
            </Box>
          ))) : (<></>)} */}
      </Box>

    </Box>
  </Box>
</Box>
    ) : (
      <div>
      <div
        style={{
          background:
            "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          padding: "0px 20px",
          height: "100vh",
          borderRadius: "20px",
          width: "95%",
          marginLeft: "10px",
        }}
      >
<div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Approve Agreement
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Sort by:
                  </label>

                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
           
            </div>
            <div style={{marginTop:"10px"}}>
            <TableApproveAgreement onRowClick={handleRowClick} onSelectionChange={handleSelectionChange}

/>
<div style={{
                height:"89vh",
                borderRadius:"20px",
                border:"0px solid white",
                background: "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",

            }}>
              <div>
            <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px" marginTop="10px">
                 Agreement Details
             </Typography>
            </div>
            <div style={{
                 display:"flex",
                 flexDirection:"column",
                 backgroundColor:"#FFFFFF",
                 borderRadius:"20px",
                 overflowY:"scroll",
                 height:"83.7vh",
            }}>
 {data && data.length > 0 ? (
        data.map((item) => (
          <Box
            key={item.id}
            display="flex"
            justifyContent="center"
            alignItems="flex-start" // Updated to align to the top
            colors={colors.grey[910]}
            // borderBottom={`1px solid ${colors.primary[500]}`}
            width="45%"
            textAlign="center"
            margin="1px 1px 1px 30px"
            flexDirection="column"
          >
            <ContainerActivity
              icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
              title={item.loan_id || "-"}
              subtitle= {item.type.charAt(0).toUpperCase() + item.type.slice(1) || "-"}
            />
            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3" }} />
          </Box>
        ))
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          colors={colors.grey[910]}
          width="100%"
          textAlign="center"
          margin="1px 1px 1px 0px"
          flexDirection="column"
          height="80vh"
        >
          <ContainerActivity
            icon={warn_ico}
            title="Please Click on Table Row to View Details"
          />
        </Box>
      )}
        {!data && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            colors={colors.grey[910]}
            width="45%"
            textAlign="center"
            margin="1px 1px 1px 30px"
            flexDirection="column"
          >
            <ContainerActivity
              icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
              title="NO VALUE HERE"
              subtitle="NO VALUE HERE"
            />
            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3" }} />
          </Box>
        )}



        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Lender"

              label={item.lender || "-"} />
          ))}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Lender ID"

              label={item.lender_id || "-"}
            />
          ))}
        </Box> */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Borrower"

              label={item.borrower || "-"}
            />
          ))}
        </Box>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction

              title="Borrower ID"

              label={item.borrower_id || "-"}
            />
          ))}
        </Box> */}

      
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Status"
              label= {item.status.charAt(0).toUpperCase() + item.status.slice(1) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Amount"
              label={item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Interest Rate"
              label={item.interest_rate + ' %' || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Admin Fee"
              label={item.admin_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Monthly Installment"
              label={item.monthly_installment.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Period"
              label={item.period + ' Months' || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Principal"
              label={item.principal.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          colors={colors.grey[910]}
          marginLeft="0px"
          marginTop="0px"

        >
          {data && data.length > 0 && data.map((item) => (
            <ContainerTransaction
              title="Revenue"
              label={item.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}
            />
          ))}
        </Box>
        {data && data.length > 0 ? (
          data.map((item) => (
            <div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "30px" }}>

              {/* <button onClick={(event) => showModal(item.loan_id, event)} open_modal={modalStates[item.loan_id]} style={{ marginRight: "10px", backgroundColor: "#ECF0F5", borderRadius: "10px", color: "#0E5AA5", border: "none", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "12px" }}>Remind Borrower</button> */}
            </div>
          ))) : (<></>)}

        {/* {data && data.length > 0 ? (
          data.map((item) => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start" // Updated to align to the top
              colors={colors.grey[910]}
              width="45%"
              textAlign="center"
              // margin="30px 1px 1px 30px"
              flexDirection="column"
              marginTop="10px"
              marginLeft="25px"
            >
              <div style={{ borderBottom: "2px solid grey", width: "190%", marginTop: "7px", }} />
              <Typography color={colors.grey[920]} variant="h5" fontWeight="600" fontFamily="Manrope, sans-serif" fontSize="12px" marginTop="5px" >
                Report Borrower Issue
              </Typography>
            </Box>
          ))) : (<></>)} */}
            </div>

</div>
            </div>
        </div>
        </div>
    )}
    </>
  

  );
};

export default PendingSign;