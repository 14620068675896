import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import profilePicture from "../../assets/img/profile-picture.jpg";
import React, { useState, useEffect } from "react";
import { Popover, List, Avatar } from "antd";
import Cookies from "js-cookie";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PopoverContent = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/user/notification/viewNotification",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setNotifications(data["Notifications"]);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <List
      style={{
        height: "350px",
        width: "250px",
        overflowY: "scroll",
        marginLeft: "10px",
      }}
      itemLayout="horizontal"
      dataSource={notifications}
      renderItem={(item) => (
        <div
          className="notification_item"
          onClick={() => console.log(item.id)}
          style={{ cursor: "pointer" }}
        >
          <a
            href="#"
            onClick={() => console.log(item.id)}
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "13px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            {item.subject}
          </a>
          <div
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "12px",
              color: "rgba(0,0,0,0.4)",
            }}
          >
            {item.message}
          </div>
        </div>

        // <List.Item>

        //   <List.Item.Meta
        //     title={
        //       <a href="#" onClick={() => console.log(item.id)}>
        //         {item.subject}
        //       </a>
        //     }
        //     description={item.message}
        //   />
        // </List.Item>
      )}
    />
  );
};

const Topbar = ({ userDetails }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [data, setData] = useState([]);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    // Function to get the value of a URL parameter by name
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    // Get the token parameter from the URL
    const tokenParam = getUrlParameter("token");

    // Check if a token is present in the URL
    if (tokenParam) {
      // Store the token in cookies
      console.log(tokenParam);
      Cookies.set("yourTokenCookieName", tokenParam, { expires: 720 }); // Set an expiration if needed
    }

    async function fetchData() {
      // const profilePictureUrl =
      //   (await loadprofilepicture()) || Cookies.get("profile_picture");
      // if (profilePictureUrl) {
      //   setImageUrl(profilePictureUrl);
      //   console.log(profilePictureUrl);
      // }
    }
    fetchData();
  }, []);
  const history = useHistory();
  const location = useLocation();

  const redirectToUrl = (path) => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    let redirectTo = path;

    if (code && state) {
      redirectTo += `?code=${code}&state=${state}`;
    }

    history.push(redirectTo);
  };

  const [initialName, setInitialName] = useState("SL");

  useEffect(() => {
    if (userDetails) {
      const textToSplit = userDetails.principal_name
        ? userDetails.principal_name.split(" ")
        : null;
      if (textToSplit !== null) {
        const initials =
          textToSplit?.[0]?.[0].toUpperCase() +
          (textToSplit?.[1]?.[0].toUpperCase() || "");
        setInitialName(initials);
      }
    }
  }, [userDetails]);

  const loadprofilepicture = async () => {
    console.log("getting profile picture");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/viewInfoProfilePicture",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 401) {
        if (!Cookies.get("expressauth")) {
          Cookies.remove("token");
          // window.location.href = "/auth/signin";

          try {
            const response = await fetch(
              "https://www.smart-lend.com/api/user/logout",
              {
                method: "POST",
                redirect: "manual", // don't follow the redirect
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Cookies.get("token")}`,
                },
              }
            );
            console.log("response status value", response.status);
            if (response.status === 200) {
              // Clear user session and navigate to login page
              Cookies.remove("token");
              window.location.href = "/auth/signin";
            } else if (response.status === 0) {
              window.location.href = "/auth/signin";
            }
            // if (!response.ok) {
            //   throw new Error("Logout failed");
            // }
          } catch (error) {
            console.error(error);
            // Display error message to user
            alert("Login failed. Please try again.");
          }
        } else {
          redirectToUrl("/director-authorization");
        }
      } else if (!response.ok) {
        throw new Error("Failed to fetch profile picture");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error retrieving profile picture", error);
      return null;
    }
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box display="flex" justifyContent="space-between" p={2}>
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.grey[910]}
            // colors={colors.grey[910]}
            opacity="1.00"
            borderRadius="10px"
            width="74.5%"
          >
            <IconButton type="button" sx={{ p: 1, color: "black" }}>
              <SearchIcon
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "25px" }}
              />
            </IconButton>
            <InputBase
              className="input_base"
              sx={{ ml: 2, flex: 1, color: "black" }}
              placeholder="Search In Page"
            />
          </Box>

          {/* ICONS */}
          <Box display="flex">
            {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
            {/* <Popover
              content={<PopoverContent />}
              title="Notifications"
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <IconButton>
                <NotificationsIcon
                  style={{ color: "#707070", marginRight: "-5px" }}
                />
              </IconButton>
            </Popover> */}
            <IconButton style={{ marginRight: "8px" }}>
              <SettingsIcon
                style={{ color: "#707070" }}
                onClick={() => {
                  window.location.href = "/stg-b-profile";
                }}
              />
            </IconButton>
            <div
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {initialName}
            </div>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" p={2}>
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.grey[910]}
            opacity="1.00"
            borderRadius="10px"
            width="60%"
            marginLeft="50PX"
          >
            <IconButton type="button" sx={{ p: 1, color: "black" }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 2, flex: 1, color: "black", fontSize: "11px" }}
              placeholder="Search In Pages"
            />
          </Box>

          {/* ICONS */}

          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              background: "rgb(140,140,170)",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {initialName}
          </div>
        </Box>
      )}
    </>
  );
};

export default Topbar;
