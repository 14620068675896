import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import Cookies from "js-cookie";
import { Progress, Tooltip } from "antd";

const COLORS = ["#0083b0", "#FFFF"];

const PaidPrincipalBorrower = ({ title, subtitle }) => {
  const [data, setData] = useState([]);
  const [loanAmount, setLoanAmount] = useState(null);
  const [paidPercentage, setPaidPercentage] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [average, setAverage] = useState(null);

  const strokeWidth = 27; // Set the thickness of the progress bar here

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/dashboard/loanPrincipalPaid",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const amountReceived = json["Amount Received"];
      const loanAmount = json["Loan Amount"];
      const paidPercentage = `${json["Paid Percentage"]}`;
      const average = json["Average Amount Paid"]["avg_amount_paid"];
      const unpaid = loanAmount - amountReceived;
      setData([{ value: amountReceived }, { value: unpaid }]);
      setLoanAmount(loanAmount);
      console.log(paidPercentage / 100);

      if (paidPercentage / 100 < 0.1) {
        setPaidPercentage(0.1);
      } else {
        setPaidPercentage(paidPercentage / 100);
      }
      setAmountReceived(amountReceived);
      setAverage(average);
    };

    fetchData();
  }, []);

  const [screenWidthx, setScreenWidthx] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidthx(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "12.61em",
        border: "1px solid none",
        backgroundColor: "#FFFFFFB3",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "87%",
          marginLeft: "1em",
          marginTop: "1.1em",
          marginRight: "0.1em",
        }}
      >
        <h3
          style={{
            display: "inline-block",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          Principal Paid
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.8em",
            fontWeight: "400",
            color: "#00000066",
            margin: "0px",
          }}
        >
          Overall
        </p>
      </div>

      <div style={{ marginTop: screenWidthx < 800 ? "0px" : "1em" }}>
        {/* <ResponsiveContainer width="12vw">
          <Tooltip title={`Paid Percentage: ${paidPercentage} %`}>
            <Progress
              percent={parseFloat(paidPercentage)}
              showInfo={false}
              strokeWidth={strokeWidth}
              strokeColor={
                subtitle === "Your subtitle here"
                  ? { "0%": "#b1c3e0", "100%": "#1557a9" }
                  : { "0%": "#b1c3e0", "100%": "#1557a9" } // Update these colors based on your conditions
              }
            />
          </Tooltip>
        </ResponsiveContainer> */}
        <svg width="100%" height="50">
          <rect
            x="10"
            y="10"
            width="90%"
            height="25"
            fill="rgba(0,0,0,0.1)" // Color of the background
            rx="12.5"
            ry="12.5"
          />
          <rect
            x="13"
            y="14"
            width="88%"
            height="20"
            fill="white" // Color of the background
            rx="12.5"
            ry="12.5"
            filter="url(#blur)" // Apply blur filter to this path
          />
          {/* Define the filter for the inner shadow */}
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.3" />
          </filter>

          <rect
            x="10"
            y="10"
            width={`calc(90% * ${paidPercentage})`} // Adjust the width based on the progress value (0 to 1)
            height="25"
            fill="#115CA6" // Color of the progress
            rx="12.5"
            ry="12.5"
          />
        </svg>
      </div>

      <div
        style={{
          textAlign: "left",
          position: "absolute",
          left: 10,
          right: 0,
          bottom: 10,
        }}
      >
        <h5
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            color: "#142C44",
            letterSpacing: "-1px",
            fontSize: screenWidthx < 800 ? "18px" : "25px",
          }}
        >
          {loanAmount
            ? `${loanAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </h5>
        <p
          style={{
            paddingRight: "0em",
            marginTop: "-0.5em",
            marginBottom: "0px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "600",
            color: "#00000066",
          }}
        >
          Average of{" "}
          {average
            ? `${average.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}{" "}
          per month.
        </p>
      </div>
    </div>
  );
};

export default PaidPrincipalBorrower;
