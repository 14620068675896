import { Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { format } from "date-fns";




const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const TableDelayedPayment = ({ onRowClick }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.smart-lend.com/api/loan/viewDelayedPayment', {
          headers: {
            "Authorization": "Bearer " + Cookies.get('token'),
            "Accept": "application/json",
          }
        });
        const json = await response.json();
        setData(json['Delayed Payments']);
        console.log(json['Delayed Payments']);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const reformatDateString = (originalDateString) => {
    // Create a Date object from the original date string
    const originalDate = new Date(originalDateString);

    // Define options for formatting the date
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date into "Mon dd, yyyy" format
    const formattedDate = originalDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };
  const columns = [
   
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Due Date</span>,
      dataIndex: 'repay_due_date',
      key: 'repay_due_date',
      render: (text) => (
        <span style={{ fontFamily:"Poppins, sans-serif", fontSize: "13px", fontWeight: "500" }}>{`${formatDate(text)}`}</span>
      ),
    },
    {
      title: <span style={{fontFamily:"Poppins, sans-serif", fontSize: "13px", fontWeight: "600"}}>Borrower</span>,
      dataIndex: 'principal_name',
      key: 'principal_name',
      render: (text) => (
        <span style={{ fontFamily:"Poppins, sans-serif", fontSize: "13px", fontWeight: "500" }}>{`${text}`}</span>
      ),
    },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Loan Amount</span>,
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <span style={{ color: '#B13434', fontFamily:"Manrope, sans-serif", fontSize: "16px", fontWeight: "800" }}>{text ? `${text.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` : '-'}</span>
      ),
      
    },
    
  ];

  return(
    <div
      style={{
        background: "rgba(255,255,255,0.7)",
        width: "100%",
        borderRadius: "10px",
        padding: "10px",
        transition: "0.3s all ease-in-out",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // use "center" to vertically center the titles
          backgroundColor: "#ffff",
          width: "100%",
          borderRadius: "10px",
          padding: "12px",
          marginBottom: "7px",
        }}
      >
        <div style={{ textAlign: "left", width: "30%" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            Due Date
          </h3>
        </div>
        <div style={{ textAlign: "center", width: "30%" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            Borrower
          </h3>
        </div>
        <div style={{ textAlign: "center", width: "40%" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            Loan Amount
          </h3>
        </div>
      </div>
      {data.length ? data.map((loan, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // use "center" to vertically center the titlesZ
            width: "100%",
            borderRadius: "10px",
            padding: "12px",
            marginBottom: "7px",
          }}
        >
          <div style={{ textAlign: "left", width: "30%" }}>
            <h3
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                color: "#435669",
                margin: "0px",
              }}
            >
              {reformatDateString(formatDate(loan.repay_due_date))}
            </h3>
          </div>
          <div style={{ textAlign: "center", width: "30%" }}>
            <h3
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                color: "#435669",
                margin: "0px",
              }}
            >
              {loan.principal_name}
            </h3>
          </div>
          <div style={{ textAlign: "center", width: "40%" }}>
            <h3
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "13px",
                color: "rgb(177, 52, 52)",
                margin: "0px",
              }}
            >
              {loan.amount
                ? `${loan.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : "-"}
            </h3>
          </div>
        </div>
      )) : 
      <div
        style={{
          width: "100%",
          padding: "0px 0px",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "0px 15px",
            // background: "rgba(255,255,255,0.7)",
            borderRadius: "10px",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "600",
            color: "#00000066",
          }}
        >
          No delayed payments
        </div>
      </div>}
    </div>
  );
};

export default TableDelayedPayment;