import { Form, Button } from "antd";
import { Table } from "antd";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";

const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};

const table1titleStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "bold",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "200",
};

const table1dataStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "200",
  fontFamily: "Poppins",
  fontSize: "14px",
  // borderBottom: "1px solid red",
};

const table2titleStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "bold",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "200",
};

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const formatDate2 = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const columns = [
  // {
  //   dataIndex: "",
  //   key: "account_balance",
  //   render: (text, record) => (
  //     <td style={table1titleStyle}>
  //       Ordinary Account (OA)
  //       <hr style={{ width: "100%" }} />
  //       Special Account (SA)
  //       <hr style={{ width: "100%" }} />
  //       Medisave Account (MA)
  //       <hr style={{ width: "100%" }} />
  //       Retirement Account (RA)
  //     </td>
  //   ),
  // },
  // {
  //   dataIndex: "",
  //   key: "account_balance",
  //   render: (text, record) => (
  //     <td style={table1dataStyle}>
  //       {record.oa.value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       })}
  //       <hr style={{ width: "100%" }} />
  //       {record.sa.value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       })}
  //       <hr style={{ width: "100%" }} />
  //       {record.ma.value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       })}
  //       <hr style={{ width: "100%" }} />
  //       {record.ra.value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       })}
  //     </td>
  //   ),
  // },
  {
    title: "Account Type",
    dataIndex: "accountType",
    key: "accountType",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance) =>
      balance.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
  },
];
const columns2 = [
  {
    title: <span style={table2titleStyle}>Year of Assessment</span>,
    dataIndex: "yearofassessment",
    key: "yearofassessment",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {text}
      </a>
    ),
  },
  {
    title: <span style={table2titleStyle}>Description</span>,
    dataIndex: "category",
    key: "category",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {text}
      </a>
    ),
  },
  {
    title: <span style={table2titleStyle}>Amount ($)</span>,
    dataIndex: "amount",
    key: "amount",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {text.toLocaleString("en-US", { style: "currency", currency: "USD" })}
      </a>
    ),
  },
];

const Finance = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Authorization", "Bearer " + Cookies.get("token"));

  //     const options = {
  //       method: "GET",
  //       headers: headers,
  //     };

  //     try {
  //       const response = await fetch(
  //         "https://www.smart-lend.com/api/profile/viewPersonal?type=Finance",
  //         options
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data");
  //       }

  //       const responseData = await response.json();
  //       setData(responseData["CPF Contribution History"]);
  //       setData2(responseData["Finance Account Balance"]);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        // const personDetail = displaySingpassData.singpass_data[0].person.cpfbalances;
        // const cpfContributions = displaySingpassData.singpass_data[0].person.cpfcontributions;

        const personDetail =
          displaySingpassData.singpass_data.person.noahistory.noas;
        // const cpfContributions = displaySingpassData.singpass_data.person.cpfcontributions;

        // Transforming the data for the first table
        // const tableData = [
        //     { key: '1', accountType: 'Ordinary Account (OA)', balance: personDetail.oa.value },
        //     { key: '2', accountType: 'Special Account (SA)', balance: personDetail.sa.value },
        //     { key: '3', accountType: 'Medisave Account (MA)', balance: personDetail.ma.value },
        //     { key: '4', accountType: 'Retirement Account (RA)', balance: personDetail.ra.value }
        // ];
        // setData(tableData);

        // Transforming the data for the second table
        const historyData = personDetail.map((item, index) => ({
          key: index.toString(),
          yearofassessment: item.yearofassessment.value,
          category: item.category.value,
          amount: parseFloat(item.amount.value).toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }),
        }));
        console.log("history data:", historyData);
        setData2(historyData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ minHeight: "73vh" }}>
      <h5
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          fontWeight: "800",
          fontFamily: "Manrope",
          fontSize: "18px",
        }}
      >
        Notice of Assessment
      </h5>
      <Table
        columns={columns2}
        dataSource={data2}
        pagination={false}
        bordered
        style={{
          width: screenWidth > 1200 ? "60%" : "100%",
          border: "1px solid #979797",
          borderRadius: "10px",
          overflow: "hidden", // To make sure borderRadius applies to all inner elements
        }}
      />{" "}
      {/* <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </div>
  );
};

export default Finance;
