import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import LineChartIntrestProfit from "../../components/LineChartInterestProfit";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import ClientBarChart from "../../components/ClientBarChart";
import StatBox from "../../components/StatBox";
import Cookies from "js-cookie";
import TinyLineChartAnalytics from "../../components/TinyLineChartAnalytics";
import React, { useEffect, useState } from "react";
import HalfPieChartAnalytics from "../../components/HalfPieChartAnalytics";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const Analytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [averagePrincipalAmount, setAvgPrincipalAmount] = useState({});
  const [sortBy, setSortBy] = useState("week");
  const [dateRange, setDateRange] = useState([]);
  const [data, setData] = useState([]);
  const [averagePaidPrincipal, setAveragePaidPrincipal] = useState(null);
  const [averageClients, setAverageClients] = useState(null);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState("1920");

  const handleAverageClientsUpdate = (average) => {
    setAverageClients(average);
  };
  const month = "January"; // Replace this with the desired month
  const averageForSelectedMonth = averageClients ? averageClients[month] : 0;

  //Sort data by Week, Month and Year
  const dataByWeek = mockTransactions.reduce((acc, transaction) => {
    const weekNumber = moment(transaction.date).isoWeek();
    if (!acc[weekNumber]) {
      acc[weekNumber] = 0;
    }
    acc[weekNumber] += transaction.amount;
    return acc;
  }, {});

  const sortedData = Object.keys(dataByWeek)
    .sort((a, b) => a - b)
    .map((weekNumber) => ({
      name: `Week ${weekNumber}`,
      value: dataByWeek[weekNumber],
    }));

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/auth/register/viewUser",
        options
      );
      const json = await response.json();
      setEmail(json.email); // Save the email from the response to the state
      setData(response);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/analyticsAvgPrincipalAmount",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const allAmount = json["All Principal Amount"];
      const filteredData = combineData(dateRange, allAmount);

      const totalAmount = filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );

      const averagePaidPrincipal = totalAmount / allAmount.length;
      setData(filteredData);
      setAveragePaidPrincipal(averagePaidPrincipal.toFixed(2));
    };

    fetchData();
  }, [dateRange]);

  function combineData(dateRange, allAmount) {
    // Create a new array of objects that includes both amount_receive and amount properties
    const combinedData = [];

    allAmount.forEach((item) => {
      combinedData.push({
        Date: moment(item.Date).format("YYYY-MM-DD"),
        amount: item.amount,
      });
    });

    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let filteredData;
    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");

      // Filter the combined data based on the date range
      filteredData = combinedData
        .filter((item) => {
          return moment(item.Date).isBetween(startDate, endDate, "day", "[]");
        })
        .sort(
          (a, b) => moment(a.Date, "YYYY-MM-DD") - moment(b.Date, "YYYY-MM-DD")
        );
    } else {
      filteredData = allAmount;
    }

    return filteredData;
  }

  function exportData(dateRange) {
    // Get the start and end dates from the dateRange prop
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    if (dateRange && dateRange[0] && dateRange[1]) {
      startDate = dateRange[0].startOf("day").format("YYYY-MM-DD");
      endDate = dateRange[1].endOf("day").format("YYYY-MM-DD");
    } else {
      startDate = moment().startOf("month").format("YYYY-MM-DD");
      endDate = moment().endOf("month").format("YYYY-MM-DD");
    }

    // Return an object containing both startDate and endDate
    return { startDate, endDate };
  }

  function formatNumberWithCommasAndDecimals(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      return "Invalid Number";
    }

    // Use toFixed(2) to round to two decimal places and convert to string
    const formattedNumber = number.toFixed(2);

    // Split the string at the decimal point
    const parts = formattedNumber.split(".");

    // Use regular expression to add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and decimal parts back together with a period
    return parts.join(".");
  }

  const ContainerRow1 = ({ title, subtitle, label, description }) => (
    <div
      style={{
        width: "100%",
        height: "200px",
        border: "1px solid white",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        position: "relative",
        padding: "15px",
      }}
    >
      <div style={{}}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
            color: "#142C44CC",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "130px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "35px",
            color: "#142C44CC",
            marginBottom: "10px",
          }}
        >
          {`$${
            formatNumberWithCommasAndDecimals(parseFloat(label)) ===
            "Invalid Number"
              ? 0
              : formatNumberWithCommasAndDecimals(parseFloat(label))
          }`}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          Is your average Principal Loan Amount <br />
          per client
        </p>
      </div>
    </div>
  );

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: "210",
        marginRight: "0px",
        borderRadius: "10px",
      }}
    >
      <TinyLineChartAnalytics
        subtitle="Is the current market interest rate"
        dateRange={dateRange}
      />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: "210",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <HalfPieChartAnalytics dateRange={dateRange} />
    </div>
  );

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "90%",
        textAlign: "left",
        marginBottom: "22px",
        border: "1px solid",
        borderRadius: "20px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "10px",
            margin: "10px 10px 10px 10px",
          }}
        />
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "8px",
              marginLeft: "10px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "10px",
              fontWeight: "400",
              marginLeft: "10px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  function handleDateRangeChange(dates) {
    setDateRange(dates);
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="0px 20px 20px 20px">
          {/* HEADER */}

          <Box
            gridColumn="span 8"
            gridRow="span 2"
            borderRadius="10px"
            opacity="0.95"
            style={{
              overflowY: "scroll",
              height: "88vh",
              padding: "15px",
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "0px 0px 10px 0px" }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  color: "#142C44CC",
                  textAlign: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "50%",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "5px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  View by:
                </label>
                <div style={{ width: "29%", marginRight: "20px" }}>
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={handleDateRangeChange}
                      value={dateRange}
                    />
                  </Space>
                </div>
                <Button
                  onClick={async () => {
                    try {
                      const { startDate, endDate } = exportData(dateRange);
                      const response = await fetch(
                        `https://www.smart-lend.com/api/analytics/exportExcel?start_date=${startDate}&end_date=${endDate}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization: "Bearer " + Cookies.get("token"),
                            Accept: "application/json",
                          },
                        }
                      );
                      const blob = await response.blob();
                      const url = URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `${email}_Analytics_${startDate}_${endDate}.xlsx`
                      );
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  className="analytics_button"
                  style={{
                    backgroundColor: "none",
                    color: "#0E5AA5",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "2px 10px",
                    border: "1px solid lightgrey",
                    borderRadius: "10px",
                    textTransform: "none", // Add this line to allow small letters in the button text
                    padding: "3px 20px",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  <DownloadOutlinedIcon
                    className="analytics_button_icon"
                    style={{
                      transition: "0.3s all ease-in-out",
                    }}
                  />
                  Export
                </Button>
              </div>
            </Box>

            <Box gridColumn="span 8">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div style={{ width: "65%" }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    colors={colors.grey[100]}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                      style={{ marginBottom: "10px" }}
                    >
                      Forecast Revenue
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "7px",
                          width: "30px",
                          background: "rgb(21, 87, 169, 0.4)",
                          marginLeft: "5px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px 0px 0px 5px",
                        }}
                      >
                        Loaned Amount
                      </div>
                      <div
                        style={{
                          height: "7px",
                          width: "30px",
                          background: "rgb(21, 87, 169)",
                          marginLeft: "5px",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px 0px 0px 5px",
                        }}
                      >
                        Amount Received
                      </div>
                    </div>
                  </Box>
                  <Box
                    gridColumn="span 4"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    border="none"
                    flexDirection="column"
                    backgroundColor="#FFFFFF"
                    borderRadius="10px"
                    style={{ padding: "10px" }}
                  >
                    <Box height="300px">
                      <LineChart isDashboard={true} dateRange={dateRange} />
                    </Box>
                  </Box>
                </div>
                <div
                  style={{
                    width: "35%",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    colors={colors.grey[100]}
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Interest Profit
                    </Typography>
                  </Box>
                  <Box
                    gridColumn="span 4"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    border="1px solid none"
                    flexDirection="column"
                    backgroundColor="#FFFFFF"
                    borderRadius="10px"
                    style={{ padding: "10px" }}
                  >
                    <Box height="300px">
                      <LineChartIntrestProfit
                        isDashboard={true}
                        dateRange={dateRange}
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            </Box>

            <Box gridColumn="span 8">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="auto"
                      borderRadius="10px"
                    >
                      <ContainerPieChart
                        dateRange={dateRange}
                      ></ContainerPieChart>
                    </Box>
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="10px"
                    >
                      <ContainerLineChart
                        dateRange={dateRange}
                      ></ContainerLineChart>
                    </Box>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="200px"
                      borderRadius="10px"
                    >
                      <ContainerRow1
                        title="Avg. Principal Amount"
                        subtitle="You've loaned"
                        label={
                          averagePaidPrincipal ? averagePaidPrincipal : "0"
                        }
                      />
                    </Box>
                    <Box
                      width="50%"
                      opacity="1.00"
                      display="flex"
                      height="200px"
                      backgroundColor="#FFFFFF"
                      borderRadius="10px"
                      border="1px solid white"
                    >
                      <Box
                        className="client_bar_chart"
                        height={200}
                        style={{ padding: "15px", width: "100%" }}
                      >
                        <div style={{}}>
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontSize: "18px",
                              fontWeight: "800",
                              color: "#142C44CC",
                              margin: "0px",
                            }}
                          >
                            Clients
                          </h3>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "600",
                              fontSize: "11px",
                              color: "rgba(0,0,0,0.4)",
                              margin: "0px",
                            }}
                          >
                            You've gained {averageForSelectedMonth} new client
                            of this month
                          </p>
                        </div>

                        <ClientBarChart
                          isDashboard={true}
                          width="100%"
                          height={170}
                          dateRange={dateRange}
                          onAverageClientsUpdate={handleAverageClientsUpdate}
                        />
                      </Box>
                    </Box>
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <Box
                    display="flex"
                    justifyContent="left"
                    colors={colors.grey[100]}
                    style={{ marginBottom: "10px", height: "28px", gap: 10 }}
                  >
                    <Typography
                      color={colors.grey[920]}
                      variant="h5"
                      fontWeight="800"
                      fontFamily="Manrope, sans-serif"
                      fontSize="18px"
                    >
                      Industries Analytics
                    </Typography>
                    <tooltip
                      style={{}}
                      placement="topRight"
                      title="Discover the preferred industries among lenders on our platform for insightful analytics. Gain valuable data for other lenders'
                      preferences to optimize decision-making and investment strategies based on industry-specific trends and preferences."
                    >
                      <button
                        className="all_button"
                        style={{
                          borderRadius: "15px",
                          height: "15px",
                          width: "15px",
                          background: "transparent",
                          border: "1px solid rgba(0,0,0,0.6)",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <QuestionOutlined style={{ fontSize: "8px" }} />
                      </button>
                    </tooltip>
                  </Box>
                  <Box
                    gridColumn="span 4"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    border="1px solid none"
                    flexDirection="column"
                    backgroundColor="#FFFFFF"
                    height="calc(100% - 38px)"
                    borderRadius="10px"
                  >
                    <Box gridColumn="span 4" gridRow="span 2">
                      <Box
                        style={{ height: "377px" }}
                        className="industry_analytics_bar_chart"
                      >
                        <BarChart isDashboard={true} dateRange={dateRange} />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Analytic
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    View by:
                  </label>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        onChange={handleDateRangeChange}
                        value={dateRange}
                      />
                    </Space>
                  </div>
                  <Button
                    onClick={async () => {
                      try {
                        const { startDate, endDate } = exportData(dateRange);
                        const response = await fetch(
                          `https://www.smart-lend.com/api/analytics/exportExcel?start_date=${startDate}&end_date=${endDate}`,
                          {
                            method: "GET",
                            headers: {
                              Authorization: "Bearer " + Cookies.get("token"),
                              Accept: "application/json",
                            },
                          }
                        );
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          `${email}_Analytics_${startDate}_${endDate}.xlsx`
                        );
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    sx={{
                      backgroundColor: "none",
                      color: "#0E5AA5",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "2px 10px",
                      border: "1px solid #00000066",
                      marginTop: "5px",
                      width: "100%",
                      borderRadius: "10px",
                      textTransform: "none", // Add this line to allow small letters in the button text
                      "&:hover": {
                        backgroundColor: "#0E5AA5",
                        color: "#ffffff",
                      },
                    }}
                  >
                    <DownloadOutlinedIcon
                      sx={{
                        mr: "5px",
                        transition: "0.1s",
                        "&:hover": { color: "#ffffff" },
                      }}
                    />
                    Export
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="10px"
              >
                Forecast Revenue
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                border: "1px solid none",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
              }}
            >
              <Box height="300px">
                <LineChart isDashboard={true} dateRange={dateRange} />
              </Box>
            </div>
            <div>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="10px"
              >
                Interest Profit
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                border: "1px solid none",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
              }}
            >
              <Box height="300px">
                <LineChartIntrestProfit
                  isDashboard={true}
                  dateRange={dateRange}
                />
              </Box>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "auto",
                  marginRight: "5px",
                }}
              >
                <ContainerPieChart dateRange={dateRange}></ContainerPieChart>
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "20px",
                }}
              >
                <ContainerLineChart dateRange={dateRange}></ContainerLineChart>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "207px",
                  marginRight: "5px",
                }}
              >
                <ContainerRow1
                  title="Avg. Principal Amount"
                  subtitle="You've loaned"
                  label={
                    averagePaidPrincipal
                      ? `$${averagePaidPrincipal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}`
                      : "0"
                  }
                />
              </div>
              <div
                style={{
                  width: "49%",
                  opacity: "1.00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  border: "1px solid white",
                }}
              >
                <Box height={207}>
                  <div style={{ marginTop: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <h3
                        style={{
                          display: "inline-block",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "20px",
                          marginRight: "80px",
                          color: "#142C44CC",
                          marginLeft: "15px",
                        }}
                      >
                        Clients
                      </h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      marginTop: "-8px",
                      marginBottom: "-75px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        fontSize: "10px",
                        color: "#142C44CC",
                        marginLeft: "15px",
                      }}
                    >
                      You've gained {averageForSelectedMonth} new client of this
                      month
                    </p>
                  </div>

                  <ClientBarChart
                    isDashboard={true}
                    width="100%"
                    height={150}
                    dateRange={dateRange}
                    onAverageClientsUpdate={handleAverageClientsUpdate}
                  />
                </Box>
              </div>
            </div>
            <div>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
                marginTop="10px"
              >
                Industries Analytics
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                border: "1px solid none",
                flexDirection: "column",
                backgroundColor: "#FFFFFF",
                height: "50%",
                borderRadius: "20px",
              }}
            >
              <Box>
                <BarChart isDashboard={true} dateRange={dateRange} />
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Analytics;
