import React, { useState } from "react";
// import { LoadingOutlined } from '@ant-design/icons';
// import { Spin } from 'antd';
import { Switch, Route } from "react-router-dom";
import Topbar from "../../admin/global/Topbar";
import Sidebar from "../../admin/global/Sidebar";
import ApplyLoan from "../../borrower/loan/apply-loan";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Loan from "../../borrower/loan/loan";
import OngoingLoans from "../../borrower/loan/ongoing-loans";
import RequestedLoans from "../../borrower/loan/pending-loans";
import Agreements from "../../borrower/agreements";
import Transactions from "../../borrower/transactions";
import Profile from "../../borrower/profile";
import { Link } from "react-router-dom";
import Users from "../../admin/users"; 
import OngoingAgreement from "../../admin/agreement/ongoing-agreement";
import GenerateAgreement from "../../admin/agreement/generate-agreement";
import PendingSign from "../../admin/agreement/pending-sign";
import LenderPayment from "../../admin/payment/lender-payment";
import BorrowerPayment from "../../admin/payment/borrower-payment";
import Advertisement from "../../admin/advertisement";
import OfferedLoan from "../../admin/loan/offered-loan";
import OngoingLoan from "../../admin/loan/ongoing-loan";
import RequestedLoan from "../../admin/loan/request-loan";
import AdminLoan from "../../admin/loan/admin-loan";
import AdminAnalytic from "../../admin/admin-analytics";
import { SearchProvider } from "../../admin/search-context";
import UserDatabase from "../../admin/user-database";
import RedFlagged from "../../admin/loan/red-flagged";
import NewsAndEvents from "../../admin/nande";
import AdminMessaging from "../../borrower/loan/message-admin";
import VerifyBorrower from "../../admin/verify-borrower";
import ViewFeedback from "../../admin/view-feedback";
import CorporateStructureLoan from "../../admin/loan/corporate-structure-loan";

// const antIcon = (
//   <LoadingOutlined
//     style={{
//       fontSize: 24,
//     }}
//     spin
//   />
// );

function DashboardAdminLayout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = React.useState("");

  // const handleRouteClick = () => {
  //   setIsLoading(true);
  //   // You can add a timeout here to simulate a loading process
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // };

  return (
    <SearchProvider value={{ search, setSearch }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main
              className="contentAdmin"
              style={{ overflowY: "hidden", height: "100vh" }}
            >
              <Topbar setIsSidebar={setIsSidebar} />
              {/* {isLoading && <Spin indicator={antIcon} />} */}
              <Switch>
                <Route path="/a-users" component={Users} />
                <Route path="/a-loan" component={AdminLoan} />
                <Route path="/a-offered-loan" component={OfferedLoan} />
                <Route path="/a-requested-loan" component={RequestedLoan} />
                <Route path="/a-corporate-structure-loan" component={CorporateStructureLoan} />
                <Route path="/a-ongoing-loan" component={OngoingLoan} />
                <Route path="/b-requested-loans" component={RequestedLoans} />
                <Route path="/b-agreements" component={Agreements} />
                <Route
                  path="/a-ongoing-agreement"
                  component={OngoingAgreement}
                />
                <Route
                  path="/a-generate-agreement"
                  component={GenerateAgreement}
                />
                <Route path="/a-pending-sign" component={PendingSign} />
                <Route path="/a-lender-payment" component={LenderPayment} />
                <Route path="/a-borrower-payment" component={BorrowerPayment} />
                <Route path="/a-advertisement" component={Advertisement} />
                <Route path="/a-analytic" component={AdminAnalytic} />
                <Route path="/b-profile" component={Profile} />
                <Route path="/a-user-database" component={UserDatabase} />
                <Route path="/a-news-events" component={NewsAndEvents} />
                <Route path="/a-verify-borrower" component={VerifyBorrower} />
                <Route path="/a-red-flagged" component={RedFlagged} />
                <Route path="/a-message" component={AdminMessaging} />
                <Route path="/a-feedback" component={ViewFeedback} />
              </Switch>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SearchProvider>
  );
}

export default DashboardAdminLayout;
