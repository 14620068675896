import { Form, Button } from "antd";
import { Table } from "antd";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";

const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};

const table1titleStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "bold",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "200",
};

const table1dataStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "200",
  fontFamily: "Poppins",
  fontSize: "14px",
  // borderBottom: "1px solid red",
};

const table2titleStyle = {
  textAlign: "left",
  width: "1%",
  fontWeight: "bold",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "200",
};

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const formatDate2 = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const columns = [
  {
    dataIndex: "",
    key: "account_balance",
    render: (text, record) => (
      <td style={table1titleStyle}>
        Ordinary Account (OA)
        <hr style={{ width: "100%" }} />
        Special Account (SA)
        <hr style={{ width: "100%" }} />
        Medisave Account (MA)
        <hr style={{ width: "100%" }} />
        Retirement Account (RA)
      </td>
    ),
  },
  {
    dataIndex: "",
    key: "account_balance",
    render: (text, record) => (
      <td style={table1dataStyle}>
        {record.ordinary_account.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        <hr style={{ width: "100%" }} />
        {record.special_account.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        <hr style={{ width: "100%" }} />
        {record.medisave_account.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        <hr style={{ width: "100%" }} />
        {record.retirement_account.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </td>
    ),
  },
];
const columns2 = [
  {
    title: <span style={table2titleStyle}>For Month</span>,
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {formatDate(text)}
      </a>
    ),
  },
  {
    title: <span style={table2titleStyle}>Paid On</span>,
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {formatDate2(text)}
      </a>
    ),
  },
  {
    title: <span style={table2titleStyle}>Amount ($)</span>,
    dataIndex: "amount",
    key: "amount",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {text.toLocaleString("en-US", { style: "currency", currency: "USD" })}
      </a>
    ),
  },
  {
    title: <span style={table2titleStyle}>Employer Contribution</span>,
    dataIndex: "employer",
    key: "employer",
    render: (text) => (
      <a
        style={{
          fontWeight: "400",
          fontFamily: "Manrope",
          fontSize: "14px",
        }}
      >
        {text}
      </a>
    ),
  },
];

const Finance = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewPersonal?type=Finance",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        setData(responseData["CPF Contribution History"]);
        setData2(responseData["Finance Account Balance"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ minHeight: "73vh" }}>
      <h5
        style={{
          marginTop: "20px",
          fontWeight: "800",
          fontFamily: "Manrope",
          fontSize: "18px",
        }}
      >
        CPF Account Balance
      </h5>
      <Table
        columns={columns}
        dataSource={data2}
        pagination={false}
        bordered
        showHeader={false}
        style={{
          width: screenWidth > 1200 ? "40%" : "100%",
          border: "1px solid #979797",
          borderRadius: "10px",
          overflow: "hidden", // To make sure borderRadius applies to all inner elements
        }}
      />{" "}
      <h5
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          fontWeight: "800",
          fontFamily: "Manrope",
          fontSize: "18px",
        }}
      >
        CPF Contribution History
      </h5>
      <Table
        columns={columns2}
        dataSource={data}
        pagination={false}
        bordered
        style={{
          width: screenWidth > 1200 ? "60%" : "100%",
          border: "1px solid #979797",
          borderRadius: "10px",
          overflow: "hidden", // To make sure borderRadius applies to all inner elements
        }}
      />{" "}
      <Form.Item {...tailLayout} style={{ marginTop: "30px" }}>
        <Button type="default" htmlType="button">
          Edit
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }}>
          Save
        </Button>
      </Form.Item>
    </div>
  );
};

export default Finance;
