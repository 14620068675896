import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
const { Option } = Select;
// const onChange = (date, dateString) => {
//     console.log(date, dateString);
// };

const Appointments = () => {
  const [uen, setUEN] = useState("");
  const [primaryActivity, setPrimaryActivity] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityStatus, setEntityStatus] = useState("");
  const [businessConstitution, setBusinessConstitution] = useState("");
  const [ownership, setOwnership] = useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [businessExpiryDate, setBusinessExpiryDate] = useState("");
  const [companyType, setCompanyType] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [basicProfile, setBasicProfile] = useState({});
  const [appointment, setAppointment] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const entityDetail = displaySingpassData.singpass_data.entity;

        console.log("details", entityDetail);

        setBasicProfile(entityDetail["basic-profile"]);

        // Ensure that you are setting the array of appointments
        if (
          entityDetail &&
          entityDetail.appointments &&
          Array.isArray(entityDetail.appointments["appointments-list"])
        ) {
          setAppointment(entityDetail.appointments["appointments-list"] || []);
        } else {
          // Handle the case where appointments data is not an array or not available
          setAppointment([]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {appointment.length > 0 ? (
        appointment.map((item, index) => (
          <React.Fragment key={index}>
            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Identification Number">
                  <Input
                    style={{ width: "90%" }}
                    value={item["person-reference"]?.idno?.value}
                    disabled={
                      !!item["person-reference"]?.idno?.value ||
                      !item["person-reference"]?.idno?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 16}>
                <Form.Item label="Person Name">
                  <Input
                    style={{ width: "90%" }}
                    value={item["person-reference"]["person-name"]?.value}
                    disabled={
                      !!item["person-reference"]["person-name"]?.value ||
                      !item["person-reference"]["person-name"]?.value
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Nationality/Citizenship">
                  <Input
                    style={{ width: "90%" }}
                    value={item["person-reference"]?.nationality?.desc}
                    disabled={
                      !!item["person-reference"]?.nationality?.desc ||
                      !item["person-reference"]?.nationality?.desc
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Corppass Email Address">
                  <Input
                    style={{ width: "90%" }}
                    value={item["corppass-email"]?.value}
                    disabled={
                      !!item["corppass-email"]?.value ||
                      !item["corppass-email"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Corppass Mobile Number">
                  <Input
                    style={{ width: "90%" }}
                    value={item["corppass-mobileno"]?.value}
                    disabled={
                      !!item["corppass-mobileno"]?.value ||
                      !item["corppass-mobileno"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="UEN">
                  <Input
                    style={{ width: "90%" }}
                    value={basicProfile.uen?.value || ""}
                    disabled={
                      !!basicProfile.uen?.value || !basicProfile.uen?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Entity Name">
                  <Input
                    style={{ width: "90%" }}
                    value={basicProfile["entity-name"]?.value || ""}
                    disabled={
                      !!basicProfile["entity-name"]?.value ||
                      !basicProfile["entity-name"]?.value
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Entity Type">
                  <Select
                    style={{ width: "90%" }}
                    value={basicProfile["entity-type"]?.desc || ""}
                    disabled={
                      !!basicProfile["entity-type"]?.desc ||
                      !basicProfile["entity-type"]?.desc
                    }
                  >
                    <Option value="Singaporean">Singaporean</Option>
                    <Option value="Malaysian">Malaysian</Option>
                    <Option value="Chinese">Chinese</Option>
                    <Option value="Indian">Indian</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Category">
                  <Select
                    style={{ width: "90%" }}
                    value={item?.category?.desc}
                    disabled={!!item?.category?.desc || !item?.category?.desc}
                  >
                    <Option value="Singaporean">Singaporean</Option>
                    <Option value="Malaysian">Malaysian</Option>
                    <Option value="Chinese">Chinese</Option>
                    <Option value="Indian">Indian</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Position">
                  <Input
                    style={{ width: "90%" }}
                    value={item?.position?.desc}
                    disabled={!!item?.position?.desc || !item?.position?.desc}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 11 : 8}>
                <Form.Item label="Appointment Date">
                  <Input
                    style={{ width: "90%" }}
                    value={item["appointment-date"]?.value}
                    disabled={
                      !!item["appointment-date"]?.value ||
                      !item["appointment-date"]?.value
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr style={{ color: "grey", width: "97%" }} />
          </React.Fragment>
        ))
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0px 0px",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "0px 15px",
              background: "rgba(255,255,255,0.7)",
              borderRadius: "10px",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Manrope, sans-serif",
              fontSize: "0.8em",
              fontWeight: "600",
              color: "#00000066",
            }}
          >
            You have no appointments
          </div>
        </div>
      )}
      {/* <div style={{ display: "flex", gap: "10px", marginTop: "40px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default Appointments;
