import "./App.css";
import React, { useState, useEffect } from "react";
import Macbook from "./img/macbook_mockup.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import IphoneImage from "./img/iphone_mockup.png";
import verified from "../../../assets/img/verified.png";
import disbursement from "../../../assets/img/disbursement.png";
import mutual_agreement from "../../../assets/img/mutual_agreement.png";
import one from "../../../assets/img/one.png";
import two from "../../../assets/img/two.png";
import three from "../../../assets/img/three.png";
import four from "../../../assets/img/four.png";
import SgIcon from "./img/197496.png";
import CloseIcon from "@mui/icons-material/Close";
import bank_image from "../../../assets/img/bank_image.png";
import { Modal, Button, message } from "antd";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";
import HomeHeader from "./Headesr.jsx";

const PartnerWithUs = () => {
  // const navigate = useHistory();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [emailSubscription, setEmailSubscription] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [createLenderModal, setCreateLenderModal] = useState(false);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [lenderDetails, setLenderDetails] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
  });

  const SendEmail = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/smartlend/lenderCreateEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(lenderDetails),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        setCreateLenderModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Simple email validation function
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle input change
  const handleInputChange = (e) => {
    const email = e.target.value;
    setEmailSubscription(email);
    setIsEmailValid(validateEmail(email));
  };

  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer:
        "Premelo is designed for anyone who needs to manage tasks, projects, and teams, including freelancers, small businesses, and large enterprises.",
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 7,
      title: "How much does Premelo cost?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 8,
      title: "What is Workflow?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 9,
      title: "What is Platform Customization?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 10,
      title: "What does the custom domain and hosting do?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 11,
      title: "11.	What is Social Media connect?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 12,
      title: "What is Centralized Messaging system meant?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 13,
      title: "How many projects can I create from Premelo and Premelo Plus? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 14,
      title: "How many tasks can I create per project?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 15,
      title: "How many assignees can I assign per task? ",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 16,
      title: "I can’t use the workflow function why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 17,
      title: "My calendar is not getting updated with the task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 18,
      title: "I am not getting notify by my notification. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 19,
      title: "My profile is not being updated. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
    {
      id: 20,
      title: "I am unable to add attachments to a task. Why?",
      answer:
        "Getting started with Premelo is easy. Simply sign up for a free account and start exploring our features. You can upgrade to our premium subscription, Premelo Plus, to unlock even more features and benefits.",
    },
  ];

  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEmailSubscription = async (event) => {
    event.preventDefault();

    try {
      let item = {
        email: emailSubscription,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/guest/subscribeEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      let data;
      if (
        response.ok &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        data = await response.json();
        // Display success message
        message.success(data.message);
      } else {
        // If response is not ok or not JSON, consider it as an email already subscribed case
        message.error("Your email already subscribed!");
        // throw new Error('Your email already subscribed!');
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      message.error(error);
    }
  };

  const FAQItem = ({ id, title, answer, image }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    const [hoveredState, setHoveredState] = useState(false);

    return (
      <div
        onMouseOver={() => setHoveredState(true)}
        onMouseLeave={() => setHoveredState(false)}
        style={{
          width: "22%",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          marginRight: "2.5%",
          marginBottom: "2.5%",
          cursor: "pointer",
        }}
      >
        <div style={{}} onClick={handleToggle} className="mui-button">
          {/* <IconButton onClick={handleToggle}>   
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton> */}
          <img
            src={image}
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "20px 20px 0px 0px",
              outline: "none",
              border: "none",
            }}
          />
          <div
            style={{
              padding: "0% 5%",
              fontWeight: "bold",
              margin: "10px 0px -5px 0px",
            }}
          >
            {title}
          </div>
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "300",
            padding: "0% 5%",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          {answer.length > 200 ? answer.substring(0, 200) + " ..." : answer}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "5%",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "300",
              marginTop: "10px",
              marginBottom: "20px",
              textAlign: "right",
              opacity: hoveredState ? 1 : 0,
              background: "#256199",
              color: "white",
              borderRadius: "10px",
              padding: "7px 15px",
              transition: "all 0.3s ease-in-out",
            }}
          >
            Read more ...
          </div>
        </div>
      </div>
    );
  };

  const [privacyBar, setPrivacybar] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
    if (screenWidth > 1200) {
      setPrivacybar(true);
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 1200) {
      setPrivacybar(false);
    }
  }, [screenWidth]);
  return (
    <div style={{ width: "100%", overflowX: "hidden", position: "relative" }}>
      <div
        style={{
          background: "white",
          position: "fixed",
          width: "100%",
          height: "40px",
          display: privacyBar ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: "49",
          background: "rgb(37, 97, 153)",
        }}
      >
        <div
          style={{
            marginLeft: "20px",
            color: "rgba(0,0,0,0.7)",
            fontSize: "14px",
            color: "white",
          }}
        >
          Information is collected in accordance with our Privacy Policy. By
          continuing you agree to our terms.
        </div>
        <CloseIcon
          style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
          onClick={() => setPrivacybar(false)}
        />
      </div>
      {/* Header bar */}
      <div
        style={{
          position: "fixed",
          background: "white",
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
          width: "100%",
          top: privacyBar ? "40px" : "0px",
          zIndex: "50",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <div style={{ width: "25%", display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "35px",
                color: "#256199",
              }}
            >
              SmartLend
            </div>
          </div>
          <div
            style={{
              width: "75%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="menu_button"
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Home
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/whysmartlend");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Why SmartLend?
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "700",
                color: "#256199",
                marginRight: "10px",
              }}
            >
              Partner With Us
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              News & Events
            </div>

            <div
              className="menu_button"
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
              style={{
                fontWeight: "500",
                color: "rgba(0,0,0,0.7)",
                marginRight: "10px",
              }}
            >
              Contact Us
            </div>
            <hr
              style={{
                width: "2px",
                height: "20px",
                margin: "0px",
                outline: "none",
                border: "none",
                background: "rgba(0,0,0,0.3)",
              }}
            />
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signup");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#256199",
                border: "2px solid #256199",
                padding: "5px 30px",
                borderRadius: "15px",
              }}
            >
              Sign Up
            </div>
            <div
              className="menu_button"
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: "5px 40px",
                borderRadius: "15px",
              }}
            >
              Login
            </div>
          </div>
        </div> */}
        <HomeHeader />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: screenWidth > 1200 ? "90px" : "150px",
          height: screenWidth > 1200 ? "90vh" : "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: screenWidth > 1200 ? "40%" : "100%",
              textAlign: "left",
              marginLeft: pageLoad ? "0px" : "-300px",
              opacity: pageLoad ? "1" : "0",
              transition: "all 0.8s ease-in-out",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "45px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "50px",
                }}
              >
                Join Forces for Success:
              </div>
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "40px",
                }}
              >
                Partner with SmartLend!
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "20px",
                  marginTop: "20px",
                }}
              >
                Are you a Non-Banking Financial Company, Peer to Peer Platform,
                Family offices, Fintech Lenders or Alternative Debt Funds
                looking to amplify your reach and impact? SmartLend invites you
                to collaborate and redefine the future of finance.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "rgba(0,0,0,0.8)",
                  lineHeight: "20px",
                  marginTop: "10px",
                }}
              >
                By partnering with us, you gain access to a dynamic ecosystem
                that offers cutting -edge technology, a vast network of
                borrowers, and innovative solutions.
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  className="menu_button"
                  onClick={() => setCreateLenderModal(true)}
                  style={{
                    background: "#256199",
                    color: "white",
                    fontSize: "20px",
                    padding: "7px 35px",
                    margin: "0px 20px 0px 0px",
                    borderRadius: "30px",
                    cursor: "pointer",
                  }}
                >
                  Partner with us
                </div>
              </div>
            </div>
          </div>
          {screenWidth > 1200 ? (
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={Macbook}
                style={{
                  objectFit: "cover",
                  width: "150%",
                  opacity: pageLoad ? "1" : "0",
                  marginRight: pageLoad ? "-100px" : "-500px",
                  transition: "all 0.8s ease-in-out",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "80px",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: "0px 10%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "20px",
                  width: "100%",
                  marginTop: screenWidth > 1200 ? "150px" : "0px",
                }}
              >
                <div
                  style={{
                    // background: "#256199",
                    // color: "white",
                    // height: "60px",
                    // width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // fontSize: "25px",
                    // borderRadius: "10px",
                    // marginRight: "15px",
                  }}
                >
                  <img
                    src={one}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "40px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Diversified Loan Portfolio
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Access to a Diverse Range of Borrowers: By partnering with
                    us, you gain access to a broad and varied pool of SME
                    borrowers, each with their unique needs and credit profiles.
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "40%" : "100%",
                marginTop: screenWidth > 1200 ? "0px" : "30px",
              }}
            >
              <img
                src={verified}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: screenWidth > 1200 ? "-200px" : "50px",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={mutual_agreement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                // marginTop: "30px",
                // alignItems: "flex-start",
                justifyContent: "center",
                height: "100%",
                marginTop: screenWidth > 1200 ? "30px" : "0px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      // background: "#256199",
                      // color: "white",
                      // height: "60px",
                      // width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // fontSize: "25px",
                      // borderRadius: "10px",
                      // marginRight: "15px",
                    }}
                  >
                    <img
                      src={two}
                      style={{
                        height: "600px",
                        position: "absolute",
                        objectFit: "contain",
                        marginTop: "-250px",
                        right: "600px",
                      }}
                    />
                    {/* 1 */}
                  </div>
                  <div
                    style={{
                      width: screenWidth > 1200 ? "90%" : "100%",
                      marginTop: screenWidth > 1200 ? "0px" : "30px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "30px",
                        fontWeight: "800",
                        color: "#256199",
                      }}
                    >
                      Increased Efficiency
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,0.8)",
                        marginTop: "5px",
                      }}
                    >
                      Streamlined Lending Process: Our platform streamlines the
                      lending process, reducing administrative overhead and
                      accelerating loan origination. It's a win-win for both
                      lenders and borrowers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              // marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                // marginTop: "30px",
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "20px",
                  width: "100%",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    // background: "#256199",
                    // color: "white",
                    // height: "60px",
                    // width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // fontSize: "25px",
                    // borderRadius: "10px",
                    // marginRight: "15px",
                  }}
                >
                  <img
                    src={three}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "-200px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Transparency and Insights:
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Access to Valuable Data: We provide lenders with insights
                    and data analytics to help you make informed lending
                    decisions and refine your lending strategies.
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={disbursement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                  marginTop: screenWidth > 1200 ? "-190px" : "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: screenWidth > 1200 ? "-200px" : "50px",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={mutual_agreement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                // marginTop: "30px",
                // alignItems: "flex-start",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      // background: "#256199",
                      // color: "white",
                      // height: "60px",
                      // width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // fontSize: "25px",
                      // borderRadius: "10px",
                      // marginRight: "15px",
                    }}
                  >
                    <img
                      src={two}
                      style={{
                        height: "600px",
                        position: "absolute",
                        objectFit: "contain",
                        marginTop: "-250px",
                        right: "600px",
                      }}
                    />
                    {/* 1 */}
                  </div>
                  <div
                    style={{
                      width: screenWidth > 1200 ? "90%" : "100%",
                      marginTop: screenWidth > 1200 ? "0px" : "30px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "30px",
                        fontWeight: "800",
                        color: "#256199",
                      }}
                    >
                      Market Expansion
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,0.8)",
                        marginTop: "5px",
                      }}
                    >
                      Reach New Markets: Our platform opens doors to SMEs that
                      may have been previously inaccessible, allowing you to tap
                      into new markets and expand your lending reach
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "85%" }}>
          <div
            style={{
              padding: screenWidth > 1200 ? "0px 10%" : "0px 10px",
              // marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: screenWidth > 1200 ? "row" : "column-reverse",
            }}
          >
            <div
              style={{
                width: screenWidth > 1200 ? "60%" : "100%",
                display: "flex",
                flexDirection: "column",
                // marginTop: "30px",
                // alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "20px",
                  width: "100%",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    // background: "#256199",
                    // color: "white",
                    // height: "60px",
                    // width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // fontSize: "25px",
                    // borderRadius: "10px",
                    // marginRight: "15px",
                  }}
                >
                  <img
                    src={three}
                    style={{
                      height: "600px",
                      position: "absolute",
                      objectFit: "contain",
                      left: "121px",
                      marginTop: "-200px",
                    }}
                  />
                  {/* 1 */}
                </div>
                <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      fontWeight: "800",
                      color: "#256199",
                      marginTop: "15px",
                    }}
                  >
                    Financial Inclusion
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,0.8)",
                      marginTop: "5px",
                    }}
                  >
                    Support Financial Inclusion: Partnering with us contributes
                    to the broader goal of financial inclusion by helping SMEs
                    access the capital they need to thrive.
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: screenWidth > 1200 ? "40%" : "100%" }}>
              <img
                src={disbursement}
                style={{
                  height: screenWidth > 1200 ? "600px" : "auto",
                  width: "100%",
                  objectFit: "contain",
                  marginTop: screenWidth > 1200 ? "-190px" : "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer section */}
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
          marginTop: "100px",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div style={{ fontWeight: "300", fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%", }}>
            Smart Towkay Ventures Pte. Ltd., SmartLend Financial Pte. Ltd., are
            part of the Smart Towkay Brand.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "3px",
            width: screenWidth > 1200 ? "100%" : "80%",
            marginLeft: screenWidth > 1200 ? "0%" : "10%", }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <img
              className="menu_button"
              src={tiktokIcon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Enterprise
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
            >
              SmartLend for Business
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              SmartAds
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              Express Loan
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "30%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>

      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          margin: "10% auto",
        }}
        open={createLenderModal}
        onCancel={() => setCreateLenderModal(false)}
        footer={[]}
      >
        <div style={{ textAlign: "center", padding: "30px 0px 0px 0px" }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Want to be a lender
          </div>
          <form
            style={{
              fontSize: "14px",
              fontWeight: 400,
              width: "100%",
              marginTop: "15px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              setCreateLenderModal(false);
              SendEmail();
            }}
          >
            <input
              type="text"
              value={lenderDetails.first_name}
              onChange={(e) => {
                setLenderDetails({
                  ...lenderDetails,
                  first_name: e.target.value,
                });
              }}
              required
              style={{
                width: "46%",
                padding: "5px 15px",
                borderRadius: "5px",
                outline: "1px solid lightgrey",
                border: "none",
                marginRight: "3%",
              }}
              placeholder="First name"
            />
            <input
              type="text"
              value={lenderDetails.last_name}
              onChange={(e) => {
                setLenderDetails({
                  ...lenderDetails,
                  last_name: e.target.value,
                });
              }}
              required
              style={{
                width: "46%",
                padding: "5px 15px",
                borderRadius: "5px",
                outline: "1px solid lightgrey",
                border: "none",
              }}
              placeholder="Last name"
            />
            <input
              type="tel"
              value={lenderDetails.phone_number}
              onChange={(e) => {
                setLenderDetails({
                  ...lenderDetails,
                  phone_number: e.target.value,
                });
              }}
              required
              style={{
                width: "95%",
                padding: "5px 15px",
                borderRadius: "5px",
                outline: "1px solid lightgrey",
                border: "none",
                marginTop: "10px",
              }}
              placeholder="+65"
            />
            <input
              type="email"
              value={lenderDetails.email_address}
              onChange={(e) => {
                setLenderDetails({
                  ...lenderDetails,
                  email_address: e.target.value,
                });
              }}
              required
              style={{
                width: "95%",
                padding: "5px 15px",
                borderRadius: "5px",
                outline: "1px solid lightgrey",
                border: "none",
                marginTop: "10px",
              }}
              placeholder="Email address"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
                gap: "5%",
              }}
            >
              <button
                style={{
                  padding: "5px 20px",
                  borderRadius: 5,
                  border: "1px solid rgb(37, 97, 153)",
                  outline: "none",
                  color: "rgb(37, 97, 153)",
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => setCreateLenderModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{
                  padding: "5px 20px",
                  borderRadius: 5,
                  background: "rgb(37, 97, 153)",
                  border: "none",
                  outline: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
export default PartnerWithUs;
