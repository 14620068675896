import { Space, Table, Tag, Radio, Button, Modal, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableUserBorrower = ({
  onRowClick,
  onSelectionChange,
  reloadTableBorrower,
  setReloadTableBorrower,
 
}) => {
  const [data, setData] = useState([]);
  const [dataRowClick, setDataRowClick] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});
  const [modalStates4, setModalStates4] = useState({});
  const [modalStates5, setModalStates5] = useState({});
  const [screenWidth, setScreenWidth] = useState("1920");
  const [credit_score, setCreditScore] = useState("");
  const [fileUploading, setFileUploading] = useState(false);
  const [fileName, setFileName] = useState('');


  const handleRowClick = async (record) => {
    const userId = record.user_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewUserDetail?user_id=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setDataRowClick(json["User"]);
      showModal4(userId);
    } catch (error) {
      console.error("Error retrieving User:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      setReloadTableBorrower(true); // <-- trigger table reload
      console.log("User deleted successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  const handleDeactivate = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=deactivate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      setReloadTableBorrower(true); // <-- trigger table reload
      console.log("User deactivate successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to deactivate user", error);
    }
  };

  const handleActivate = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=activate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      setReloadTableBorrower(true); // <-- trigger table reload
      console.log("User activate successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to activate user", error);
    }
  };

  const handleSaveCreditScore = async (userId) => {
    try {
      let item = {
        user_id: userId,
        credit_score: credit_score,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/user/updateCreditScore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },

          body: JSON.stringify(item),
        }
      );

      const data = await response.json();

      // Refresh your table data here
      setReloadTableBorrower(true); // <-- trigger table reload

      message.success("Borrower credit score saved successfully");
    } catch (error) {
      message.error(error);
      console.log("error:", error);
    }
  };

  const handleFileSelect = (event, user_id) => {
    const file = event.target.files[0]; // Get the first selected file
    setFileUploading(true);

    if (file) {
      // Create a FormData object
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("credit_report", file);

      setFileName(file.name);

      // Send a POST request with FormData
      fetch("https://www.smart-lend.com/api/user/updateCreditReport", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("value data", data);
          if (data.status === "active") {
            message.success("Credit Report updated successfully");
            // Refresh your table data here
            setReloadTableBorrower(true); // <-- trigger table reload
          } else {
            message.success(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading agreement file:", error);
        });
      console.log("data value:", data);
      // const changingData = [...data];
      // const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
      // changingData[dataIndex].document = 1;
      // setData(changingData);
      setFileUploading(false);
    }
  };

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    const initialStates3 = {};
    const initialStates4 = {};
    const initialStates5 = {};

    data.forEach((item) => {
      initialStates[item.userId] = false;
      initialStates2[item.userId] = false;
      initialStates3[item.userId] = false;
      initialStates4[item.userId] = false;
      initialStates5[item.userId] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
    setModalStates3(initialStates3);
    setModalStates4(initialStates4);
    setModalStates5(initialStates5);
  }, [data]);

  const showModal = (userId) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal2 = (userId) => {
    setModalStates2((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal3 = (userId) => {
    setModalStates3((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal4 = (userId) => {
    console.log("value in showModal4", userId);
    setModalStates4((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal5 = (userId) => {
    setModalStates5((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/user/viewAllUser",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setData(json["Borrower"]);
        // Reset `reloadTable` after data fetch is complete
        if (reloadTableBorrower !== false) {
          // or: if (reloadTable !== baselineValue)
          setReloadTableBorrower(false); // or: setReloadTable(baselineValue)
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadTableBorrower, setReloadTableBorrower]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Email
        </span>
      ),
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Borrower Name
        </span>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          User Account Type
        </span>
      ),
      dataIndex: "user_account_type",
      key: "user_account_type",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          NRIC
        </span>
      ),
      dataIndex: "nric",
      key: "nric",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Mobile No.
        </span>
      ),
      dataIndex: "mobile_no",
      key: "mobile_no",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Credit Score
        </span>
      ),
      dataIndex: "credit_score",
      key: "credit_score",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      fixed: "right",
      key: "action",
      width: 110,
      render: (text, record) => (
        <Button
          type="primary"
          onClick={(event) => {
            event.stopPropagation();
            showModal(record.user_id);
          }}
          danger
        >
          Delete
        </Button>
      ),
    },
    {
      fixed: "right",
      key: "action",
      width: 150,
      render: (text, record) =>
        record.status === "active" ? (
          <Button
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              showModal2(record.user_id);
            }}
            danger
          >
            Deactivate
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              showModal3(record.user_id);
            }}
            style={{ backgroundColor: "#00DA5E", width: "90%" }}
          >
            Activate
          </Button>
        ),
    },
    {
      fixed: "right",
      key: "action",
      width: 130,
      render: (text, record) => (
        <div>
          <label
            onClick={(event) => {
              event.stopPropagation();
              showModal5(record.user_id);
            }}
            className="all_button"
            style={{
              background: "#1557a9",
              color: "white",
              width: "100%",
              border: "none",
              borderRadius: "50px",
              padding: "6px 0px",
              fontSize: "12px",
              margin: "10px 0px 0px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            Credit Score
          </label>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="user_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              handleRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
        scroll={{
          x: 1700,
        }}
      />
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates[item.user_id]}
            onCancel={() =>
              setModalStates((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleDelete(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to delete this borrower?
          </Modal>
        ))}
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates2[item.user_id]}
            onCancel={() =>
              setModalStates2((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleDeactivate(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates2((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to deactivate this borrower?
          </Modal>
        ))}
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates3[item.user_id]}
            onCancel={() =>
              setModalStates3((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleActivate(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates3((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to activate this borrower?
          </Modal>
        ))}
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates5[item.user_id]}
            onCancel={() => {
              setModalStates5((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }));
              setFileName(''); // Reset the file name when the modal is closed
            }}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <div>
                  <label
                    onClick={(event) => {
                      handleSaveCreditScore(item.user_id);
                    }}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 30px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </label>
                </div>

                <div>
                  <label
                    onClick={() => {
                      setModalStates5((prevStates) => ({
                        ...prevStates,
                        [item.user_id]: false,
                      }));
                      setFileName(''); // Reset the file name when the modal is closed
                    }}
                    className="all_button"
                    style={{
                      background: "transparent",
                      color: "black",
                      width: "100%",
                      border: "1px solid black",
                      borderRadius: "50px",
                      padding: "6px 15px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </label>
                </div>

                {/* <Button
                  type="default"
                  onClick={() =>
                    setModalStates5((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                 Cancel
                </Button> */}
              </div>,
            ]}
          >
            <div
              style={{
                marginBottom: "10px",
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "400",
              }}
            >
              Please fill in the credit score and upload the credit report
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                width: "70%",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  width: "40%",
                }}
              >
                Credit Score:
              </div>
              <input
                style={{
                  borderRadius: "20px",
                  paddingLeft: "10px",
                  width: "60%",
                }}
                type="number"
                max="100"
                placeholder="Credit Score"
                value={credit_score }
                onChange={(e) => {
                  const val = e.target.value;
                  if (val <= 100) {
                    setCreditScore(val);
                  }
                }}
              />
            </div>
            <div style={{ display: "flex", width: "70%" }}>
              <div
                style={{
                  marginRight: "10px",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  width: "40%",
                }}
              >
                Credit Report:
              </div>
              <div style={{ width: "60%" }}>
                <label
                  onClick={(event) => {
                    event.stopPropagation();
                    console.log(item.user_id);
                  }}
                  // htmlFor={`fileInput${item.user_id}`}
                  className="all_button"
                  style={{
                    background: "#1557a9",
                    color: "white",
                    width: "100%",
                    border: "none",
                    borderRadius: "50px",
                    padding: "6px 10px",
                    fontSize: "12px",
                    margin: "0px 0px 0px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  Upload Credit Report
                  <input
                    type="file"
                    // id={`fileInput${item.user_id}`}
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileSelect(e, item.user_id)}
                  />
                </label>
              </div>
            
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
       

       {/* Display file name as a preview */}
          {fileName && <div>Selected File: {fileName}</div>}
       </div>
          </Modal>
        ))}

      {dataRowClick &&
        dataRowClick.length > 0 &&
        dataRowClick.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={modalStates4[item.user_id]}
            onCancel={() =>
              setModalStates4((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={
              [
                // <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                //   <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                // </div>
              ]
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 16,
                borderRadius: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_amount"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Email
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.email || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_interest_rate"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  NRIC
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.nric || "-"}
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 16,
                borderRadius: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_amount"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Sex
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.sex || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_interest_rate"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Race
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.race || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Date of Birth
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.date_of_birth || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Status
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.status
                    ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    : "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Residential Status
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.residential_status || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Nationality
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.nationality || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Country of Birth
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.country_of_birth || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Bank Name
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.bank_name || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Bank Account No.
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.bank_account_no || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Credit Score
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.credit_score || "-"}
                </p>
              </div>
            </div>

            <hr />
          </Modal>
        ))}
    </div>
  );
};

export default TableUserBorrower;