import { Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { format } from "date-fns";




const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};



const TableRepaymentSchedule = ({ data, onRowClick }) => {

  const processedData = data.map((item, index) => ({
    key: index + 1,
    ...item,
  }));

  const columns = [
   
    {
      title: '',
      dataIndex: 'key',
      rowScope: 'row',
      align: 'center',  // Add this line to align the content to the center
      width:'16.67%'
    },
    {
      title: <span style={{fontFamily:"Poppins, sans-serif", fontSize: "13px", fontWeight: "600"}}>Monthly Installment</span>,
      dataIndex: 'monthlyInstallment',
      key: 'monthlyInstallment',
       width:'16.67%',
      render: (text) => (
        <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "14px", fontWeight: "800"}}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}</span>
      ),
    },
    // {
    //   title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "13px", fontWeight: "600" }}>Administration Fee ($)</span>,
    //   dataIndex: 'adminFee',
    //   key: 'adminFee',
    //      width:'16.67%',
    //   render: (text) => (
    //     <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "14px", fontWeight: "800" }}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}</span>
    //   ),
      
    // },
    {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "13px", fontWeight: "600" }}>Principal ($)</span>,
        dataIndex: 'principal',
        key: 'principal',
         width:'16.67%', 
        render: (text) => (
          <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "14px", fontWeight: "800" }}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}</span>
        ),
        
      },
      {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "13px", fontWeight: "600" }}>Interest ($)</span>,
        dataIndex: 'interest',
        key: 'interest',
         width:'16.67%', 
        render: (text) => (
          <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "14px", fontWeight: "800" }}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}</span>
        ),
        
      },
      {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "13px", fontWeight: "600" }}>Final Balance</span>,
        dataIndex: 'finalBalance',
        key: 'finalBalance',
         width:'16.67%', 
        render: (text) => (
          <span style={{ fontFamily:"Manrope, sans-serif", fontSize: "14px", fontWeight: "800" }}>{`${text ? text.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}</span>
        ),
        
      },
      
    
  ];

  return <Table columns={columns} dataSource={processedData} pagination={false}  style={{ borderRadius: "10px 10px 10px 10px", }} bordered/>;
};

export default TableRepaymentSchedule;