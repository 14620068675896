import { Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const TableRepaymentLender = ({ dataRepaymentSchedule, onRowClick }) => {
  const processedData = dataRepaymentSchedule.map((item, index) => ({
    key: index + 1,
    ...item,
  }));

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const columns = [
    {
      title: "",
      dataIndex: "key",
      rowScope: "row",
      align: "center", // Add this line to align the content to the center
      width: "10%",
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Monthly Installment
        </span>
      ),
      dataIndex: "repay_amount",
      key: "repay_amount",
      width: "20%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${
          text
            ? text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "-"
        }`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Loan Balance
        </span>
      ),
      dataIndex: "loan_balance",
      key: "loan_balance",
      width: "30%",
      render: (text, record) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >
          {record.status === "pending"
            ? "-"
            : `${
                text
                  ? text.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : "-"
              }`}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Late Day
        </span>
      ),
      dataIndex: "late_day",
      key: "late_day",
      width: "25%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >
          {text === null ? "-" : `${text + " Day" || "0"}`}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Repayment Date
        </span>
      ),
      dataIndex: "repay_date",
      key: "repay_date",
      width: "25%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${text ? formatDate(text) : "-"}`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Repayment Due Date
        </span>
      ),
      dataIndex: "repay_due_date",
      key: "repay_due_date",
      width: "30%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${text ? formatDate(text) : "-"}`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: "30%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${text ? text.charAt(0).toUpperCase() + text.slice(1) : "-"}`}</span>
      ),
    },
  ];
  const columnsMobile = [
    {
      title: "",
      dataIndex: "key",
      rowScope: "row",
      align: "center", // Add this line to align the content to the center
      width: "10%",
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Monthly Installment
        </span>
      ),
      dataIndex: "repay_amount",
      key: "repay_amount",
      width: "20%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${
          text
            ? text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "-"
        }`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Repayment Due Date
        </span>
      ),
      dataIndex: "repay_due_date",
      key: "repay_due_date",
      width: "30%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${text ? formatDate(text) : "-"}`}</span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "15px",
            color: "grey",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: "30%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "12px",
            opacity: ".6",
          }}
        >{`${text ? text.charAt(0).toUpperCase() + text.slice(1) : "-"}`}</span>
      ),
    },
  ];

  return (
    <Table
      columns={isMobile ? columnsMobile : columns}
      dataSource={processedData}
      pagination={false}
      style={{ borderRadius: "10px 10px 10px 10px" }}
      bordered
    />
  );
};

export default TableRepaymentLender;
