import { Form, Input, Select, Button, Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
const { Option } = Select;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const Property = () => {
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [outstandingHDBLoanBalance, setOutstandingHDBLoanBalance] =
    useState("");
  const [typeOfHDBDwelling, setTypeOfHDBDwelling] = useState("");
  const [monthlyLoanInstallment, setMonthlyLoanInstallment] = useState("");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    vertical: true,
  };

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/singpass/getSingpassData",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const displaySingpassData = await response.json();
        const personDetail = displaySingpassData.singpass_data.person;
        const entityDetail = displaySingpassData.singpass_data.entity;
        setRegisteredAddress(
          (personDetail?.regadd?.unit?.value !== undefined
            ? `Unit ${personDetail?.regadd?.unit?.value}, `
            : "") +
            (personDetail?.regadd?.floor?.value !== undefined
              ? `Floor ${personDetail?.regadd?.floor?.value}, `
              : "") +
            (personDetail?.regadd?.block?.value !== undefined
              ? `Block ${personDetail?.regadd?.block?.value}, `
              : "") +
            (personDetail?.regadd?.street?.value !== undefined
              ? `${personDetail?.regadd?.street?.value}, `
              : "") +
            (personDetail?.regadd?.postal?.value !== undefined
              ? `${personDetail?.regadd?.postal?.value}, `
              : "") +
            (personDetail?.regadd?.country?.desc !== undefined
              ? `${personDetail?.regadd?.country?.desc}.`
              : "")
        );
        setOutstandingHDBLoanBalance(
          personDetail?.hdbownership?.[0]?.outstandingloanbalance?.value
        );
        setTypeOfHDBDwelling(personDetail?.hdbownership?.[0]?.hdbtype?.desc);
        console.log(personDetail?.hdbownership?.[0]?.hdbtype?.desc);
        setMonthlyLoanInstallment(
          personDetail?.hdbownership?.[0]?.monthlyloaninstalment?.value
        );

        console.log(
          "personDetail value:",
          personDetail.hdbownership.address.value
        );
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Form
      style={{ minHeight: "73vh" }}
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div style={{ marginTop: "10px" }}>
        <div style={{ fontWeight: 400, fontSize: "18px" }}>
          CPF Housing Withdrawal
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <div style={{ width: isMobile ? "100%" : "33%" }}>
            <Form.Item label="Address">
              <Input
                style={{ width: "90%" }}
                value={""}
                onChange={(value) => console.log(value)}
                disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
              />
            </Form.Item>
          </div>
          <div style={{ width: isMobile ? "100%" : "33%" }}>
            <Form.Item label="Principal Withdrawal Amount">
              <Input
                style={{ width: "90%" }}
                value={""}
                onChange={(value) => console.log(value)}
                disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
              />
            </Form.Item>
          </div>
          <div style={{ width: isMobile ? "100%" : "33%" }}>
            <Form.Item label="Monthly Installment Amount">
              <Input
                style={{ width: "90%" }}
                value={""}
                onChange={(value) => console.log(value)}
                disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
              />
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ width: isMobile ? "100%" : "33%" }}>
            <Form.Item label="Accrued Interest Amount">
              <Input
                style={{ width: "90%" }}
                value={""}
                onChange={(value) => console.log(value)}
                disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
              />
            </Form.Item>
          </div>
          <div style={{ width: isMobile ? "100%" : "33%" }}>
            <Form.Item label="Total Amount of CPF Allowed for Property">
              <Input
                style={{ width: "90%" }}
                value={""}
                onChange={(value) => console.log(value)}
                disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <Row>
        <hr style={{ color: "grey", width: "100%" }} />
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Registered Address">
            <Input
              style={{ width: isMobile ? "90%" : "96.5%" }}
              value={registeredAddress}
              onChange={(value) => setRegisteredAddress(value)}
              disabled={!!registeredAddress || !registeredAddress}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="HDB Type">
            <Input
              style={{ width: "90%" }}
              value={typeOfHDBDwelling}
              onChange={(value) => setTypeOfHDBDwelling(value)}
              disabled={!!typeOfHDBDwelling || !typeOfHDBDwelling}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Outstanding HDB Loan Balance">
            <Input
              style={{ width: "90%" }}
              value={parseFloat(outstandingHDBLoanBalance).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "SGD",
                }
              )}
              onChange={(value) => setOutstandingHDBLoanBalance(value)}
              disabled={
                !!outstandingHDBLoanBalance || !outstandingHDBLoanBalance
              }
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <Form.Item label="Monthly Loan Installment">
            <Input
              style={{ width: "90%" }}
              value={parseFloat(monthlyLoanInstallment).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "SGD",
                }
              )}
              onChange={(value) => setMonthlyLoanInstallment(value)}
              disabled={!!monthlyLoanInstallment || !monthlyLoanInstallment}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            color: "rgb(14, 90, 165)",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Edit
        </div>
        <div
          style={{
            padding: "6px 40px",
            border: "2px solid rgb(14, 90, 165)",
            background: "rgb(14, 90, 165)",
            color: "white",
            fontWeight: 700,
            borderRadius: "20px",
          }}
        >
          Save
        </div>
      </div> */}
    </Form>
  );
};

export default Property;
