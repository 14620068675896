import { Space, Table, Tag, Radio, Button } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableAdminLoanOffer = ({
  onRowClick,
  onSelectionChange,
  statusUpdate,
}) => {
  const [data, setData] = useState([]);
  const [loanIdFilters, setLoanIdFilters] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");

  useEffect(() => {
    console.log("status update changed");
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/loanOffer/viewAllOfferedLoan",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Loan offers"].flat();
        const adjustingFlatData = [...flatData];
        for (const loans of adjustingFlatData) {
          if (
            // loans.loan_details &&
            loans.status === "accepted"
            // &&
            // loans.loan_details.receipt
          ) {
            const loanIndex = flatData.findIndex(
              (lo) => lo.loan_id === loans.loan_id
            );
            const [pushingLoan] = adjustingFlatData.splice(loanIndex, 1);
            adjustingFlatData.unshift(pushingLoan);
          }
        }
        for (const loans of adjustingFlatData) {
          if (
            loans.loan_details &&
            loans.status === "accepted" &&
            loans.loan_details.receipt
          ) {
            const loanIndex = flatData.findIndex(
              (lo) => lo.loan_id === loans.loan_id
            );
            const [pushingLoan] = adjustingFlatData.splice(loanIndex, 1);
            adjustingFlatData.unshift(pushingLoan);
          }
        }
        setData(adjustingFlatData);

        const uniqueLoanIds = new Set();
        flatData.forEach((loan) => {
          uniqueLoanIds.add(loan.loan_id);
        });

        const filters = Array.from(uniqueLoanIds).map((loanId) => ({
          text: loanId,
          value: loanId,
        }));

        setLoanIdFilters(filters);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [statusUpdate]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/loanOffer/viewAllOfferedLoan",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Loan offers"].flat();
        const adjustingFlatData = [...flatData];
        for (const loans of adjustingFlatData) {
          if (
            // loans.loan_details &&
            loans.status === "accepted"
            // &&
            // loans.loan_details.receipt
          ) {
            const loanIndex = flatData.findIndex(
              (lo) => lo.loan_id === loans.loan_id
            );
            const [pushingLoan] = adjustingFlatData.splice(loanIndex, 1);
            adjustingFlatData.unshift(pushingLoan);
          }
        }
        for (const loans of adjustingFlatData) {
          if (
            loans.loan_details &&
            loans.status === "accepted" &&
            loans.loan_details.receipt
          ) {
            const loanIndex = flatData.findIndex(
              (lo) => lo.loan_id === loans.loan_id
            );
            const [pushingLoan] = adjustingFlatData.splice(loanIndex, 1);
            adjustingFlatData.unshift(pushingLoan);
          }
        } 
        setData(adjustingFlatData);

        const uniqueLoanIds = new Set();
        flatData.forEach((loan) => {
          uniqueLoanIds.add(loan.loan_id);
        });

        const filters = Array.from(uniqueLoanIds).map((loanId) => ({
          text: loanId,
          value: loanId,
        }));

        setLoanIdFilters(filters);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Loan ID
        </span>
      ),
      dataIndex: "loan_id",
      key: "loan_id",
      filters: loanIdFilters,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.loan_id.startsWith(value),
      width: "24%",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Amount
        </span>
      ),
      dataIndex: "offer_amount",
      key: "offer_amount",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Period
        </span>
      ),
      dataIndex: "offer_period",
      key: "offer_period",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text + " Months"}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Interest Rate
        </span>
      ),
      dataIndex: "offer_interest_rate",
      key: "offer_interest_rate",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text * 100}%` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Total Interest
        </span>
      ),
      dataIndex: "total_interest",
      key: "total_interest",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Total Admin Fee
        </span>
      ),
      dataIndex: "total_admin_fee",
      key: "total_admin_fee",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Monthly Installment
        </span>
      ),
      dataIndex: "offer_monthly_installment",
      key: "offer_monthly_installment",
      align: "center",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text
            ? `${text.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      align: "center",
      fixed: "right",
      render: (text, index) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "12px",
            fontWeight: "600",
            color: text === "accepted" ? "red" : "rgba(0,0,0,0.5)",
          }}
        >
          {index.loan_details
            ? text === "accepted" && index.loan_details.receipt
              ? "Pending Receipt Validation"
              : !index.loan_details.lender_sign
              ? "Pending Lender Send Agreement"
              : !index.loan_details.borrower_uploaded_agreement
              ? "Pending Borrower Sign Agreement"
              : !index.loan_details.borrower_sign
              ? "Pending Lender Approve Agreement"
              : !index.loan_details.receipt
              ? "Pending Loan Disbursement"
              : text === "pending_document_request"
              ? "Pending Document Request"
              : text
              ? text.charAt(0).toUpperCase() + text.slice(1)
              : "-"
            : text.charAt(0).toUpperCase() + text.slice(1)}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="offer_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox") {
              onRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
      />
    </div>
  );
};

export default TableAdminLoanOffer;
