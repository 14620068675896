import React, { useState, useRef, useEffect } from "react";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { cloneDeep, isEqual } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import moment from "moment-timezone";
import InboxIcon from "@mui/icons-material/Inbox";
import { Modal } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import { message } from "antd";

const Messaging = () => {
  const [myId, setMyId] = useState("");
  const scrollContainerRef = useRef();

  const [screenWidth, setScreenWidth] = useState("1920");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const archiveChat = async (chatId) => {
    try {
      console.log(chatId);
      const response = await fetch(
        "https://www.smart-lend.com/api/message/archiveMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ chatId: chatId }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [messages, setMessages] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [parameters, setParameters] = useState({});
  const [messageHover, setMessageHover] = useState(null);
  const [showArchive, setShowArchive] = useState(false);

  const ReturnUserId = (message) => {
    if (message.user_id1 === myId) {
      return message.user_id2;
    } else {
      return message.user_id1;
    }
  };

  function convertDateString(inputString) {
    // Parse the input string to obtain a Date object
    const dateObject = new Date(inputString);

    // Format the date
    const optionsDate = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("en-US", optionsDate);

    // Format the time
    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = dateObject.toLocaleTimeString("en-US", optionsTime);

    return { date: formattedDate, time: formattedTime };
  }

  const inputRef = useRef(null);
  const sampleSupportMessageForB = [
    { id: 0, mesa: "Hi, I need help." },
    { id: 1, mesa: "My lender has yet to reply me." },
    { id: 2, mesa: "How do I apply for a loan?" },
    { id: 3, mesa: "How do i check my loan status?" },
  ];
  const sampleSupportMessageForL = [
    { id: 0, mesa: "Hi, I need help." },
    { id: 1, mesa: "How do I make an offer?" },
    {
      id: 2,
      mesa: "I want to make an offer, but I don't know the borrower's credit rating?",
    },
    { id: 3, mesa: "How do I know if my borrower made his payments?" },
  ];
  const sampleSupportMessageForA = [
    { id: 0, mesa: "Sure, how may I help you today?" },
    { id: 1, mesa: "Good day, sure let me help you check the status of that." },
    { id: 2, mesa: "Is there anything else I can help you with today?" },
    { id: 3, mesa: "Can i close this matter now?" },
  ];

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (activeConversation) {
      setLoad(true);
    }
  }, [activeConversation]);

  useEffect(() => {
    // Access the current property of the ref to get the DOM element
    const scrollContainer = scrollContainerRef.current;

    if (activeConversation) {
      if (
        scrollContainerRef &&
        scrollContainer &&
        scrollContainer.scrollHeight
      ) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
    // Set scrollTop to the maximum scroll height
  }, [messages, activeConversation]); // Run the effect only once on mount

  const getMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      let messagessss = [];
      if (response.status === 200) {
        setMyId(json.user_id);
        // console.log(json.user_id[0])
        if (json.data.length > 0) {
          messagessss = [...json.data];
        }

        const queryParams = new URLSearchParams(location.search);
        const params = {};
        for (const [key, value] of queryParams.entries()) {
          params[key] = value;
        }
        if (params.principelname) {
          setParameters(params);
          // console.log(params);
          const newMessage = {
            user_id1: params.userid,
            message: [],
            receiverName: params.principelname,
          };
          messagessss.push(newMessage);
        }
        // Remove parameters from the URL
        const cleanUrl = window.location.pathname;
        history.replace(cleanUrl);

        const adjustingMessageByAdmin = [...messagessss];
        // console.log(adjustingMessageByAdmin);
        let broadcastMessage = [];

        for (const mess of adjustingMessageByAdmin) {
          // console.log(mess.receiverName, mess.id);
          if (
            mess.user_id1 === "AL" ||
            mess.user_id1 === "AB" ||
            mess.user_id1 === "ALL" ||
            mess.user_id2 === "AL" ||
            mess.user_id2 === "AB" ||
            mess.user_id2 === "ALL"
          ) {
            if (mess.message !== null) {
              broadcastMessage = [...broadcastMessage, ...mess.message];
            }
          }
        }

        const broadcastToPush = {
          user_id1: "Admin",
          user_id2: "Admin",
          receiverName: "SmartLend",
          message: broadcastMessage.sort(
            (a, b) => new Date(a.id) - new Date(b.id)
          ),
        };
        // console.log(broadcastToPush);

        let adjustedMessages = [
          broadcastToPush,
          ...adjustingMessageByAdmin.filter(
            (mes) =>
              mes.user_id1 !== "AL" &&
              mes.user_id1 !== "AB" &&
              mes.user_id1 !== "ALL" &&
              mes.user_id2 !== "AL" &&
              mes.user_id2 !== "AB" &&
              mes.user_id2 !== "ALL"
          ),
        ];

        const sampleMessage = {
          id: `${new Date()}`,
          from: "A00001",
          text: "Hey there, what can i help you with today?",
        };

        adjustedMessages = adjustedMessages.map((mes) => {
          if (mes.receiverName === "Admin") {
            return {
              ...mes,
              receiverName: "Support",
              message: [sampleMessage, ...mes.message],
            };
          }

          return { ...mes, message: [...mes.message] };
        });

        // console.log(adjustedMessages);

        setMessages(adjustedMessages);

        if (params.principelname) {
          // console.log(messagessss.length - 1);
          if (params.requestamount) {
            setMessageInput(
              `Hey ${
                params.principelname
              }! I would like to discuss your loan request of ${parseFloat(
                params.requestamount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}.`
            );
          } else if (params.offeramount) {
            setMessageInput(
              `Hey ${
                params.principelname
              }! I would like to discuss your loan offer that you offered of ${parseFloat(
                params.offeramount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}.`
            );
          }
          // console.log(messages.length);
          setActiveConversation(messagessss.length - 2);
          inputRef.current.focus();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createUpdateMessage = async (data) => {
    try {
      // console.log(data);
      const response = await fetch(
        "https://www.smart-lend.com/api/message/createMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();

      const supportMessage = {
        id: `${new Date()}`,
        from: "A00001",
        text: "Hey there, what can i help you with today?",
      };

      if (response.status === 200) {
        console.log(json);

        const depictedMessage = [
          supportMessage,
          ...JSON.parse(json.data.message),
        ];
        // console.log(depictedMessage);
        const dataToPush = {
          receiverName:
            json.receiverName === "Admin" ? "Support" : json.receiverName,
          id: json.data.id,
          message:
            json.receiverName === "Admin"
              ? depictedMessage
              : JSON.parse(json.data.message),
          user_id1: json.data.user_id1,
          user_id2: json.data.user_id2,
          created_at: json.data.created_at,
          updated_at: json.data.updated_at,
          archive: false,
        };

        // console.log(dataToPush);

        const messageIndex = messages.findIndex(
          (mes) => mes.id === dataToPush.id
        );

        if (messageIndex > -1) {
          const updatingMessage = [...messages];
          updatingMessage[messageIndex] = dataToPush;
          console.log(updatingMessage);
          setMessages(updatingMessage);
          setParameters({});
        } else {
          const updatingMessage = [...messages];
          updatingMessage[activeConversation] = dataToPush;
          console.log(updatingMessage);
          setMessages(updatingMessage);
          setParameters({});
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  const checkReadId = (data) => {
    // Extract data from the object
    const { read_id1, read_id2, user_id1, user_id2, receiver } = data;

    if (user_id1 === receiver) {
      return read_id1;
    } else {
      return read_id2;
    }
  };

  const retrieveMessage = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getMessage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        const data = [...json.data];
        if (json.data.length > 0) {
          if (!isEqual(data, messages)) {
            // console.log("updated new data");

            const adjustingMessageByAdmin = [...data];
            // console.log(adjustingMessageByAdmin);
            let broadcastMessage = [];
            for (const mess of adjustingMessageByAdmin) {
              // console.log(mess.receiverName, mess.id);
              if (
                mess.user_id1 === "AL" ||
                mess.user_id1 === "AB" ||
                mess.user_id1 === "ALL" ||
                mess.user_id2 === "AL" ||
                mess.user_id2 === "AB" ||
                mess.user_id2 === "ALL"
              ) {
                if (mess.message !== null) {
                  broadcastMessage = [...broadcastMessage, ...mess.message];
                }
              }
            }
            const broadcastToPush = {
              user_id1: "Admin",
              user_id2: "Admin",
              receiverName: "SmartLend",
              message: broadcastMessage.sort(
                (a, b) => new Date(a.id) - new Date(b.id)
              ),
            };
            // console.log(broadcastToPush);

            let adjustedMessages = [
              broadcastToPush,
              ...data.filter(
                (mes) =>
                  mes.user_id1 !== "AL" &&
                  mes.user_id1 !== "AB" &&
                  mes.user_id1 !== "ALL" &&
                  mes.user_id2 !== "AL" &&
                  mes.user_id2 !== "AB" &&
                  mes.user_id2 !== "ALL"
              ),
            ];

            const sampleMessage = {
              id: `${new Date()}`,
              from: "A00001",
              text: "Hey there, what can i help you with today?",
            };

            adjustedMessages = adjustedMessages.map((mes) => {
              if (mes.receiverName === "Admin") {
                return {
                  ...mes,
                  receiverName: "Support",
                  message: [sampleMessage, ...mes.message],
                };
              }

              return { ...mes, message: [...mes.message] };
            });

            // console.log(adjustedMessages);

            setMessages(adjustedMessages);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!parameters.principelname) {
      const intervalId = setInterval(() => {
        retrieveMessage();
      }, 4000);

      return () => clearInterval(intervalId);
    }
  }, [parameters]);

  const checkParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    if (params.principelname) {
      setParameters(params);
      // console.log(params);
      const newMessage = {
        user_id1: params.userid,
        message: [],
        receiverName: params.principelname,
      };
      setMessages([...messages, newMessage]);
      // console.log([...messages, newMessage]);
    }
    // Remove parameters from the URL
    const cleanUrl = window.location.pathname;
    history.replace(cleanUrl);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [proceedScheduling, setProceedScheduling] = useState(false);
  const [schedulingSetting, setSchedulingSetting] = useState(null);
  const [adjustingOfferProceed, setAdjustingOfferProceed] = useState(false);
  const [loanOfferData, setLoanOfferData] = useState([]);
  const [adjustingIndex, setAdjustingIndex] = useState(null);

  const adjustLoanOffer = async () => {
    try {
      let dataToPush = {
        offer_principal:
          loanOfferData[adjustingIndex].offer_amount *
            (loanOfferData[adjustingIndex].offer_interest_rate / 100).toFixed(
              2
            ) *
            loanOfferData[adjustingIndex].offer_period +
          loanOfferData[adjustingIndex].offer_amount,
        offer_amount: loanOfferData[adjustingIndex].offer_amount,
        offer_period: loanOfferData[adjustingIndex].offer_period,
        offer_interest_rate: parseFloat(
          (loanOfferData[adjustingIndex].offer_interest_rate / 100).toFixed(2)
        ),
        offer_revenue:
          loanOfferData[adjustingIndex].offer_amount *
          (loanOfferData[adjustingIndex].offer_interest_rate / 100).toFixed(2) *
          loanOfferData[adjustingIndex].offer_period,
        offer_monthly_installment: parseFloat(
          (
            (loanOfferData[adjustingIndex].offer_amount *
              (loanOfferData[adjustingIndex].offer_interest_rate / 100).toFixed(
                2
              ) *
              loanOfferData[adjustingIndex].offer_period +
              loanOfferData[adjustingIndex].offer_amount) /
            loanOfferData[adjustingIndex].offer_period
          ).toFixed(2)
        ),
        processing_fee: parseFloat(
          (loanOfferData[adjustingIndex].processing_fee / 100).toFixed(2)
        ),
        loan_id: loanOfferData[adjustingIndex].loan_id,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/message/adjustLoanOffers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(dataToPush),
        }
      );
      const json = await response.json();

      message.success("Loan offer has been adjusted.");
      setAdjustingIndex(null);
      console.log(dataToPush);
    } catch (error) {
      console.error(error);
    }
  };

  const getLoanOffer = async () => {
    try {
      let borrowerId =
        messages[activeConversation].user_id1 === myId
          ? messages[activeConversation].user_id2
          : messages[activeConversation].user_id1;
      const response = await fetch(
        "https://www.smart-lend.com/api/message/getLoanOffer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ bid: borrowerId }),
        }
      );
      const json = await response.json();

      setLoanOfferData(
        json.data
          .filter((object) => object.status === "pending")
          .map((object) => ({
            ...object,
            offer_interest_rate: object.offer_interest_rate * 100,
            processing_fee: object.processing_fee * 100,
          }))
      );
      setAdjustingOfferProceed(true);
    } catch (error) {
      console.error(error);
    }
  };
  const momentDate = (date, time) => {
    return moment(date + " " + time);
  };

  const formattedMomentDate = (date, time) => {
    return momentDate(date, time).format("YYYY-MM-DDTHHmm"); // Google Calendar format
  };
  const googleCalendarLink = (start, end, receiverName) => {
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=Meeting+with+${receiverName.replace(
      / /g,
      "+"
    )}&dates=${start}/${end}`;
  };

  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  function formatDateTime(dateStr, timeStr, userTimeZone) {
    try {
      const dateObj = new Date(dateStr);
      if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date format. Please use YYYY-MM-DD.");
      }

      const formattedDate = dateObj
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");

      if (timeStr) {
        const userDateTime = moment.tz(`${dateStr}T${timeStr}`, userTimeZone);
        if (!userDateTime.isValid()) {
          throw new Error("Invalid time format. Please use HH:MM.");
        }

        // Add one hour in the user's time zone
        userDateTime.add(0, "hours");

        const formattedTime = userDateTime.format("THHmmss"); // Includes "T" prefix
        return formattedDate + formattedTime;
      } else {
        return formattedDate;
      }
    } catch (error) {
      console.error("Error formatting date and time:", error);
      return ""; // Return empty string on error
    }
  }

  function formatDateTimeAddHourInUserTimeZone(dateStr, timeStr, userTimeZone) {
    try {
      const dateObj = new Date(dateStr);
      if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date format. Please use YYYY-MM-DD.");
      }

      const formattedDate = dateObj
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");

      if (timeStr) {
        const userDateTime = moment.tz(`${dateStr}T${timeStr}`, userTimeZone);
        if (!userDateTime.isValid()) {
          throw new Error("Invalid time format. Please use HH:MM.");
        }

        // Add one hour in the user's time zone
        userDateTime.add(1, "hours");

        const formattedTime = userDateTime.format("THHmmss"); // Includes "T" prefix
        return formattedDate + formattedTime;
      } else {
        return formattedDate;
      }
    } catch (error) {
      console.error("Error formatting date and time:", error);
      return ""; // Return empty string on error
    }
  }

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        display: "flex",
        gap: "5px",
        alignItems: "flex-start",
        justifyContent: "space-around",
        position: "relative",
      }}
    >
      {screenWidth > 1200 && myId[0] !== "A" ? (
        <div
          className="mui_button"
          style={{
            position: "absolute",
            zIndex: 10,
            borderRadius: "15px 15px 0px 15px",
            background: "white",
            bottom: "24px",
            right: screenWidth > 1200 ? "74.5%" : "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            color: "rgb(14, 90, 165)",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 800,
            gap: 10,
          }}
          onClick={() => {
            // console.log(messages);
            const newMessage = {
              user_id1: "A00001",
              message: [
                {
                  from: "A00001",
                  id: `${new Date()}`,
                  text: "Hey there, what can i help you with today?",
                },
              ],
              receiverName: "Support",
            };
            setLoad(false);
            setParameters({ userid: "A00001", principelname: "Admin" });
            setMessages([...messages, newMessage]);
            setActiveConversation(messages.length);
          }}
        >
          <SupportAgentIcon
            style={{ color: "rgb(14, 90, 165)", fontSize: 30 }}
          />{" "}
          Support
        </div>
      ) : screenWidth < 1200 &&
        activeConversation === null &&
        myId[0] !== "A" ? (
        <div
          className="mui_button"
          style={{
            position: "absolute",
            zIndex: 10,
            borderRadius: "15px 15px 0px 15px",
            background: "white",
            bottom: "24px",
            right: screenWidth > 1200 ? "74.5%" : "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            color: "rgb(14, 90, 165)",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 800,
            gap: 10,
          }}
          onClick={() => {
            // console.log(messages);
            const newMessage = {
              user_id1: "A00001",
              message: [
                {
                  from: "A00001",
                  id: `${new Date()}`,
                  text: "Hey there, what can i help you with today?",
                },
              ],
              receiverName: "Support",
            };
            setLoad(false);
            setParameters({ userid: "A00001", principelname: "Admin" });
            setMessages([...messages, newMessage]);
            setActiveConversation(messages.length);
          }}
        >
          <SupportAgentIcon
            style={{ color: "rgb(14, 90, 165)", fontSize: 30 }}
          />
          Support
        </div>
      ) : null}
      {screenWidth > 1200 ? (
        <div
          style={{
            width: screenWidth > 1200 ? "24%" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
                marginBottom: "15px",
              }}
            >
              Your Messages
            </div>
            <div
              className="mui_button"
              onClick={() => {
                setShowArchive(!showArchive);
              }}
              style={{
                background: showArchive ? "rgb(21, 87, 169)" : "white",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "7px",
              }}
            >
              <InboxIcon
                style={{
                  color: showArchive ? "white" : "rgb(21, 87, 169)",
                  fontSize: 30,
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "relative",
              background: "rgba(255,255,255,0.6)",
              color: "rgba(70,70,70)",
              borderRadius: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search conversations"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                padding: "10px",
                width: "92%",
              }}
            />
            <SearchIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translate(0%,-50%)",
              }}
            />
          </div>
          <div
            style={{
              height: "78%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages.length > 0 ? "flex-start" : "center",
            }}
          >
            {messages.length > 0 ? (
              messages
                .filter((mes) =>
                  mes.receiverName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((mes, index) => (
                  <div
                    key={index}
                    className="mui_button"
                    style={{
                      display:
                        (showArchive === false && mes.archive === 1) ||
                        (showArchive === false && mes.archive === true)
                          ? "none"
                          : "flex",
                      width: "100%",
                      color:
                        activeConversation === index
                          ? "white"
                          : "rgba(80,80,80)",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      onMouseOver={() => {
                        setMessageHover(index);
                      }}
                      onMouseLeave={() => {
                        setMessageHover(null);
                      }}
                      onClick={() => {
                        console.log(mes);
                        if (
                          mes.archive === null ||
                          mes.archive === false ||
                          mes.archive === 0
                        ) {
                          const messageIndex = messages.findIndex(
                            (mess) => mess.id === mes.id
                          );
                          if (messageIndex > -1) {
                            const updatingMessage = [...messages];
                            updatingMessage[messageIndex].archive = true;
                            setMessages(updatingMessage);
                          }
                        } else {
                          const messageIndex = messages.findIndex(
                            (mess) => mess.id === mes.id
                          );
                          if (messageIndex > -1) {
                            const updatingMessage = [...messages];
                            updatingMessage[messageIndex].archive = false;
                            setMessages(updatingMessage);
                          }
                        }
                        archiveChat(mes.id);
                      }}
                      style={{
                        height: "100%",
                        position: "absolute",
                        right: 0,
                        width: "20%",
                        top: 0,
                        // background: "red",
                        zIndex: 2,
                      }}
                    />
                    <div
                      style={{
                        height: "90%",
                        position: "absolute",
                        right: "1%",
                        width: "20%",
                        top: "5%",
                        background:
                          mes.archive === true || mes.archive === 1
                            ? "green"
                            : "red",
                        transition: "0.3s all ease-in-out",
                        zIndex: 0,
                        borderRadius: "0px 10px 10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "inset 3px 3px 10px rgba(0,0,0,0.4)",
                      }}
                    >
                      <InboxIcon
                        style={{
                          color: "white",
                          fontSize: 32,
                          marginLeft: "25%",
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        console.log(messages);
                        setLoad(false);
                        setMessageInput("");
                        setProceedScheduling(false);
                        setSchedulingSetting(null);
                        setActiveConversation(index);
                      }}
                      style={{
                        background:
                          mes.archive === true || mes.archive === 1
                            ? "rgba(230,230,230,1)"
                            : activeConversation === index
                            ? "rgb(21, 87, 169)"
                            : "rgba(255,255,255,1)",
                        borderRadius: "10px",
                        padding: "10px 15px",
                        width:
                          mes.receiverName !== "SmartLend" &&
                          messageHover === index
                            ? "85%"
                            : "100%",
                        height: "100%",
                        position: "relative",
                        cursor: "pointer",
                        transition: "0.3s all ease-in-out",
                        zIndex: 1,
                      }}
                    >
                      {checkReadId(mes) ? (
                        <div
                          className="blink"
                          style={{
                            position: "absolute",
                            width: 7,
                            height: 7,
                            borderRadius: "50%",
                            background:
                              activeConversation === index ? "white" : "green",
                            bottom: 14,
                            right: 14,
                          }}
                        />
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          {mes.receiverName.length > 14
                            ? mes.receiverName.slice(0, 14) + "..."
                            : mes.receiverName}
                        </div>
                        <div
                          style={{
                            color:
                              activeConversation === index
                                ? "white"
                                : "rgba(0,0,0,0.4)",
                            fontSize: "10px",
                          }}
                        >
                          {mes.message
                            ? mes.message.length > 0
                              ? convertDateString(
                                  mes.message[mes.message.length - 1].id
                                ).time
                              : ""
                            : ""}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          color:
                            activeConversation === index
                              ? "white"
                              : "rgba(0,0,0,0.4)",
                        }}
                      >
                        {mes.message
                          ? mes.message.length > 0
                            ? mes.message[mes.message.length - 1].text?.length >
                              40
                              ? mes.message[mes.message.length - 1].text.slice(
                                  0,
                                  40
                                ) + "..."
                              : mes.message[mes.message.length - 1].text
                            : ""
                          : ""}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No messages found
                </div>
              </div>
            )}
          </div>
        </div>
      ) : !messages[activeConversation] ? (
        <div
          style={{
            width: screenWidth > 1200 ? "24%" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Your Messages
          </div>
          <div
            style={{
              position: "relative",
              background: "rgba(255,255,255,0.6)",
              color: "rgba(70,70,70)",
              borderRadius: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search conversations"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                padding: "10px",
                width: "92%",
              }}
            />
            <SearchIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translate(0%,-50%)",
              }}
            />
          </div>
          <div
            style={{
              height: "78%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages.length > 0 ? "flex-start" : "center",
            }}
          >
            {messages.length > 0 ? (
              messages
                .filter((mes) =>
                  mes.receiverName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((mes, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setLoad(false);
                      setActiveConversation(index);
                    }}
                    className="mui_button"
                    style={{
                      background: "rgba(255,255,255,0.8)",
                      borderRadius: "10px",
                      padding: "10px 15px",
                      width: "100%",
                      color: "rgba(80,80,80)",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        {mes.receiverName.length > 14
                          ? mes.receiverName.slice(0, 14) + "..."
                          : mes.receiverName}
                      </div>
                      <div
                        style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}
                      >
                        {mes.message
                          ? mes.message.length > 0
                            ? convertDateString(
                                mes.message[mes.message.length - 1].id
                              ).time
                            : ""
                          : ""}
                      </div>
                    </div>
                    <div style={{ fontSize: 10, color: "rgba(0,0,0,0.4)" }}>
                      {mes.message
                        ? mes.message.length > 0
                          ? mes.message[mes.message.length - 1].text.length > 40
                            ? mes.message[mes.message.length - 1].text.slice(
                                0,
                                40
                              ) + "..."
                            : mes.message[mes.message.length - 1].text
                          : ""
                        : ""}
                    </div>
                  </div>
                ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No messages found
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {screenWidth > 1200 ? (
        <>
          <hr
            style={{
              background: "rgba(255,255,255,0.9)",
              border: "none",
              outline: "none",
              width: "2.5px",
              height: "95%",
            }}
          />
          <div
            style={{
              width: "70%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: messages[activeConversation]
                ? "flex-start"
                : "center",
              position: "relative",
              overflowY: "hidden",
            }}
          >
            {activeConversation &&
            messageInput !== null &&
            messages[activeConversation].receiverName !== "Support" ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  background: "rgba(255,255,255,0.9)",
                  padding: "15px 20px",
                  bottom:
                    (load &&
                      messageInput.toLowerCase().includes("appointment")) ||
                    messageInput.toLowerCase().includes("schedule") ||
                    messageInput.toLowerCase().includes("meeting") ||
                    messageInput.toLowerCase().includes("set up") ||
                    messageInput.toLowerCase().includes("arrange") ||
                    messageInput.toLowerCase().includes("you free") ||
                    messageInput.toLowerCase().includes("you be free")
                      ? 60
                      : -300,
                  left: 0,
                  borderRadius: "10px 10px 10px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 5,
                  transition: "0.4s all ease-in-out",
                }}
              >
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "16px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    marginBottom: "5px",
                  }}
                >
                  {proceedScheduling
                    ? "Schedule Appointment"
                    : "Want to set an appointment?"}
                </div>
                {proceedScheduling ? (
                  <div>
                    <div
                      style={{
                        color: "#142C44CC",
                      }}
                    >
                      Meeting type
                    </div>
                    <div
                      style={{ display: "flex", gap: "10px", marginTop: "5px" }}
                    >
                      <div
                        className="mui_button"
                        style={{
                          background:
                            schedulingSetting?.type === "call"
                              ? "rgb(21, 87, 169)"
                              : "lightgrey",
                          padding: "6px 25px",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          setSchedulingSetting({
                            ...schedulingSetting,
                            type: "call",
                          });
                        }}
                      >
                        Call
                      </div>
                      <div
                        className="mui_button"
                        style={{
                          background:
                            schedulingSetting?.type === "online"
                              ? "rgb(21, 87, 169)"
                              : "lightgrey",
                          padding: "6px 25px",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          setSchedulingSetting({
                            ...schedulingSetting,
                            type: "online",
                          });
                        }}
                      >
                        Online Meeting
                      </div>
                      <div
                        className="mui_button"
                        style={{
                          background:
                            schedulingSetting?.type === "physical"
                              ? "rgb(21, 87, 169)"
                              : "lightgrey",
                          padding: "6px 25px",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          setSchedulingSetting({
                            ...schedulingSetting,
                            type: "physical",
                          });
                        }}
                      >
                        Physical Meeting
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#142C44CC",
                        marginTop: "15px",
                      }}
                    >
                      Time and date
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "5px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "calc(50% - 5px)" }}>
                        <input
                          type="date"
                          value={schedulingSetting?.date}
                          onChange={(e) => {
                            setSchedulingSetting({
                              ...schedulingSetting,
                              date: e.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            padding: "7px 20px",
                            borderRadius: "8px",
                            border: "1px solid lightgrey",
                          }}
                        />
                      </div>
                      <div style={{ width: "calc(50% - 5px)" }}>
                        <input
                          type="time"
                          value={schedulingSetting?.time}
                          onChange={(e) => {
                            setSchedulingSetting({
                              ...schedulingSetting,
                              time: e.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            padding: "7px 20px",
                            borderRadius: "8px",
                            border: "1px solid lightgrey",
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <button
                        className="mui_button"
                        disabled={
                          schedulingSetting?.date &&
                          schedulingSetting?.time &&
                          schedulingSetting?.type
                            ? false
                            : true
                        }
                        style={{
                          background: "rgb(21, 87, 169)",
                          padding: "9px 35px",
                          borderRadius: "8px",
                          border: "none",
                          color: "white",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          console.log(true);

                          if (activeConversation !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: messageInput,
                              meet: "schedule",
                              from: myId,
                              ...schedulingSetting,
                              from_accepted: false,
                              to_accepted: false,
                            };
                            console.log(messageToPush);
                            if (updatingMessage[activeConversation].message) {
                              updatingMessage[activeConversation].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[activeConversation].message = [
                                messageToPush,
                              ];
                            }
                            setMessages(updatingMessage);
                            createUpdateMessage(messages[activeConversation]);
                          }

                          setMessageInput("");
                          setSchedulingSetting(null);
                          setProceedScheduling(false);
                        }}
                      >
                        Schedule
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: 10,
                      width: "100%",
                    }}
                  >
                    <div
                      className="mui_button"
                      style={{
                        background: "rgb(21, 87, 169)",
                        padding: "10px 35px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        setProceedScheduling(true);
                      }}
                    >
                      Proceed
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {activeConversation &&
            messageInput !== null &&
            messages[activeConversation].receiverName !== "Support" &&
            myId &&
            myId[0] === "L" ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  background: "rgba(255,255,255,0.9)",
                  padding: "15px 20px",
                  bottom:
                    (load && messageInput.toLowerCase().includes("offer")) ||
                    messageInput.toLowerCase().includes("deal") ||
                    messageInput.toLowerCase().includes("agree") ||
                    messageInput.toLowerCase().includes("adjust") ||
                    messageInput.toLowerCase().includes("change") ||
                    messageInput.toLowerCase().includes("interest rate") ||
                    messageInput.toLowerCase().includes("processing fee") ||
                    messageInput.toLowerCase().includes("fee")
                      ? 60
                      : -300,
                  left: 0,
                  borderRadius: "10px 10px 10px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 5,
                  transition: "0.4s all ease-in-out",
                }}
              >
                <div style={{ maxWidth: "300px" }}>
                  <div
                    style={{
                      color: "#142C44CC",
                      fontSize: "16px",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 800,
                      marginBottom: "5px",
                    }}
                  >
                    Would you like to adjust your loan offer to this borrower?
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      className="mui_button"
                      style={{
                        background: "rgb(21, 87, 169)",
                        padding: "10px 35px",
                        borderRadius: "8px",
                        color: "white",
                      }}
                      onClick={() => {
                        getLoanOffer();
                      }}
                    >
                      Proceed
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {activeConversation &&
            messages[activeConversation] &&
            messages[activeConversation].receiverName &&
            messages[activeConversation].receiverName === "Support" ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  background: "rgba(255,255,255,0.8)",
                  padding: "15px 20px",
                  bottom:
                    load && messages[activeConversation].message.length < 2
                      ? messageInput.toLowerCase().includes("appointment") ||
                        messageInput.toLowerCase().includes("schedule") ||
                        messageInput.toLowerCase().includes("meeting") ||
                        messageInput.toLowerCase().includes("set up") ||
                        messageInput.toLowerCase().includes("arrange") ||
                        messageInput.toLowerCase().includes("you free") ||
                        messageInput.toLowerCase().includes("you be free")
                        ? -300
                        : 60
                      : -300,
                  right: 0,
                  borderRadius: "10px 10px 0px 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gap: 5,
                  transition: "0.4s all ease-in-out",
                }}
              >
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "16px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    marginBottom: "5px",
                  }}
                >
                  Frequently asked questions
                </div>
                {myId[0] === "B"
                  ? sampleSupportMessageForB.map((sam, index) => (
                      <div
                        className="mui_button"
                        key={index}
                        style={{
                          background: "rgb(21, 87, 169)",
                          color: "white",
                          padding: "8px 14px",
                          borderRadius: "8px",
                          maxWidth: "350px",
                          textAlign: "right",
                        }}
                        onClick={() => {
                          if (activeConversation !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: sam.mesa,
                              from: myId,
                            };
                            if (updatingMessage[activeConversation].message) {
                              updatingMessage[activeConversation].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[activeConversation].message = [
                                messageToPush,
                              ];
                            }
                            setMessages(updatingMessage);
                            createUpdateMessage(messages[activeConversation]);
                          }
                          setMessageInput("");
                        }}
                      >
                        {sam.mesa}
                      </div>
                    ))
                  : myId[0] === "L"
                  ? sampleSupportMessageForL.map((sam, index) => (
                      <div
                        className="mui_button"
                        key={index}
                        style={{
                          background: "rgb(21, 87, 169)",
                          color: "white",
                          padding: "8px 14px",
                          borderRadius: "8px",
                          maxWidth: "350px",
                          textAlign: "right",
                        }}
                        onClick={() => {
                          if (activeConversation !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: sam.mesa,
                              from: myId,
                            };
                            if (updatingMessage[activeConversation].message) {
                              updatingMessage[activeConversation].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[activeConversation].message = [
                                messageToPush,
                              ];
                            }
                            setMessages(updatingMessage);
                            createUpdateMessage(messages[activeConversation]);
                          }
                          setMessageInput("");
                        }}
                      >
                        {sam.mesa}
                      </div>
                    ))
                  : sampleSupportMessageForA.map((sam, index) => (
                      <div
                        className="mui_button"
                        key={index}
                        style={{
                          background: "rgb(21, 87, 169)",
                          color: "white",
                          padding: "8px 14px",
                          borderRadius: "8px",
                          maxWidth: "350px",
                          textAlign: "right",
                        }}
                        onClick={() => {
                          if (activeConversation !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: sam.mesa,
                              from: myId,
                            };
                            if (updatingMessage[activeConversation].message) {
                              updatingMessage[activeConversation].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[activeConversation].message = [
                                messageToPush,
                              ];
                            }
                            setMessages(updatingMessage);
                            createUpdateMessage(messages[activeConversation]);
                          }
                          setMessageInput("");
                        }}
                      >
                        {sam.mesa}
                      </div>
                    ))}
              </div>
            ) : null}
            {messages[activeConversation] ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "rgba(70,70,70)",
                }}
              >
                <div
                  ref={scrollContainerRef}
                  style={{
                    height: "calc(100% - 50px)",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {messages[activeConversation].message
                    ? messages[activeConversation].message.map(
                        (convo, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent:
                                convo.from === myId ? "flex-end" : "flex-start",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 10px 25px 10px",
                                borderRadius: "10px",
                                background:
                                  convo.from === myId
                                    ? "rgba(255,255,255,0.8)"
                                    : "rgb(21, 87, 169)",
                                marginBottom: "10px",
                                color:
                                  convo.from === myId ? "inherit" : "white",
                                minWidth: "30%",
                                maxWidth: "75%",
                                position: "relative",
                              }}
                            >
                              {!convo.meet ? (
                                convo.text
                              ) : convo.from === myId ? (
                                <div>
                                  <div>
                                    {"I would like to setup " +
                                      (convo.type === "call"
                                        ? "a call"
                                        : convo.type === "online"
                                        ? "an online meeting"
                                        : "an in person meeting") +
                                      " at " +
                                      convo.time +
                                      ", " +
                                      convo.date}
                                  </div>
                                  {!convo.to_accepted ? (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        fontSize: 12,
                                      }}
                                    >
                                      Awaiting meeting confirmation from{" "}
                                      {
                                        messages?.[activeConversation]
                                          ?.receiverName
                                      }
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        fontSize: 12,
                                      }}
                                    >
                                      <div>
                                        {messages?.[activeConversation]
                                          ?.receiverName +
                                          " has agreed to your invite. Would you like to add the meeting to your calendar?"}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "8px",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className="mui_button"
                                          style={{
                                            background: "rgb(21, 87, 169)",
                                            color: "white",
                                            padding: "6px 25px",
                                            borderRadius: "8px",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              googleCalendarLink(
                                                formatDateTime(
                                                  convo.date,
                                                  convo.time
                                                ),
                                                formatDateTimeAddHourInUserTimeZone(
                                                  convo.date,
                                                  convo.time
                                                ),
                                                messages[activeConversation]
                                                  .receiverName
                                              )
                                            );
                                          }}
                                        >
                                          Add meeting to calendar
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    {messages?.[activeConversation]
                                      ?.receiverName +
                                      " would like to setup " +
                                      (convo.type === "call"
                                        ? "a call"
                                        : convo.type === "online"
                                        ? "an online meeting"
                                        : "an in person meeting") +
                                      " at " +
                                      convo.time +
                                      ", " +
                                      convo.date}
                                  </div>
                                  {convo.to_accepted ? (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        fontSize: 12,
                                      }}
                                    >
                                      <div>
                                        You have accepted this meeting. Would
                                        you like to add the meeting to your
                                        calendar?
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "8px",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className="mui_button"
                                          style={{
                                            color: "rgb(21, 87, 169)",
                                            background: "white",
                                            padding: "6px 25px",
                                            borderRadius: "8px",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              googleCalendarLink(
                                                formatDateTime(
                                                  convo.date,
                                                  convo.time
                                                ),
                                                formatDateTimeAddHourInUserTimeZone(
                                                  convo.date,
                                                  convo.time
                                                ),
                                                messages[activeConversation]
                                                  .receiverName
                                              )
                                            );
                                          }}
                                        >
                                          Add meeting to calendar
                                        </div>
                                      </div>
                                    </div>
                                  ) : index ===
                                    messages[activeConversation].message
                                      .length -
                                      1 ? (
                                    <div>
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          fontSize: 12,
                                        }}
                                      >
                                        Would you like to accept?
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "7px",
                                          gap: "10px",
                                        }}
                                      >
                                        <div
                                          onClick={() => {
                                            if (activeConversation !== -1) {
                                              const updatingMessage = [
                                                ...messages,
                                              ];
                                              const messageToPush = {
                                                id: `${new Date()}`,
                                                text: "Meeting has been rejected",
                                                from: myId,
                                              };
                                              console.log(messageToPush);
                                              if (
                                                updatingMessage[
                                                  activeConversation
                                                ].message
                                              ) {
                                                updatingMessage[
                                                  activeConversation
                                                ].message.push(messageToPush);
                                              } else {
                                                updatingMessage[
                                                  activeConversation
                                                ].message = [messageToPush];
                                              }
                                              setMessages(updatingMessage);
                                              createUpdateMessage(
                                                messages[activeConversation]
                                              );
                                            }

                                            setMessageInput("");
                                          }}
                                          className="mui_button"
                                          style={{
                                            color: "rgb(21, 87, 169)",
                                            background: "white",
                                            padding: "6px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          No
                                        </div>
                                        <div
                                          onClick={() => {
                                            console.log(index);

                                            if (activeConversation !== -1) {
                                              const updatingMessage = [
                                                ...messages,
                                              ];
                                              const updatingInvite = {
                                                ...messages[activeConversation]
                                                  .message[index],
                                              };
                                              updatingInvite.to_accepted = true;
                                              console.log(updatingInvite);
                                              if (
                                                updatingMessage[
                                                  activeConversation
                                                ].message[index]
                                              ) {
                                                updatingMessage[
                                                  activeConversation
                                                ].message[index] =
                                                  updatingInvite;
                                              }
                                              setMessages(updatingMessage);
                                              createUpdateMessage(
                                                messages[activeConversation]
                                              );
                                            }
                                          }}
                                          className="mui_button"
                                          style={{
                                            color: "rgb(21, 87, 169)",
                                            background: "white",
                                            padding: "6px 25px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          Yes
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        fontSize: 12,
                                      }}
                                    >
                                      You may request or setup another meeting.
                                    </div>
                                  )}
                                </div>
                              )}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "10px",
                                  fontSize: "10px",
                                  color:
                                    convo.from === myId
                                      ? "rgba(0,0,0,0.4)"
                                      : "rgba(255,255,255,0.6)",
                                }}
                              >
                                {convertDateString(convo.id).time}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
                {messages[activeConversation].receiverName !== "SmartLend" ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (messageInput !== "" && messageInput !== null) {
                        if (activeConversation !== -1) {
                          const updatingMessage = [...messages];
                          const messageToPush = {
                            id: `${new Date()}`,
                            text: messageInput,
                            from: myId,
                          };
                          if (updatingMessage[activeConversation].message) {
                            updatingMessage[activeConversation].message.push(
                              messageToPush
                            );
                          } else {
                            updatingMessage[activeConversation].message = [
                              messageToPush,
                            ];
                          }
                          setMessages(updatingMessage);
                          createUpdateMessage(messages[activeConversation]);
                        }
                        setMessageInput("");
                      }
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                      background: "rgba(255,255,255,0.8)",
                      borderRadius: "10px",
                    }}
                  >
                    <input
                      ref={inputRef}
                      placeholder="Type your message here"
                      value={messageInput}
                      onChange={(e) => {
                        setProceedScheduling(false);
                        setSchedulingSetting(null);
                        setMessageInput(e.target.value);
                      }}
                      style={{
                        outline: "none",
                        border: "none",
                        background: "transparent",
                        padding: "12px 8px 12px 15px",
                        borderRadius: "10px",
                        width: "95%",
                        position: "relative",
                        zIndex: 1,
                      }}
                    />
                    <button
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        right: "10px",
                        outline: "none",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <SendIcon />
                    </button>
                  </form>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <UpcomingIcon
                    style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                  />
                </div>
                <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                  No conversation selected
                </div>
              </div>
            )}
          </div>
        </>
      ) : messages[activeConversation] ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: messages[activeConversation]
              ? "flex-start"
              : "center",
          }}
        >
          {messages[activeConversation] ? (
            <>
              <div
                style={{
                  height: "5%",
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                <ArrowBackIosIcon
                  style={{ color: "#142C44CC", cursor: "pointer" }}
                  onClick={() => setActiveConversation(null)}
                />
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    margin: 0,
                  }}
                >
                  {messages[activeConversation].receiverName}
                </div>
              </div>
              <div
                style={{
                  height: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  color: "rgba(70,70,70)",
                }}
              >
                <div
                  ref={scrollContainerRef}
                  style={{ height: "calc(100% - 50px)", overflowY: "auto" }}
                >
                  {messages[activeConversation].message
                    ? messages[activeConversation].message.map(
                        (convo, index) => (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent:
                                convo.from === myId ? "flex-end" : "flex-start",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 10px 25px 10px",
                                borderRadius: "10px",
                                background:
                                  convo.from === myId
                                    ? "rgba(255,255,255,0.8)"
                                    : "rgb(21, 87, 169)",
                                marginBottom: "10px",
                                color:
                                  convo.from === myId ? "inherit" : "white",
                                minWidth: "65%",
                                maxWidth: "95%",
                                position: "relative",
                              }}
                            >
                              {convo.text}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "10px",
                                  fontSize: "10px",
                                  color:
                                    convo.from === myId
                                      ? "rgba(0,0,0,0.4)"
                                      : "rgba(255,255,255,0.6)",
                                }}
                              >
                                {convertDateString(convo.id).time}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
                {messages[activeConversation].receiverName !== "SmartLend" ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (messageInput !== "" && messageInput !== null) {
                        console.log(messages)
                        if (messages[activeConversation].id === undefined) {

                          const conversationIndex = activeConversation
                          console.log(messages[activeConversation].id);
                          if (conversationIndex !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: messageInput,
                              from: myId,
                            };
                            if (updatingMessage[conversationIndex].message) {
                              updatingMessage[conversationIndex].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[conversationIndex].message = [
                                messageToPush,
                              ];
                            }
                            createUpdateMessage(messages[activeConversation]);
                            setMessages(updatingMessage);
                          }
                          setMessageInput("");


                        } else {
                          const conversationIndex = messages.findIndex(
                            (mes) => mes.id === messages[activeConversation].id
                          );
                          console.log(messages[activeConversation].id);
                          if (conversationIndex !== -1) {
                            const updatingMessage = [...messages];
                            const messageToPush = {
                              id: `${new Date()}`,
                              text: messageInput,
                              from: myId,
                            };
                            if (updatingMessage[conversationIndex].message) {
                              updatingMessage[conversationIndex].message.push(
                                messageToPush
                              );
                            } else {
                              updatingMessage[conversationIndex].message = [
                                messageToPush,
                              ];
                            }
                            createUpdateMessage(messages[activeConversation]);
                            setMessages(updatingMessage);
                          }
                          setMessageInput("");
                        }
                      }
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                      background: "rgba(255,255,255,0.8)",
                      borderRadius: "10px",
                    }}
                  >
                    <input
                      ref={inputRef}
                      placeholder="Type your message here"
                      value={messageInput}
                      onChange={(e) => {
                        setProceedScheduling(false);
                        setSchedulingSetting(null);
                        setMessageInput(e.target.value);
                      }}
                      style={{
                        outline: "none",
                        border: "none",
                        background: "transparent",
                        padding: "12px 8px 12px 15px",
                        borderRadius: "10px",
                        width: "95%",
                        position: "relative",
                        zIndex: 1,
                      }}
                    />
                    <button
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        right: "10px",
                        outline: "none",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <SendIcon />
                    </button>
                  </form>
                ) : null}
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <UpcomingIcon
                  style={{ fontSize: "120px", color: "rgba(130,130,130)" }}
                />
              </div>
              <div style={{ color: "rgba(150,150,150)", fontSize: "15px" }}>
                No conversation selected
              </div>
            </div>
          )}
        </div>
      ) : null}
      <Modal
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={adjustingOfferProceed}
        onCancel={() => {
          setAdjustingOfferProceed(false);
          setAdjustingIndex(null);
        }}
        width="60vw"
        footer={null}
      >
        {messages[activeConversation] ? (
          <div style={{}}>
            <div
              style={{
                fontFamily: "Manrope, sans-serif",
                color: "rgba(20,44,68,0.8)",
                fontSize: "20px",
                fontWeight: 800,
              }}
            >
              Loan Offers to {messages[activeConversation].receiverName}
              {/* {messages[activeConversation].user_id1 === myId
              ? messages[activeConversation].user_id2
              : messages[activeConversation].user_id1} */}
            </div>
            <div
              style={{
                fontFamily: "Manrope, sans-serif",
                color: "rgba(0,0,0,0.7)",
                fontSize: "12px",
                fontWeight: 500,
                width: "70%",
                lineHeight: "12px",
                marginBottom: "20px",
              }}
            >
              Listed below are offers that you have made to this individual, if
              you would like to readjust the offer, click on the edit icon
              located on the top left of the loan offer.
            </div>
            <div style={{ display: "flex", width: "100%", gap: "10px" }}>
              {loanOfferData
                ? loanOfferData.map((data, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid lightgrey",
                        position: "relative",
                        minWidth: "25%",
                        maxWidth: "30%",
                      }}
                    >
                      <div
                        className="mui_button"
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid lightgrey",
                          position: "absolute",
                          zIndex: 100,
                          top: 5,
                          right: 5,
                          borderRadius: "6px",
                          display: adjustingIndex !== null ? "none" : "block",
                        }}
                        onClick={() => setAdjustingIndex(index)}
                      >
                        <EditIcon style={{ color: "grey" }} />
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "10px",
                          opacity: ".6",
                        }}
                      >
                        Offer amount
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "grey",
                        }}
                      >
                        {adjustingIndex === index ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            SGD{" "}
                            <input
                              type="number"
                              value={loanOfferData[adjustingIndex].offer_amount}
                              style={{
                                border: "1px solid lightgrey",
                                width: "50%",
                                padding: "2px 15px",
                                borderRadius: "7px",
                              }}
                              onChange={(e) => {
                                const updatingLoanOfferData = [
                                  ...loanOfferData,
                                ];
                                updatingLoanOfferData[
                                  adjustingIndex
                                ].offer_amount = parseFloat(e.target.value);
                                setLoanOfferData(updatingLoanOfferData);
                              }}
                            ></input>{" "}
                          </div>
                        ) : (
                          data.offer_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })
                        )}
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "10px",
                          opacity: ".6",
                          marginTop: "10px",
                        }}
                      >
                        Offer Interest Rate (Month)
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "grey",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {adjustingIndex === index ? (
                          <input
                            type="number"
                            style={{
                              border: "1px solid lightgrey",
                              width: "40%",
                              padding: "2px 15px",
                              borderRadius: "7px",
                            }}
                            value={
                              loanOfferData[adjustingIndex].offer_interest_rate
                            }
                            onChange={(e) => {
                              const updatingLoanOfferData = [...loanOfferData];
                              updatingLoanOfferData[
                                adjustingIndex
                              ].offer_interest_rate = parseFloat(
                                e.target.value
                              );
                              setLoanOfferData(updatingLoanOfferData);
                            }}
                          ></input>
                        ) : (
                          data.offer_interest_rate.toFixed(1)
                        )}
                        {" %"}
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "10px",
                          opacity: ".6",
                          marginTop: "10px",
                        }}
                      >
                        Processing Fee
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "grey",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {adjustingIndex === index ? (
                          <input
                            type="number"
                            style={{
                              border: "1px solid lightgrey",
                              width: "40%",
                              padding: "2px 15px",
                              borderRadius: "7px",
                            }}
                            value={loanOfferData[adjustingIndex].processing_fee}
                            onChange={(e) => {
                              const updatingLoanOfferData = [...loanOfferData];
                              updatingLoanOfferData[
                                adjustingIndex
                              ].processing_fee = parseFloat(e.target.value);
                              setLoanOfferData(updatingLoanOfferData);
                            }}
                          ></input>
                        ) : (
                          data.processing_fee.toFixed(1)
                        )}
                        {" %"}
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "10px",
                          opacity: ".6",
                          marginTop: "10px",
                        }}
                      >
                        Loan Tenure
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "grey",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {adjustingIndex === index ? (
                          <input
                            type="number"
                            value={loanOfferData[adjustingIndex].offer_period}
                            style={{
                              border: "1px solid lightgrey",
                              width: "40%",
                              padding: "2px 15px",
                              borderRadius: "7px",
                            }}
                            onChange={(e) => {
                              const updatingLoanOfferData = [...loanOfferData];
                              updatingLoanOfferData[
                                adjustingIndex
                              ].offer_period = parseFloat(e.target.value);
                              setLoanOfferData(updatingLoanOfferData);
                            }}
                          ></input>
                        ) : (
                          data.offer_period
                        )}{" "}
                        months
                      </div>
                      {adjustingIndex === index ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "15px",
                          }}
                        >
                          <div
                            className="mui_button"
                            style={{
                              background: "rgb(21, 87, 169)",
                              padding: "6px 35px",
                              borderRadius: "8px",
                              color: "white",
                            }}
                            onClick={() => {
                              adjustLoanOffer();
                            }}
                          >
                            Confirm changes
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export default Messaging;
