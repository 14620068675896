import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import Quill from "react-quill";
import ".././assets/css/app.css";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import CancelIcon from "@mui/icons-material/Cancel";

const VerifyBorrower = () => {
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [allBorrowers, setAllBorrowers] = useState([]);
  const getAllBorrowers = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/getAllBorrowers",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setAllBorrowers(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllBorrowers();
  }, []);

  const [verificationFiles, setVerificationFiles] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const verifyBorrower = async (user_id) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/verifyBorrower",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ user_id: user_id }),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        message.success(`User ${user_id} has been verified.`);
        setAllBorrowers(json.updated_data);
        setModalIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const unverifyBorrower = async (user_id) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/unverifyBorrower",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ user_id: user_id }),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        message.success(`User ${user_id} has been Unverified.`);
        setAllBorrowers(json.updated_data);
        setModalIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Modal
        open={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        width={isMobile ? "100%" : "66%"}
        footer={
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                verifyBorrower(activeUserId);
              }}
              style={{
                background: "#142C44CC",
                color: "white",
                padding: "7px 35px",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              Verify Borrower
            </div>
          </div>
        }
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "20px",
            fontFamily: "Manrope,sans-serif",
            fontWeight: 800,
            marginBottom: "15px",
          }}
        >
          User {activeUserId} verification files.
        </div>
        <div>
          {verificationFiles
            ? verificationFiles.map((file, index) => (
                <div
                  key={index}
                  onClick={() => {
                    window.open(file.url);
                  }}
                  style={{
                    color: "#1c5f8a",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {file.name}
                </div>
              ))
            : null}
        </div>
      </Modal>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Verify Borrower
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 0px",
          color: "#142C44CC",
          fontSize: "16px",
          fontFamily: "Manrope,sans-serif",
          fontWeight: 800,
          background:"rgba(256,256,256,0.6)",
          borderRadius: "12px 12px 0px 0px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "75%",
          }}
        >
          <div>
            <div style={{ marginLeft: "15px" }}>User ID</div>
          </div>
          <div style={{ width: "60%", textAlign: "center" }}>Borrower Name</div>
          <div style={{ width: "15%", textAlign: "center" }}>Status</div>
        </div>
        <div style={{ marginRight: "55px" }}>Action</div>
      </div>
      <div style={{ width: "100%", height: "80vh", overflowY: "auto" }}>
        {allBorrowers.map((bor, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                color: "#142C44CC",
                textAlign: "center",
                alignItems: "center",
                height: "60px",
                background: index % 2 ? "rgba(256,256,256,0.4)" : "transparent",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "75%" }}
              >
                <div style={{ fontWeight: 700, marginLeft: "15px" }}>
                  {bor.user_id}
                </div>
                <div style={{ width: "60%" }}>
                  {bor.entity_name
                    ? bor.entity_name
                    : bor.principal_name
                    ? bor.principal_name
                    : "User have yet to sync data."}
                </div>
                <div
                  style={{
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  {!bor.badge_verified ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Unverified
                      <CancelIcon style={{ color: "#8c0808" }} />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Verified
                      <CheckCircleIcon style={{ color: "green" }} />
                    </div>
                  )}
                </div>
              </div>
              {!bor.badge_verified ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  {bor.verification_files ? (
                    <div>
                      <div
                        onClick={() => {
                          setVerificationFiles(
                            JSON.parse(bor.verification_files)
                          );
                          setActiveUserId(bor.user_id);
                          setModalIsOpen(true);
                        }}
                        style={{
                          background: "#088c1a",
                          color: "white",
                          padding: "7px 35px",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                      >
                        Verification Files
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <div
                      onClick={() => {
                        verifyBorrower(bor.user_id);
                      }}
                      style={{
                        background: "#142C44CC",
                        color: "white",
                        padding: "7px 35px",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    >
                      Verify User
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    onClick={() => {
                      unverifyBorrower(bor.user_id);
                    }}
                    style={{
                      background: "#8c0808",
                      color: "white",
                      padding: "7px 35px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    Un-verify User
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerifyBorrower;
