import React, { useEffect, useState } from "react";
import HomeHeader from "./Headesr";
import { useHistory } from "react-router-dom";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import SimCardAlertIcon from "@mui/icons-material/SimCardAlert";

const Roadmap = () => {
  const history = useHistory();
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const getFeedbacks = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/feedback/getFeedback",
        {
          method: "GET",
        }
      );
      let data = [];

      if (response.status === 200) {
        const json = await response.json();
        const jsonData = json.feedback;

        // Sort the feedback by `updated_at` in descending order
        jsonData.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );

        for (const feed of jsonData) {
          if (feed.status_2 !== "" && feed.status_2 !== null) {
            const submissionData = JSON.parse(feed.submission_data);
            const dataToPush = {
              id: feed.id,
              status_2: feed.status_2,
              category: submissionData.category,
              image: submissionData.image,
              message: submissionData.message,
              title: submissionData.title,
              description: submissionData.description,
              instruction: submissionData.instruction,
            };
            data.push(dataToPush);
          }
        }
        console.log(data);
        setFeedbackData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  const filteredFeedbacks = feedbackData.filter(
    (fd) =>
      fd.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      fd.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {/* Header bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
        }}
      >
        <HomeHeader />
      </div>
      {/* First section */}
      <div style={{ minHeight: "70vh" }}>
        <div
          style={{
            padding: "35px 0px",
            textAlign: "center",
            background: "#256199",
            marginLeft: pageLoad ? "0px" : "-300px",
            opacity: pageLoad ? "1" : "0",
            transition: "all 0.8s ease-in-out",
          }}
        >
          <div
            style={{
              fontSize: screenWidth > 1200 ? "45px" : "30px",
              fontWeight: "700",
              color: "white",
            }}
          >
            Platform Roadmap
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{
                fontSize: "13px",
                fontWeight: "400",
                width: screenWidth > 1200 ? "50%" : "80%",
                marginTop: "10px",
                color: "white",
              }}
            >
              Here you'll be able to see feature request, bug reports, and so on
              for the platform to improve, if you would like to contribute to
              helping this platform provide users with a better user experience,
              head over to your dashboard, and you should see a feedback request
              button on the bottom left of the screen where you can make a
              submission and we will work on it.
            </div>
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="🔍 Search by features, request and more"
            style={{
              border: "none",
              outline: "none",
              padding: "8px 20px",
              textAlign: "center",
              marginTop: "20px",
              borderRadius: "25px",
              height: "30px",
              width: screenWidth > 1200 ? "30%" : "60%",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            width: "85%",
            marginLeft: "7.5%",
            padding: "40px 0px",
          }}
        >
          <div
            style={{
              width: "30%",
              background: "rgb(240,240,240)",
              minHeight: "35vh",
              maxHeight: "70vh",
              padding: "8px 12px",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 800,
                fontFamily: "Manrope, sans-serif",
                marginBottom: "10px",
              }}
            >
              Requested
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredFeedbacks.filter((fd) => fd.status_2 === "ready")
                .length > 0 ? (
                filteredFeedbacks
                  .filter((fd) => fd.status_2 === "ready")
                  .map((fd, index) => (
                    <div
                      key={index}
                      style={{
                        background: "white",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "rgb(100,100,100)",
                        }}
                      >
                        {fd.title}
                      </div>
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "rgb(80,80,80)",
                        }}
                      >
                        {fd.description}
                      </div>
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "-20px",
                  }}
                >
                  <div>
                    <SimCardAlertIcon
                      style={{ color: "lightgrey", fontSize: "80px" }}
                    />
                  </div>
                  <div style={{ color: "lightgrey" }}>No data found</div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "30%",
              background: "rgb(240,240,240)",
              minHeight: "35vh",
              maxHeight: "70vh",
              padding: "8px 12px",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 800,
                fontFamily: "Manrope, sans-serif",
                marginBottom: "10px",
              }}
            >
              Ongoing Development
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredFeedbacks.filter((fd) => fd.status_2 === "ongoing")
                .length > 0 ? (
                filteredFeedbacks
                  .filter((fd) => fd.status_2 === "ongoing")
                  .map((fd, index) => (
                    <div
                      key={index}
                      style={{
                        background: "white",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "rgb(100,100,100)",
                        }}
                      >
                        {fd.title}
                      </div>
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "rgb(80,80,80)",
                        }}
                      >
                        {fd.description}
                      </div>
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "-20px",
                  }}
                >
                  <div>
                    <SimCardAlertIcon
                      style={{ color: "lightgrey", fontSize: "80px" }}
                    />
                  </div>
                  <div style={{ color: "lightgrey" }}>No data found</div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "30%",
              background: "rgb(240,240,240)",
              minHeight: "35vh",
              maxHeight: "70vh",
              padding: "8px 12px",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                color: "rgb(37, 97, 153)",
                fontWeight: 800,
                fontFamily: "Manrope, sans-serif",
                marginBottom: "10px",
              }}
            >
              Completed
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredFeedbacks.filter((fd) => fd.status_2 === "complete")
                .length > 0 ? (
                filteredFeedbacks
                  .filter((fd) => fd.status_2 === "complete")
                  .map((fd, index) => (
                    <div
                      key={index}
                      style={{
                        background: "white",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "rgb(100,100,100)",
                        }}
                      >
                        {fd.title}
                      </div>
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "rgb(80,80,80)",
                        }}
                      >
                        {fd.description}
                      </div>
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "-20px",
                  }}
                >
                  <div>
                    <SimCardAlertIcon
                      style={{ color: "lightgrey", fontSize: "80px" }}
                    />
                  </div>
                  <div style={{ color: "lightgrey" }}>No data found</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Footer section */}
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            Smart Towkay Ventures Pte. Ltd., SmartLend Financial Pte. Ltd., are
            part of the Smart Towkay Brand.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <img
              className="menu_button"
              src={tiktokIcon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Enterprise
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
            >
              SmartLend for Business
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              SmartAds
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              Express Loan
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "30%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
