import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import ForgotImg from "../../assets/img/vector/forgot-password.png";

const ForgotPassword = () => {
const [email, setEmail] = useState('');
const [screenWidth, setScreenWidth] = useState("1920");


async function SendLink(event){
  event.preventDefault();

  try {
  
      let item = { email };
      let result = await fetch("https://www.smart-lend.com/api/auth/forgotPassword/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(item)
      })
        .then(response => response.json());
      localStorage.setItem('user-info', JSON.stringify(result));
  } catch (error) {
    console.log(error);
    alert('An error occurred while sending link. Please try again.');
  }
}

useEffect(() => {
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Call handleResize initially to set the initial screen width
  handleResize();

  // Add event listener for resize
  window.addEventListener("resize", handleResize);

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  return (
    <>
  {screenWidth > 1200 ? (
      <React.Fragment>
        <div className="auth-header">
          <div className="auth-header-logo forgot-img">
            <img src={ForgotImg} alt="" className="auth-header-logo-img" />
          </div>
        </div>
        <div className="auth-body">
        <form className="auth-form-validation">
          <h1 className="auth-header-title-forgot-header">Forgot Password?</h1>
          <p className="auth-header-subtitle-forgot-subtitle">
            Enter your email and we'll send you intructions to reset your password
          </p>
        
            <div className="input-field">
              <label htmlFor="email" className="input-label">
                Email address
              </label>
              <input
                type="email"
                className="input-control"
                id="email"
                placeholder="example@gmail.com"
                autoComplete="off"
                onChange={event => setEmail(event.target.value)}
                required
              />
            </div>
            <div className="input-field">
            <button type="submit" className="btn-submit-forgot-password" onClick={SendLink}>
              Send Link Notification
            </button>
            <Link to={"/auth/signin"} className="btn-back-to-login">
              Back to login
            </Link>
            </div>
          </form>
        </div>
      </React.Fragment>
    ) : (
      <div>
      <div
        style={{
          background:
            "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          width: "95%",
          marginLeft: "10px",
        }}
      >
  <div className="auth-header">
          <div className="auth-header-logo forgot-img">
            <img src={ForgotImg} alt="" className="auth-header-logo-img" />
          </div>
        </div>
        <div className="auth-body">
        <form className="auth-form-validation">
          <h1 className="auth-header-title-forgot-header">Forgot Password?</h1>
          <p className="auth-header-subtitle-forgot-subtitle">
            Enter your email and we'll send you <br/> intructions to reset your password
          </p>
        
            <div className="input-field">
              <label htmlFor="email" className="input-label">
                Email address
              </label>
              <input
                type="email"
                className="input-control"
                id="email"
                placeholder="example@gmail.com"
                autoComplete="off"
                onChange={event => setEmail(event.target.value)}
                required
              />
            </div>
            <div className="input-field">
            <button type="submit" className="btn-submit-forgot-password" onClick={SendLink}>
              Send Link Notification
            </button>
            <Link to={"/auth/signin"} className="btn-back-to-login">
              Back to login
            </Link>
            </div>
          </form>
        </div>
        </div>
        </div>
    )}
    </>
  
  );
};

export default ForgotPassword;
