import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Modal from "antd/es/modal/Modal";
import { Collapse, message } from "antd";
import TableRepaymentUserDatabase from "../components/TableRepaymentUserDatabase";
import * as XLSX from "xlsx";

const UserDatabase = () => {
  const [dataAll, setDataAll] = useState([]);
  const [dataAll2, setDataAll2] = useState([]);

  const [dataLender, setDataLender] = useState([]);
  const [dataBorrower, setDataBorrower] = useState([]);
  const [subscribedEmail, setDataSubscribedEmail] = useState([]);

  const [activeTab, setActiveTab] = useState("Borrower");
  const [activeTab2, setActiveTab2] = useState("Admin");
  const [activeUser, setActiveUser] = useState(null);
  const [activeUser2, setActiveUser2] = useState(null);
  const [modal1, setModal1] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedLoan2, setSelectedLoan2] = useState(null);
  const { Panel } = Collapse;
  const [dataRepaymentSchedule, setDataRepaymentSchedule] = useState([]);

  function hasLateDays(loan, threshold) {
    console.log("loan value:", loan);
    console.log("threshold value:", threshold);
    if (!loan.repaymentSchedule) return false;
    for (let schedule of loan.repaymentSchedule) {
      console.log("schedule status:", schedule.status);
      if (schedule.status === "pending" && schedule.late_day > threshold) {
        return true;
      }
    }
    return false; // Return false if no matching schedule is found
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1 and pad with a 0 if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with a 0 if needed

    return `${year}-${month}-${day}`;
  }

  function hasAlmostThreeDay(loan, todayDate) {
    console.log("loan value:", loan);
    console.log("todayDate value:", todayDate);
    if (!loan.repaymentSchedule) return false;

    // Convert todayDate string into a Date object
    const today = new Date(todayDate);

    for (let schedule of loan.repaymentSchedule) {
      console.log("schedule status:", schedule.status);

      // Convert schedule.repay_due_date string into a Date object
      const dueDate = new Date(schedule.repay_due_date);
      console.log("dueDate:", dueDate);
      // Calculate the difference in days
      const diffInDays = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
      console.log("diffInDays:", diffInDays);
      if (schedule.status === "pending" && diffInDays <= 3) {
        return true;
      }
    }
    return false; // Return false if no matching schedule is found
  }

  const handleDownloadCreditReport = async (userId, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewCreditReport?user_id=${userId}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // the filename you want
      a.download = "CreditReport.pdf"; // You can dynamically set the filename based on content-disposition header or any other logic

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/user/getAllUser",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        console.log("json:", json);
        setDataAll2(json);
        setDataLender(json["Lender"]);
        setDataBorrower(json["Borrower"]);
        setActiveUser(json["Borrower"][0]);
        setActiveUser2(json["Borrower"][0]);

        console.log("credit_report:", json["Borrower"]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/guest/getEmailSubcription",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        console.log("json:", json);
        setDataAll2(json);
        // setDataLender(json["Lender"]);
        // setDataBorrower(json["Borrower"]);
        // setActiveUser(json["Borrower"][0]);
        // setActiveUser2(json["Borrower"][0]);
        setDataSubscribedEmail(json);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const customXAxisTickFormatter = (value) => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${monthNames[month]} ${day}, ${year}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  const options = { day: "numeric", month: "short", year: "numeric" };
  const currentDate = new Date();

  function getSanitizedUserInfo(activeUser) {
    const excludedProperties = ["loanOffers", "subscription", "password"];

    if (activeUser) {
      let sanitizedData = {};
      Object.entries(activeUser).forEach(([key, value]) => {
        if (!excludedProperties.includes(key)) {
          sanitizedData[key] = value;
        }
      });
      return sanitizedData;
    }
    return null;
  }

  function prepareDataForExcel(userType, data) {
    let result = [];

    if (data[userType]) {
      // Add user basic info
      result = [...data[userType]];

      // Add loanAccepted
      if (data.loanAccepted) {
        result = [...result, ...data.loanAccepted];
      }

      // Add loanRequest for Borrower
      if (userType === "Borrower" && data.loanRequest) {
        result = [...result, ...data.loanRequest];
      }

      // Add singpass_data if it exists and for Borrower
      if (userType === "Borrower" && data.singpass_data) {
        if (data.singpass_data.entity) {
          result = [...result, ...data.singpass_data.entity];
        }
        if (data.singpass_data.person) {
          result = [...result, ...data.singpass_data.person];
        }
      }
    }
    return result;
  }

  function renderUserInfo(activeUser) {
    // Define an array of properties to exclude from display
    const excludedProperties = [
      "loanOffers",
      "subscription",
      "id",
      "password",
      "singpass_data",
      "loanRequest",
      "loanAccepted",
      "singpass_data_backup",
    ];
    if (activeUser) {
      return Object.entries(activeUser).map(([key, value]) => {
        // Check if the property is excluded
        if (excludedProperties.includes(key)) {
          return null; // Skip this property
        }

        // Check if the value is a JSON string (e.g., subscription)
        if (
          typeof value === "string" &&
          value.startsWith("{") &&
          value.endsWith("}")
        ) {
          try {
            value = JSON.parse(value);
          } catch (error) {
            // Handle JSON parse error (optional)
          }
        }

        // Render the label and value
        return (
          <div key={key} style={{ width: "23%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
  }

  // Usage in your component
  <div>{activeUser ? renderUserInfo(activeUser) : null}</div>;

  function exportToExcel(data) {
    if (data) {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      // Sheet for main user info
      const mainUserInfo = { ...data };
      delete mainUserInfo.singpass_data;
      delete mainUserInfo.loanRequest;
      delete mainUserInfo.loanAccepted;
      const mainWs = XLSX.utils.json_to_sheet([mainUserInfo]);
      XLSX.utils.book_append_sheet(wb, mainWs, "UserInfo");
      // Sheet for singpass_data
      if (data.singpass_data) {
        const singpassWs = XLSX.utils.json_to_sheet([
          flattenObject(data.singpass_data),
        ]);
        console.log(flattenObject(singpassWs), singpassWs);
        XLSX.utils.book_append_sheet(wb, singpassWs, "SingPass Data");
      }

      // Sheet for loanRequest
      if (data.loanRequest && data.loanRequest.length) {
        const loanRequestWs = XLSX.utils.json_to_sheet(data.loanRequest);
        console.log(loanRequestWs);
        XLSX.utils.book_append_sheet(wb, loanRequestWs, "Loan Requests");
      }

      // Sheet for loanAccepted
      if (data.loanAccepted && data.loanAccepted.length) {
        const loanAcceptedWs = XLSX.utils.json_to_sheet(data.loanAccepted);
        XLSX.utils.book_append_sheet(wb, loanAcceptedWs, "Loans Accepted");
      }

      XLSX.writeFile(wb, "user_info.xlsx"); // Save the workbook as an Excel file
    }
  }

  function exportToEmailSubscribed(data) {
    // Create a new array without the 'created_at' and 'updated_at' properties
    const filteredData = data.map(
      ({ created_at, updated_at, ...item }) => item
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribed Emails");

    // Generate an Excel file
    XLSX.writeFile(workbook, "SubscribedEmails.xlsx");
  }

  function getBorrowerInfo(data) {
    console.log("dataGetBorrower:", data.Borrower);

    // Initialize an array to hold the processed borrower information
    let processedBorrowers = [];

    // Iterating over each borrower in the Borrower array
    data.Borrower.forEach((borrower, index) => {
      console.log(`Borrower ${index}:`, borrower);

      // Check if loanAccepted and loanRequest exist and are arrays for each borrower
      const loanAccepted = Array.isArray(borrower.loanAccepted)
        ? borrower.loanAccepted
        : [];
      const loanRequest = Array.isArray(borrower.loanRequest)
        ? borrower.loanRequest
        : [];

      // Logging the contents of loanAccepted and loanRequest arrays for each borrower
      console.log(
        `Borrower ${index} Loan Accepted:`,
        loanAccepted.length > 0 ? loanAccepted : "No data"
      );
      console.log(
        `Borrower ${index} Loan Request:`,
        loanRequest.length > 0 ? loanRequest : "No data"
      );

      // Collecting processed borrower data
      processedBorrowers.push({
        Borrower: borrower,
        loanAccepted: loanAccepted,
        loanRequest: loanRequest,
        singpass_data_entity: borrower.singpass_data
          ? borrower.singpass_data.entity
          : null,
        singpass_data_person: borrower.singpass_data
          ? borrower.singpass_data.person
          : null,
      });
    });

    // Return the processed borrower information
    return processedBorrowers;
  }

  function getLenderInfo(data) {
    console.log("dataGetLender:", data.Lender);

    // Initialize an array to hold the processed lender information
    let processedLender = [];

    // Iterating over each lender in the Lender array
    data.Lender.forEach((lender, index) => {
      console.log(`Lender ${index}:`, lender);

      // Check if loanAccepted and loanOffers exist and are arrays for each lender
      const loanAccepted = Array.isArray(lender.loanAccepted)
        ? lender.loanAccepted
        : [];
      const loanOffers = Array.isArray(lender.loanOffers)
        ? lender.loanOffers
        : [];

      // Logging the contents of loanAccepted and loanOffers arrays for each lender
      console.log(
        `Lender ${index} Loan Accepted:`,
        loanAccepted.length > 0 ? loanAccepted : "No data"
      );
      console.log(
        `Lender ${index} Loan Offered:`,
        loanOffers.length > 0 ? loanOffers : "No data"
      );

      // Collecting processed lender data
      processedLender.push({
        Lender: lender,
        loanAccepted: loanAccepted,
        loanOffers: loanOffers,
        singpass_data_entity: lender.singpass_data
          ? lender.singpass_data.entity
          : null,
        singpass_data_person: lender.singpass_data
          ? lender.singpass_data.person
          : null,
      });
    });

    // Return the processed lender information
    return processedLender;
  }
  function flattenObject(obj, parentKey = "", result = {}) {
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        flattenObject(item, `${parentKey}[${index}]`, result);
      });
    } else {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let propName = parentKey ? `${parentKey}_${key}` : key;

          if (typeof obj[key] === "object" && obj[key] !== null) {
            flattenObject(obj[key], propName, result);
          } else {
            result[propName] = obj[key];
          }
        }
      }
    }
    return result;
  }

  function exportToExcelAllInfo(processedData, userType) {
    console.log("userType: ", userType);
    console.log(processedData);
    if (processedData && processedData.length > 0) {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      // Sheet for basic borrower/lender info
      const baseData = processedData.map(
        (item) => item.Borrower || item.Lender
      );

      let updatedFlattenedBaseData = [];

      for (const base of baseData) {
        const flattenedObject = flattenObject(base);
        updatedFlattenedBaseData.push(flattenedObject);
      }
      const baseWs = XLSX.utils.json_to_sheet(updatedFlattenedBaseData);
      XLSX.utils.book_append_sheet(wb, baseWs, `${userType} Info`);
      console.log(baseData, updatedFlattenedBaseData, baseWs);

      // Sheets for loanAccepted, loanRequest, singpass_data (entity and person)
      processedData.forEach((item, index) => {
        if (item.loanAccepted && item.loanAccepted.length > 0) {
          const loanAcceptedWs = XLSX.utils.json_to_sheet(item.loanAccepted);
          XLSX.utils.book_append_sheet(
            wb,
            loanAcceptedWs,
            `Loan Accepted ${index}`
          );
        }

        if (
          userType === "Borrower" &&
          item.loanRequest &&
          item.loanRequest.length > 0
        ) {
          const loanRequestWs = XLSX.utils.json_to_sheet(item.loanRequest);
          XLSX.utils.book_append_sheet(
            wb,
            loanRequestWs,
            `Loan Request ${index}`
          );
        }

        // New code for loanOffers (specific to Lender)
        if (
          userType === "Lender" &&
          item.loanOffers &&
          item.loanOffers.length > 0
        ) {
          const loanOffersWs = XLSX.utils.json_to_sheet(item.loanOffers);
          XLSX.utils.book_append_sheet(
            wb,
            loanOffersWs,
            `Loan Offers ${index}`
          );
        }

        if (userType === "Borrower" && item.singpass_data) {
          if (item.singpass_data.entity) {
            const entityWs = XLSX.utils.json_to_sheet([
              item.singpass_data.entity,
            ]);
            console.log(entityWs);
            XLSX.utils.book_append_sheet(
              wb,
              entityWs,
              `SingPass Entity Data ${index}`
            );
          }
          if (item.singpass_data.person) {
            const personWs = XLSX.utils.json_to_sheet([
              item.singpass_data.person,
            ]);
            console.log(personWs);
            XLSX.utils.book_append_sheet(
              wb,
              personWs,
              `SingPass Person Data ${index}`
            );
          }
        }
      });

      XLSX.writeFile(wb, `${userType}_info.xlsx`); // Save the workbook as an Excel file
    } else {
      console.error("No data available to export.");
    }
  }

  function renderLoanAcceptedInfo(loan) {
    // Define an array of properties to exclude from display
    const excludedProperties = ["repaymentSchedule"];
    if (loan) {
      return Object.entries(loan).map(([key, value]) => {
        // Check if the property is excluded
        if (excludedProperties.includes(key)) {
          return null; // Skip this property
        }
        return (
          <div key={key} style={{ width: "45%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
    return null;
  }

  function renderLoanInfo(loan) {
    if (loan) {
      return Object.entries(loan).map(([key, value]) => {
        return (
          <div key={key} style={{ width: "45%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
    return null;
  }

  // Usage in your component
  // <div>{activeUser ? renderLoanAcceptedInfo(activeUser) : null}</div>;

  const [resetPassword, setResetPassword] = useState("");

  const adminResetPassword = async (email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", resetPassword);
      const response = await fetch(
        "https://www.smart-lend.com/api/admin/passwordreset",
        {
          method: "POST",
          body: formData,
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        console.log(json);
        setModal4(false);
        setResetPassword("");
        message.success("Password has been changed");
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
      }}
    >
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"600px"}
        open={modal4}
        onCancel={() => setModal4(false)}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Password Reset
          </div>
        </div>

        <hr style={{ width: "95%" }} />
        <div>
          <div>User's new password</div>
          <div>
            <input
              value={resetPassword}
              onChange={(e) => {
                setResetPassword(e.target.value);
              }}
              type="password"
              style={{
                width: "400px",
                borderRadius: 10,
                padding: "10px 20px",
                fontSize: 13,
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                if (resetPassword === "") {
                  message.error("Password is empty");
                } else if (resetPassword.length < 8) {
                  message.error("Minimum 8 characters required for password.");
                } else {
                  console.log(activeUser.email);
                  adminResetPassword(activeUser.email);
                }
              }}
              style={{
                marginTop: 20,
                background: "#196cb0",
                color: "white",
                border: "2px solid #196cb0",
                padding: "5px 35px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              Reset Password
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"90vw"}
        open={modal1}
        onCancel={() => setModal1(false)}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Details
          </div>
          <div>
            <label
              onClick={() => {
                const sanitizedData = getSanitizedUserInfo(activeUser);
                exportToExcel(sanitizedData);
              }}
              className="all_button"
              style={{
                background: "#1557a9",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: "50px",
                padding: "6px 30px",
                fontSize: "12px",
                margin: "0px 0px 0px -20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Export
            </label>
          </div>
        </div>

        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "1%",
          }}
        >
          {renderUserInfo(flattenObject(activeUser))}
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        open={modal2}
        onCancel={() => setModal2(false)}
        footer={null}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "18px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          Loan Details
        </div>
        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {renderLoanAcceptedInfo(selectedLoan)}
        </div>
        <hr style={{ width: "95%" }} />
        <div style={{ width: "100%" }}>
          <Collapse defaultActiveKey={["0"]} ghost>
            <Panel
              header="View your Repayment Schedule"
              key="1"
              // onClick={(event) =>
              //   handleViewRepaymentSchedule(
              //     selectedLoan,
              //     event
              //   )
              // }
            >
              <TableRepaymentUserDatabase
                dataRepaymentSchedule={
                  selectedLoan ? selectedLoan.repaymentSchedule : []
                }
                setDataRepaymentSchedule={setDataRepaymentSchedule}
              />
            </Panel>
          </Collapse>
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        open={modal3}
        onCancel={() => setModal3(false)}
        footer={null}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "18px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          Loan Details
        </div>
        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {renderLoanInfo(selectedLoan2)}
        </div>
      </Modal>
      <div></div>
      <div
        style={{
          width: "75%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Database
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ display: "flex" }}>
              {" "}
              {/* New div to group Borrower and Lender */}
              <div
                onClick={() => {
                  setActiveTab("Borrower");
                  setActiveUser(dataBorrower[0]);
                }}
                style={{
                  background:
                    activeTab === "Borrower"
                      ? "rgba(255,255,255,0.7)"
                      : "rgba(0,0,0,0.1)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px 25px",
                  color: "#142c44cc",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Borrower
              </div>
              <div
                onClick={() => {
                  setActiveTab("Lender");
                  setActiveUser(dataLender[0]);
                  setActiveUser2(dataBorrower[0]);
                }}
                style={{
                  background:
                    activeTab === "Lender"
                      ? "rgba(255,255,255,0.7)"
                      : "rgba(0,0,0,0.1)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px 25px",
                  color: "#142c44cc",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Lender
              </div>
            </div>

            <div

            //  style={{
            //    background:

            //      "rgba(0,0,0,0.1)",
            //  borderRadius: "10px 10px 0px 0px",
            //  padding: "5px 25px",
            //  color: "#142c44cc",
            //  fontFamily: "Manrope, sans-serif",
            //  fontWeight: "800",
            //  fontSize: "13px",
            //  cursor: "pointer",
            //  }}
            >
              <label
                onClick={() => {
                  exportToEmailSubscribed(subscribedEmail);
                }}
                className="all_button"
                style={{
                  background: "#1557a9",
                  color: "white",
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "3px 15px",
                  fontSize: "10px",
                  margin: "0px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Email Subscribed
              </label>
            </div>
          </div>

          <div
            style={{
              background: "rgba(255,255,255,0.7)",
              width: "100%",
              padding: "10px",
              height: "75vh",
              borderRadius:
                activeTab === "Borrower" ? "0px 10px 10px 10px" : "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "grey",
                // borderTop: "1px solid lightgrey",
                // borderBottom: "1px solid lightgrey",
                width: "100%",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "5px 0px",
              }}
            >
              <div style={{ width: "15%", paddingLeft: "10px" }}>User ID</div>
              <div style={{ width: "20%" }}>User Name</div>
              <div style={{ width: "15%" }}>Phone Number</div>
              <div style={{ width: "30%" }}>Email</div>
              <div style={{ width: "17%" }}>Registered</div>
              {activeTab === "Borrower" ? (
                <div style={{ width: "9%" }}>
                  <label
                    onClick={() => {
                      const allUserInfo =
                        activeTab === "Borrower"
                          ? getBorrowerInfo(dataAll)
                          : getLenderInfo(dataAll);
                      exportToExcelAllInfo(allUserInfo, activeTab);
                    }}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "3px 10px",
                      fontSize: "10px",
                      margin: "0px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Export All
                  </label>
                </div>
              ) : (
                <div style={{ width: "9%" }}>
                  <label
                    onClick={() => {
                      const allUserInfo =
                        activeTab === "Lender"
                          ? getLenderInfo(dataAll)
                          : getBorrowerInfo(dataAll);
                      exportToExcelAllInfo(allUserInfo, activeTab);
                    }}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "3px 10px",
                      fontSize: "10px",
                      margin: "0px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Export All
                  </label>
                </div>
              )}
            </div>
            {activeTab === "Borrower"
              ? dataBorrower.map((user, index) => (
                  <div
                    onClick={() => setActiveUser(user)}
                    className="db_user_row"
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#142C44CC",
                      borderTop: "1px solid rgba(0,0,0,0.05)",
                      width: "100%",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "11px",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", paddingLeft: "10px" }}>
                      {user.user_id}
                    </div>
                    <div style={{ width: "20%" }}>{user.principal_name}</div>
                    <div style={{ width: "15%" }}>
                      {user.active_mobile_no
                        ? user.active_mobile_no
                        : user.mobile_no
                        ? user.mobile_no
                        : "None inserted"}
                    </div>
                    <div style={{ width: "30%" }}>
                      {user.active_email
                        ? user.active_email
                        : user.email
                        ? user.email
                        : "None inserted"}
                    </div>
                    <div style={{ width: "15%" }}>
                      {customXAxisTickFormatter(user.registration_date)}
                    </div>
                    {user.credit_report ? (
                      // Render the button if credit_report is not null/undefined
                      <button
                        className="all_button"
                        onClick={(event) =>
                          handleDownloadCreditReport(user.user_id, event)
                        }
                        style={{
                          background: "#1557a9",
                          color: "white",
                          width: "10%",
                          border: "none",
                          borderRadius: "50px",
                          padding: "3px 5px",
                          fontSize: "10px",
                          margin: "0px 0px 0px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Download Credit Report
                      </button>
                    ) : (
                      // Render an invisible placeholder if credit_report is null/undefined
                      <div
                        style={{
                          width: "10%",
                          display: "flex",
                          padding: "3px 5px",
                        }}
                      />
                    )}
                  </div>
                ))
              : dataLender.map((user, index) => (
                  <div
                    onClick={() => {
                      console.log(user);
                      setActiveUser(user);
                    }}
                    className="db_user_row"
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#142C44CC",
                      borderTop: "1px solid rgba(0,0,0,0.05)",
                      width: "100%",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "11px",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", paddingLeft: "10px" }}>
                      {user.user_id}
                    </div>
                    <div style={{ width: "20.5%" }}>{user.principal_name}</div>
                    <div style={{ width: "14.5%" }}>
                      {user.active_mobile_no
                        ? user.active_mobile_no
                        : user.mobile_no
                        ? user.mobile_no
                        : "None inserted"}
                    </div>
                    <div style={{ width: "31%" }}>
                      {user.active_email
                        ? user.active_email
                        : user.email
                        ? user.email
                        : "None inserted"}
                    </div>
                    <div style={{ width: "26.5%" }}>
                      {customXAxisTickFormatter(user.registration_date)}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "25%",
          height: "100%",
          padding: "4px 20px 20px 0px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Details
          </div>
          {activeUser ? (
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                height: "78vh",
                width: "100%",
                borderRadius: "10px",
                marginTop: "15px",
                color: "#142C44CC",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "10px",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  margin: "0px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    textAlign: "left",
                    marginLeft: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Principal Name
                    </h3>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        fontSize: "18px",
                        textAlign: "left",
                        color: "rgba(20, 44, 68, 0.8)",
                        margin: "0px",
                      }}
                    >
                      {activeUser.principal_name
                        ? activeUser.principal_name.length > 15
                          ? activeUser.principal_name.slice(0, 15) + "..."
                          : activeUser.principal_name
                        : null}
                    </p>
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        setModal1(true);
                      }}
                      className="all_button"
                      style={{
                        padding: "7px 20px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        color: "rgba(0,0,0,0.6)",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      View details
                    </div>
                    <div
                      onClick={() => {
                        setModal4(true);
                      }}
                      className="all_button"
                      style={{
                        padding: "7px 20px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        color: "rgba(0,0,0,0.6)",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        marginTop: 5,
                      }}
                    >
                      Reset password
                    </div>
                  </div>
                </div>
              </div>
              {activeTab === "Lender" ? (
                activeUser ? (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    <div
                      style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}
                    >
                      {" "}
                      <div>
                        {activeUser ? (
                          activeUser.subscription ? (
                            JSON.parse(activeUser.subscription).validity &&
                            new Date(
                              JSON.parse(activeUser.subscription).validity
                            ) < currentDate ? (
                              <div
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                  color: "red",
                                  margin: "0px",
                                }}
                              >
                                Subscription expired
                              </div>
                            ) : (
                              <div
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                  color: "rgba(0,0,0,0.4)",
                                  margin: "0px",
                                }}
                              >
                                Subscription
                              </div>
                            )
                          ) : null
                        ) : null}
                      </div>
                      <p
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "800",
                          fontSize: "18px",
                          textAlign: "left",
                          color: "rgba(20, 44, 68, 0.8)",
                          margin: "0px",
                        }}
                      >
                        <select
                          id="plan"
                          name="plan"
                          style={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: "800",
                            fontSize: "18px",
                            textAlign: "left",
                            color: "rgba(20, 44, 68, 0.8)",
                            margin: "0px",
                            padding: "8px 20px",
                            outline: "none",
                            border: "1px solid lightgrey",
                            borderRadius: "10px",
                          }}
                          value={
                            activeUser.subscription
                              ? JSON.parse(activeUser.subscription).plan
                                ? JSON.parse(activeUser.subscription).plan
                                : ""
                              : ""
                          }
                          onChange={async (e) => {
                            let sampleSubscription = { plan: "", validity: "" };
                            if (activeUser.subscription) {
                              sampleSubscription = JSON.parse(
                                activeUser.subscription
                              );
                            }
                            sampleSubscription.plan = e.target.value;
                            try {
                              const formData = new FormData();
                              formData.append("user_id", activeUser.user_id);
                              formData.append(
                                "subscription",
                                JSON.stringify(sampleSubscription)
                              );

                              const response = await fetch(
                                "https://www.smart-lend.com/api/subcription/updateSubscription",
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization:
                                      "Bearer " + Cookies.get("token"),
                                    Accept: "application/json",
                                  },
                                  body: formData,
                                }
                              );
                              const json = await response.json();
                              console.log(json);

                              const changingActiveUser = { ...activeUser };
                              changingActiveUser.subscription =
                                JSON.stringify(sampleSubscription);
                              setActiveUser(changingActiveUser);

                              const updatingUserArray = [...dataLender];
                              const lenderIndex = updatingUserArray.findIndex(
                                (lender) =>
                                  lender.user_id === activeUser.user_id
                              );
                              updatingUserArray[lenderIndex].subscription =
                                JSON.stringify(sampleSubscription);
                              setDataLender(updatingUserArray);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <option value="">Select a plan</option>
                          <option value="Platinum">Platinum</option>
                          <option value="Gold">Gold</option>
                          <option value="Silver">Silver</option>
                        </select>
                      </p>
                      <p
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px",
                        }}
                      >
                        <input
                          type="date"
                          id="validity"
                          name="validity"
                          style={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: "600",
                            fontSize: "11px",
                            color: "rgba(0,0,0,0.4)",
                            margin: "5px 0px 0px 0px",
                            padding: "6px 20px",
                            outline: "none",
                            border: "1px solid lightgrey",
                            borderRadius: "10px",
                          }}
                          value={
                            activeUser.subscription
                              ? JSON.parse(activeUser.subscription).validity
                                ? JSON.parse(
                                    activeUser.subscription
                                  ).validity.split(" ")[0]
                                : new Date().toISOString().split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          onChange={async (e) => {
                            let sampleSubscription = { plan: "", validity: "" };
                            if (activeUser.subscription) {
                              sampleSubscription = JSON.parse(
                                activeUser.subscription
                              );
                            }
                            sampleSubscription.validity = e.target.value;
                            try {
                              const formData = new FormData();
                              formData.append("user_id", activeUser.user_id);
                              formData.append(
                                "subscription",
                                JSON.stringify(sampleSubscription)
                              );

                              const response = await fetch(
                                "https://www.smart-lend.com/api/subcription/updateSubscription",
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization:
                                      "Bearer " + Cookies.get("token"),
                                    Accept: "application/json",
                                  },
                                  body: formData,
                                }
                              );
                              const json = await response.json();
                              console.log(json);

                              const changingActiveUser = { ...activeUser };
                              changingActiveUser.subscription =
                                JSON.stringify(sampleSubscription);
                              setActiveUser(changingActiveUser);

                              const updatingUserArray = [...dataLender];
                              const lenderIndex = updatingUserArray.findIndex(
                                (lender) =>
                                  lender.user_id === activeUser.user_id
                              );
                              updatingUserArray[lenderIndex].subscription =
                                JSON.stringify(sampleSubscription);
                              setDataLender(updatingUserArray);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </p>
                    </div>
                  </div>
                ) : null
              ) : null}
              {activeTab == "Borrower" ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: "10px 0px 0px 0px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Accepted
                    </h3>
                    <>
                      {activeUser.loanAccepted.map((loan, index) => {
                        const isLate = hasLateDays(loan, 0);
                        const todayDateString = getCurrentDate();
                        const isAlmostThreeDay = hasAlmostThreeDay(
                          loan,
                          todayDateString
                        );

                        return (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan(loan);
                              setModal2(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {/* <div
      style={{
        height: "8px",
        width: "8px",
        borderRadius: "8px",
        background:
          loan.status === "accepted" ? "green" : "yellow",
        boxShadow:
          loan.status === "accepted"
            ? "0px 0px 5px green"
            : "0px 0px 5px yellow",
      }}
    ></div> */}
                              <div
                                className={isLate ? "blink" : ""}
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background: isAlmostThreeDay
                                    ? "red"
                                    : loan.status === "accepted"
                                    ? "green"
                                    : "yellow",
                                  boxShadow:
                                    loan.status === "accepted"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>

                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Requested
                    </h3>
                    {activeUser.loanRequest.map((loan, index) => (
                      <div
                        key={index}
                        style={{
                          background: "rgba(255,255,255,0.7)",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          borderRadius: "10px",
                          width: "100%",
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedLoan2(loan);
                          setModal3(true);
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "12px",
                              textAlign: "left",
                              color: "rgba(0,0,0,0.5)",
                              margin: "0px",
                            }}
                          >
                            {loan.loan_id}
                          </p>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              textAlign: "left",
                              color: "rgba(20, 44, 68, 0.8)",
                              margin: "0px",
                            }}
                          >
                            {loan.request_amount}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "8px",
                              background:
                                loan.status === "accepted" ? "green" : "yellow",
                              boxShadow:
                                loan.status === "accepted"
                                  ? "0px 0px 5px green"
                                  : "0px 0px 5px yellow",
                            }}
                          ></div>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "12px",
                              textAlign: "left",
                              color: "rgba(0,0,0,0.5)",
                              margin: "0px",
                            }}
                          >
                            {new Date(loan.created_at).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : activeTab == "Lender" ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: "20px 0px 0px 0px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Accepted
                    </h3>
                    <>
                      {activeUser2.loanAccepted.map((loan, index) => {
                        const isLate = hasLateDays(loan, 0);
                        const todayDateString = getCurrentDate();
                        const isAlmostThreeDay = hasAlmostThreeDay(
                          loan,
                          todayDateString
                        );

                        return (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan(loan);
                              setModal2(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {/* <div
      style={{
        height: "8px",
        width: "8px",
        borderRadius: "8px",
        background:
          loan.status === "accepted" ? "green" : "yellow",
        boxShadow:
          loan.status === "accepted"
            ? "0px 0px 5px green"
            : "0px 0px 5px yellow",
      }}
    ></div> */}
                              <div
                                className={isLate ? "blink" : ""}
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background: isAlmostThreeDay
                                    ? "red"
                                    : loan.status === "accepted"
                                    ? "green"
                                    : "yellow",
                                  boxShadow:
                                    loan.status === "accepted"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>

                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Offered
                    </h3>
                    {activeUser.loanOffers
                      ? activeUser.loanOffers.map((loan, index) => (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan2(loan);
                              setModal3(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.offer_amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background:
                                    loan.status === "ongoing"
                                      ? "green"
                                      : "yellow",
                                  boxShadow:
                                    loan.status === "ongoing"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    background: "rgba(255,255,255,0.7)",
                    height: "78vh",
                    width: "100%",
                    borderRadius: "10px",
                    marginTop: "15px",
                    color: "#142C44CC",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "11px",
                    padding: "10px",
                  }}
                >
                  No user selected
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                height: "78vh",
                width: "100%",
                borderRadius: "10px",
                marginTop: "15px",
                color: "#142C44CC",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "10px",
              }}
            >
              No user selected
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDatabase;
