import { Box, IconButton, Typography, useTheme, Icon, FormControl, FormLabel, Slider } from "@mui/material";
import { tokens } from "../../theme";
import { makeStyles } from '@material-ui/core/styles';
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import TinyLineChart from "../../components/TinyLineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import { useHistory } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCircle";
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TableContainer } from '@material-ui/core';
import HalfPieChart from "../../components/HalfPieChart";
import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import { Progress, Button, Modal } from 'antd';
import TableLoan from "../../components/TableLoan";
import TableDelayedPayment from "../../components/TableDelayedPayment";
import Cookies from 'js-cookie';
import { format } from "date-fns";



const useStyles = makeStyles({
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ##141414`
    },
});

const gradient = 'linear-gradient(to right, #ffffff, #0083b0)'; // define the gradient as a variable





const ContainerRow2 = ({ icon, title, subtitle, label, description, label1, label2, description1, description2 }) => {
    const connector = <StepConnector />;
    const steps = [label1, label2];

    return (
        <table style={{ width: "400px", border: "1px solid white", borderRadius: "20px", backgroundColor: "#FFFFFFB3" }}>
            <thead>
                <tr>
                    <th colSpan="2" style={{ textAlign: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={icon} alt={title} style={{ width: "70px", height: "55px", borderRadius: "20px" }} />
                            <div style={{ marginLeft: "15px", marginBottom: "10px", marginTop: "7px", marginRight: "1px" }}>
                                <h3 style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "20px" }}>{title}</h3>
                                <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "200", fontSize: "11px" }}>{subtitle}</p>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th style={{ width: "200px", textAlign: "left", fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "13px" }}>Label</th>
                    <th style={{ width: "200px", textAlign: "left", fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "15px" }}>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "13px" }}>{label}</td>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "15px" }}>{description}</td>
                </tr>
                <tr>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "13px" }}>{label1}</td>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "15px" }}>{description1}</td>
                </tr>
                <tr>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "13px" }}>{label2}</td>
                    <td style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "15px" }}>{description2}</td>
                </tr>
            </tbody>
        </table>
    );
};


const ContainerActivity = ({ icon, title, subtitle }) => (
    <div style={{ width: "100%", textAlign: "left", marginBottom: "12.5px", border: "1px solid none", borderRadius: "20px", backgroundColor: "#FFFFFFB3", height: "60px" }}>
        <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
            <img src={icon} alt={title} style={{ width: "40px", height: "40px", borderRadius: "10px", margin: "10px 10px 10px 10px" }} />
            <div>
                <h3 style={{ fontFamily: "Poppins, sans-serif", fontSize: "20px", fontWeight: "600", marginTop: "8px", marginLeft: "10px" }}>{title}</h3>
                <p style={{ fontFamily: "Poppins, sans-serif", fontSize: "10px", fontWeight: "400", marginLeft: "10px" }}>{subtitle}</p>
            </div>
        </div>
    </div>
);

const ContainerActivity2 = ({ noData }) => (
    <div style={{ width: "100%", textAlign: "left", marginBottom: "12.5px", border: "1px solid none", borderRadius: "20px", backgroundColor: "#FFFFFFB3", height: "60px" }}>
        <p style={{ fontWeight: "600", fontSize: "18px", color: "#142C44CC", textAlign: "center", paddingTop: "15px" }}>{noData}</p>

    </div>
);

const ContainerRow1 = ({ onClick, icon, title, subtitle, label, description }) => (
    <div 
    onClick={onClick} // The onClick event handler 
    style={{ width: "48%", textAlign: "center", margin: "30px 1px 1px 10px", border: "1px solid none", borderRadius: "20px", backgroundColor: "#FFFFFF", cursor:"pointer" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", marginBottom: "10px", marginTop: "7px", marginRight: "1px" }}>
            <h3 style={{ fontFamily: "Poppins, sans-serif", fontWeight: "800", fontSize: "17px", color: "#142C44CC", textAlign: "left", margin: "12px 0px 0px 0px" }}>{title}</h3>
            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "200", fontSize: "8px", color: "#142C44CC", textAlign: "right", margin: "17px 10px 0px 0px" }}>{subtitle}</p>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
            <img src={icon} alt={title} style={{ background: "#f0f0f0", width: "50px", height: "45px", borderRadius: "30px", marginLeft: "12px" }} />
            <div style={{ marginLeft: "15px", marginBottom: "10px", marginTop: "7px", marginRight: "1px" }}>
                <h3 style={{ fontFamily: "Poppins, sans-serif", fontWeight: "800", fontSize: "20px", color: "#142C44CC", textAlign: "left", marginBottom: "4px", marginTop: "10px" }}>{label}</h3>
                <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500", fontSize: "12px", color: "#142C44CC", textAlign: "left", lineHeight: "1" }}>{description}</p>
            </div>
        </div>
        {/* <p>{description}</p> */}
    </div>
);

const ContainerRow3 = ({ noData }) => (
    <div style={{ width: "48%", textAlign: "center", margin: "30px 1px 1px 10px", border: "1px solid none", borderRadius: "20px", backgroundColor: "#FFFFFFB3" }}>
        <p style={{ fontWeight: "600", fontSize: "18px", color: "#142C44CC", textAlign: "center", margin: "50px auto" }}>{noData}</p>

    </div>
);

const ContainerTransaction = ({ title, label, description }) => (
    <div
        style={{
            width: "45%",
            textAlign: "center",
            margin: "30px 1px 1px 30px",
            // border: "1px solid white",
            // borderRadius: "20px",
            // backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        {/* <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
            }}
        > */}
        <div style={{ flex: 1, alignItems: "left", marginLeft: "-140px" }}>
            <h3
                style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "17px",
                    color: "#142C44CC",
                    flex: 1,
                    marginTop: "-8px",

                }}
            >
                {title}
            </h3>
            <p
                style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "200",
                    fontSize: "11px",
                    color: "#142C44CC",
                    marginTop: "-10px",
                    marginLeft: "7px"
                }}
            >
                {description}
            </p>
        </div>
        <div
            style={{
                // display: "flex",
                // justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "-40px",
                marginLeft: "500px"
            }}>
            <h3
                style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "800",
                    fontSize: "20px",
                    color: "#3AAF4C",
                    marginTop: "-8px",

                }}
            >
                {label}
            </h3>
        </div>
    </div>
);


const ContainerTransaction2 = ({ title, label, description }) => (
    <div
        style={{
            width: "100%",
            textAlign: "center",
            margin: "30px 1px 1px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}
    >


        <div
            style={{
                textAlign: "left", // added property
                alignItems: "left",

            }}>
            <h3
                style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#142C44CC",
                    marginTop: "0px",
                    whiteSpace: "nowrap",
                }}
            >
                {title}
            </h3>
        </div>
    </div>
);



const ContainerLineChart = ({ title, subtitle }) => (
    <div style={{ width: "45%", height: "50%", margin: "0 auto", border: "1px solid black" }}>
        <TinyLineChart />
        <h3>{title}</h3>
        <p>{subtitle}</p>
    </div>
);

const ContainerPieChart = ({ title, subtitle }) => (
    <div style={{ width: "45%", height: "50%", margin: "0 auto", border: "1px solid black" }}>
        <HalfPieChart />
        <h3>{title}</h3>
        <p>{subtitle}</p>
    </div>
);


const AdminLoan = () => {
    const classes = useStyles();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataActivityLog, setActivityLog] = useState([]);
    const [data, setData] = useState([]);
    const [dataPendingAcceptance, setPendingAcceptance] = useState([]);
    const [tickValues, setTickValues] = useState([]);
    const [dataClient, setClientData] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [averageClientsPerMonth, setAverageClientsPerMonth] = useState(0);    
    const [interestProfit, setInterestProfit] = useState([]);
    const [dataRequestedLoan, setRequestedLoan] = useState([]);
    const [dataRequestedLoanToday, setRequestedLoanToday] = useState([]);
    const [loanPeriod, setLoanPeriod] = useState(0);
    const [type, setLoanType] = useState("simple_loan");
    const [offer_interest_rate, setInterestRate] = useState(0);
    const [total_admin_fee, setTotalAdminFee] = useState(0);
    const [admin_fee, setAdminFee] = useState(0);
    const [offer_amount, setLoanAmount] = useState(0);
    const [offer_period, setSelectedLoanPeriod] = useState(0);
    const [offer_principal, setLoanPrincipal] = useState(0);
    const [offer_monthly_installment, setExpectedMonthlyInstallment] = useState(0);
    const [offer_revenue, setGuaranteedRevenue] = useState(0);
    const history = useHistory();
    const [loanDetails, setLoanDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalInterestProfit, setTotalInterestProfit] = useState(0);
    const [averageInterestProfit, setAverageInterestProfit] = useState(0);

    const handleClickInterestProfit = () => {
        history.push('/analytics');
      }
    
      const handleClickClient = () => {
        history.push('/analytics');
      }
    
      const handleClickLoanRequest = () => {
        history.push('/loan-request');
      }
    
      const handleClickPendingLoan = () => {
        history.push('/pending-acceptance');
      }

    const Container = ({ icon, title, subtitle, label, description, label1, label2, description1, description2, index, credit_score, loan_id }) => {
        const shouldAddMarginTop = index >= 4;
        return (
            <>
                <div style={{
                    width: "23.5%",
                    textAlign: "center",
                    marginRight: "-15px",
                    marginLeft: "25px",
                    border: "1px solid none",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFFFB3",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: shouldAddMarginTop ? "10px" : "0px", // add margin top if index is greater than 3
                }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "0px 15px 0px 15px" }}>
                        <img src={icon} alt={title} style={{ width: "55px", height: "55px", borderRadius: "1000px", border: "2px solid #f0f0f0" }} />
                        <div style={{ marginLeft: "15px", marginBottom: "10px", marginTop: "7px", marginRight: "1px" }}>
                            <h3 style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", textAlign: "left", marginTop: "18px" }}>{title}</h3>
                            <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "200", fontSize: "11px", textAlign: "left", marginTop: "-10px", opacity: ".6" }}>{subtitle}</p>
                        </div>
                    </div>
                    <div style={{ textAlign: "left", marginLeft: "10px", margin: "0px 15px 0px 15px" }}>
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", marginBottom: "-3px", opacity: ".6" }}>{label}</p>
                        <Progress percent={credit_score} showInfo={false} strokeColor={
                            subtitle === "Express Applicant" ? { '0%': '#FFE6B2', '100%': '#FED065' } :
                                { '0%': '#b1c3e0', '100%': '#1557a9' }
                        } />
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "15px", marginTop: "-10px" }}>{description}</p>
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6", marginTop: "-3px" }}>{label1}</p>
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", marginTop: "-17px" }}>{description1}</p>
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "10px", opacity: ".6", marginTop: "-3px" }}>{label2}</p>
                        <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", marginTop: "-17px" }}>{description2}</p>
                    </div>
                    <div key={loan_id}>
                        <Button
                            type="primary"
                            size="middle"
                            onClick={(event) => showModal(loan_id, event)}
                            style={subtitle === "Express Applicant" ? buttonStyleExpress : buttonStyleBorrower}
                        >
                            Make an offer
                        </Button>


                    </div>
                </div>
            </>
        );
    };

    const Container3 = ({ noData }) => {

        return (
            <>
                <div style={{
                    width: "97%",
                    textAlign: "center",
                    height: "240px",
                    marginLeft:"25px",
                    border: "1px solid none",
                    borderRadius: "20px",
                    backgroundColor: "#FFFFFFB3",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",

                }}>
                    <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color: "#142C44CC", textAlign: "center", paddingTop: "20px" }}>{noData}</p>


                </div>
            </>
        );
    };



    function isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    function isYesterday(date) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();
    }

    function isTwoDaysAgo(date) {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        return date.getDate() === twoDaysAgo.getDate() &&
            date.getMonth() === twoDaysAgo.getMonth() &&
            date.getFullYear() === twoDaysAgo.getFullYear();
    }

    useEffect(() => {
        // To redirect user if not authenticated
        const isAuthenticated = Cookies.get('token');
        const user_account_type = Cookies.get('user_account_type');
    
        if (!isAuthenticated) {
          history.push("/auth/signin");
        } else if (user_account_type === 'borrower') {
          history.push("/b-dashboard");
        } else if (user_account_type === 'lender') {
          history.push("/dashboard")
        }
      }, [history]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://www.smart-lend.com/api/analytics/client",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + Cookies.get("token")
                        },
                    }
                );
                const result = await response.json();
                const transformedData = result["Clients"].map((d) => ({
                    month: d.month,
                    client: d.client,
                }));
                setTickValues([...new Set(transformedData.map((d) => d.client))]);
                setClientData(transformedData);
                setTotalClients(result["Total clients"]);
                setAverageClientsPerMonth(result["Average clients per month"]);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));

            const options = {
                method: 'GET',
                headers: headers
            };

            try {
                const response = await fetch(
                    "https://www.smart-lend.com/api/loan/viewPendingAcceptance",
                    options
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                setPendingAcceptance(data["Pending Loan"]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);



    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));
    
            const options = {
                method: 'GET',
                headers: headers
            };
    
            try {
                const response = await fetch(
                    "https://www.smart-lend.com/api/loan/loanDashboard/viewInterestProfit",
                    options
                );
    
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
    
                const data = await response.json();
    
                setInterestProfit(data);
                setTotalInterestProfit(data["Total Interest Profit"]);
                setAverageInterestProfit(data["Average"]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));

            const options = {
                method: 'GET',
                headers: headers
            };

            try {
                const response = await fetch(
                    "https://www.smart-lend.com/api/dashboard/lender/viewLatestTransaction",
                    options
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                setData(data["Latest Transaction"]);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);


    useEffect(() => {
        const calculateLoan = () => {
            if (type === "simple_loan") {
                const offer_monthly_installment = (offer_amount / offer_period + offer_amount * (offer_interest_rate / 100)) * 1.05 || '0';
                const offer_principal = offer_monthly_installment * offer_period || '0';
                const total_admin_fee = (offer_amount / offer_period + offer_amount * (offer_interest_rate / 100)) * 0.6 || '0';
                const offer_revenue = offer_principal - offer_amount - total_admin_fee || '0';
                const admin_fee = 0.05;
                setAdminFee(admin_fee);
                setLoanPrincipal(offer_principal);
                setExpectedMonthlyInstallment(offer_monthly_installment);
                setGuaranteedRevenue(offer_revenue);
                setTotalAdminFee(total_admin_fee);
            }
            else if (type === "effective_loan") {
                const offer_monthly_installment = ((offer_amount * (offer_interest_rate / 1200)) * ((1 + (offer_interest_rate / 1200)) ** offer_period)) / (((1 + (offer_interest_rate / 1200)) ** offer_period) - 1) * 1.05 || '0';
                const offer_principal = offer_monthly_installment * offer_period || '0';
                const total_admin_fee = ((offer_amount * (offer_interest_rate / 1200)) * ((1 + (offer_interest_rate / 1200)) ** offer_period)) / (((1 + (offer_interest_rate / 1200)) ** offer_period) - 1) * 0.6 || '0';
                const offer_revenue = offer_principal - offer_amount - total_admin_fee || '0';
                const admin_fee = 0.05;
                setAdminFee(admin_fee);
                setLoanPrincipal(offer_principal);
                setExpectedMonthlyInstallment(offer_monthly_installment);
                setGuaranteedRevenue(offer_revenue);
                setTotalAdminFee(total_admin_fee);
            }
        };
        calculateLoan();
    }, [offer_amount, offer_interest_rate, offer_period, type, offer_principal, offer_monthly_installment, offer_revenue, total_admin_fee, admin_fee]);

    const fetchLoanDetails = async (loan_id) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + Cookies.get("token"));

        const options = {
            method: "GET",
            headers: headers,
        };

        try {
            const response = await fetch(
                `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
                options
            );

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            setLoanDetails(data);
        } catch (error) {
            console.error(error);
        }
    };
    const [modalStates, setModalStates] = useState({});

    useEffect(() => {
        const initialStates = {};
        data.forEach(item => {
            initialStates[item.loan_id] = false;
        });
        setModalStates(initialStates);
    }, [data]);

    const handleLoanAmountChange = (e) => {
        setLoanAmount(e.target.value);
    };

    const handleGridPath = () => {
        // history.push('../loan-request-grid')
    };

    const handleInterestRateChange = (e) => {
        setInterestRate(e.target.value);
    };

    const handleLoanPeriodChange = (e) => {
        setLoanPeriod(e.target.value);
    };

    const handleLoanTypeChange = (e) => {
        setLoanType(e.target.value);
    };

    const formatDate = (dateString) => {
        if (!dateString) return "-"; // Return a default value if the date string is not provided

        const date = new Date(dateString); // Create a Date object from the date string
        const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

        return formattedDate;
    };
    const [open, setOpen] = useState(false);
    const showModal = (loanId, event) => {
        event.preventDefault();
        console.log("loanId modal", loanId);
        setModalStates(prevStates => ({
            ...prevStates,
            [loanId]: true
        }));
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleListPath = () => {
        history.push('../loan-request')
    }

    const handleMakeOffer = async (loan_id) => {
        console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
        // rest of the code
        try {
            let path = '';
            let item = {
                loan_id,
                offer_amount: parseFloat(offer_amount),
                offer_interest_rate: parseFloat(offer_interest_rate),
                offer_period: parseInt(offer_period),
                type,
                offer_principal: parseFloat(offer_principal),
                offer_monthly_installment: parseFloat(offer_monthly_installment),
                offer_revenue: parseFloat(offer_revenue),
                total_admin_fee: parseFloat(total_admin_fee),
                admin_fee: parseFloat(admin_fee)
            };
            const response = await fetch('https://www.smart-lend.com/api/loan/createLoan', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('token'),
                },
                body: JSON.stringify(item)
            });
            const data = await response.json();
            path = '/pending-acceptance';

            console.log('Offer created:', data);
            return history.push(path);
            // Update your UI or close the modal
        } catch (error) {
            console.error('Error creating offer:', error);
        }
    };
    const buttonStyleExpress = {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "15px",
        color: "#FFFFFF",
        textAlign: "center",
        background: "#FED065",
        border: "none",
        borderRadius: "1000px",
        width: "90%",
        height: "40px",
        marginTop: "5px",
        marginBottom: "15px"
    };

    const buttonStyleBorrower = {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "15px",
        color: "#FFFFFF",
        textAlign: "center",
        background: "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
        border: "none",
        borderRadius: "1000px",
        width: "90%",
        height: "40px",
        marginTop: "5px",
        marginBottom: "15px"
    };




    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));

            const options = {
                method: "GET",
                headers: headers,
            };

            try {

                const response = await fetch(
                    "https://www.smart-lend.com/api/loan/loanDashboard/viewRequestToday",
                    options
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                setRequestedLoanToday(data["Requested Loan"]);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));

            const options = {
                method: "GET",
                headers: headers,
            };

            try {

                const response = await fetch(
                    "https://www.smart-lend.com/api/loan/viewLoanRequest",
                    options
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                setRequestedLoan(data["Requested Loan"]);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + Cookies.get('token'));

            const options = {
                method: 'GET',
                headers: headers
            };

            try {
                const response = await fetch(
                    "https://www.smart-lend.com/api/profile/viewActivityLog",
                    options
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                setActivityLog(data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    return (
        <Box m="4px 20px 20px 20px">
            {/* HEADER */}
            {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box> */}

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
                borderRadius="20px"
            >
                {/* ROW 1 */}


                {/* ROW 2 */}
                <Box
                    gridColumn="span 9"
                    gridRow="span 2"
                    borderRadius="20px"
                    border="0px solid white"
                    paddingBottom="100px"
                    opacity="0.95"
                    style={{
                        overflowY: "scroll",
                        height: "100vh",
                        overflowX: "hidden",
                        background: "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
                    }}
                >
                    <Box
                        gridColumn="span 8"
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "50%", marginLeft: "15px" }}>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", width: "100%", marginBottom: "-20px" }}>
                                    {/* {interestProfit && "Total Interest Profit" in interestProfit ? (

                                            <ContainerRow1
                                                onClick={handleClickInterestProfit}
                                                key={0}
                                                icon={require("../../assets/img/businessanalyticsicon.png")}
                                                title="Interest Profit"
                                              
                                                label={totalInterestProfit.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "-"}
                                                description={"Average of " + averageInterestProfit.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) +" per month"}
                                            />
                                        ) : (
                                            <ContainerRow3
                                                noData="No Interest Profit Data"

                                            />

                                        )} */}
                                        {dataClient && dataClient.length > 0 ? (
                                            <ContainerRow1
                                            onClick={handleClickClient}

                                                icon={require("../../assets/img/customersicon.png")}
                                                title="Clients"
                                               
                                                label={totalClients + " Clients" || "-"}
                                                description={`Average of ${averageClientsPerMonth} clients per month`}
                                                />
                                        ) : (
                                            <ContainerRow3
                                                noData="No Client Data"

                                            />
                                        )}
                                    </div>
                                    <div style={{ display: "flex", width: "100%" }}>
                                        {dataRequestedLoan && dataRequestedLoan.length > 0 ? (
                                            <ContainerRow1
                                            onClick={handleClickLoanRequest}

                                                icon={require("../../assets/img/applicationicon.png")}
                                                title="Loan Request"
                                                label={dataRequestedLoan.length} // replace the hard-coded value with the length of the array
                                                description="Average up by ^2.3%"
                                            />
                                        ) : (
                                            <ContainerRow3
                                                noData="No Loan Request Data"

                                            />

                                        )}
                                        {dataPendingAcceptance && dataPendingAcceptance.length > 0 ? (
                                            <ContainerRow1
                                            onClick={handleClickPendingLoan}

                                                icon={require("../../assets/img/hourglassicon.png")}
                                                title="Pending Loan"
                                                label={dataPendingAcceptance.length}
                                                description="Loan awaiting approval"
                                            />
                                        ) : (
                                            <ContainerRow3
                                            noData="No Pending Loan Data"

                                        />
                                        )
                                        }
                                    </div>

                                </div>
                            </div>
                            <div style={{ width: "50%", marginTop: "25px", marginLeft: "15px", marginRight: "25px" }}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    colors={colors.grey[100]}
                                    p="15px"
                                >
                                    <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px" marginLeft="-15px">
                                        Pending Loans
                                    </Typography>
                                </Box>
                                {dataActivityLog && dataActivityLog.length > 0 && dataActivityLog.slice(0, 3).map ? ((item, index) => (

                                    <Box
                                        gridColumn="span 1"
                                        display="flex"
                                        alignItems="left"
                                        justifyContent="left"
                                    >
                                        <ContainerActivity
                                            key={index}
                                            icon="https://www.gizmochina.com/wp-content/uploads/2018/01/Logo-Sony.jpg"
                                            title={item.principal_name || "-"}
                                            subtitle={item.action || "-"}
                                            index={index}
                                        />
                                    </Box>
                                )) : (
                                    <Box
                                        gridColumn="span 1"
                                        display="flex"
                                        alignItems="left"
                                        justifyContent="left"
                                    >
                                        <ContainerActivity2
                                            noData="No Pending Loans Data"

                                        />
                                    </Box>
                                )}


                            </div>
                        </div>
                    </Box>


                    <Box
                        gridColumn="span 4"
                        gridRow="span 2"
                        background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                        border-radius="20px"
                        opacity="0.95"

                    >
                        <Box
                            display="flex"
                            flexDirection="row" // set flexDirection to row
                            justifyContent="space-between"
                            alignItems="center"
                            opacity="1.00"
                            marginTop="25px"
                            marginBottom="10px"
                        >
                            <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: "25px" }}>
                                <Typography color={colors.grey[920]} variant="h5" fontWeight="600" style={{ textAlign: 'left' }}>
                                    Upcoming Payments
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1, marginRight: "20px" }}>
                                <p style={{ color: "#142C4466", fontSize: "8px", marginLeft: "8px", textAlign: 'right', marginTop: "15px" }}>View All &gt;</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                <Typography color={colors.grey[920]} variant="h5" fontWeight="600" style={{ textAlign: 'left' }}>
                                    Delayed Payments
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1, marginRight: "25px" }}>
                                <p style={{ color: "#142C4466", fontSize: "8px", marginLeft: "8px", textAlign: 'right', marginTop: "15px" }}> {<a style={{ color: "#142C44CC", textDecoration: "none" }} href="/delayed-payments">View All &gt;</a>}    </p>
                            </div>
                        </Box>



                        <Box display="flex" flexDirection="row">

                            <Box
                                gridColumn="span 4"
                                width="50%"
                                marginLeft="25px"
                            >

                                <TableLoan />


                            </Box>

                            <Box
                                gridColumn="span 4"
                                width="50%"
                                marginLeft="20px"
                                marginRight="25px"
                            >

                                <TableDelayedPayment />


                            </Box>

                        </Box>
                        <Box
                            gridColumn="span 8"
                            gridRow="span 2"
                            // background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                            border-radius="20px"
                            opacity="0.95"
                        >

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                // borderBottom={`4px solid ${colors.primary[500]}`}
                                colors={colors.grey[910]}
                                opacity="1.00"
                                marginTop="25px"
                                marginBottom="10px"
                                marginLeft="25px"
                            >
                                <Typography color={colors.grey[920]} variant="h5" fontWeight="600"
                                >
                                    Request of the day
                                </Typography>
                            </Box>


                            <Box
                                gridColumn="span 4"
                            // backgroundColor={colors.grey[910]}

                            >
                                <div style={{ display: "flex", flexWrap: "wrap", color: "grey", width: "auto", marginRight: "10px" }}>
                                    {dataRequestedLoanToday && dataRequestedLoanToday.length > 0 ? dataRequestedLoanToday.map((item, index) => (
                                        <Container
                                            key={index}
                                            icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                                            title={item.principal_name || "-"}
                                            subtitle={item.express === 1 ? "Express Applicant" : "Approved Borrower"}
                                            label="Credit Score"
                                            description={item.credit_score || "-"}
                                            label1="Loan Amount"
                                            description1={"SGD " + item.request_amount.toFixed(2) || "-"}
                                            label2="Period"
                                            description2={item.request_period + " Months" || "-"}
                                            index={index} // pass the index prop to each Container instance
                                            progressPercent={item.credit_score} // set the progress percentage
                                            loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                                            open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                                            onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                                            cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                                        />
                                    )) : (
                                        <Container3

                                            noData="No Today Requested Loan"

                                        />


                                    )}
                                    {dataRequestedLoanToday && dataRequestedLoanToday.length > 0 && dataRequestedLoanToday.map((item, index) => (

                                        <Modal
                                            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                                            open={modalStates[item.loan_id]}
                                            onCancel={() => setModalStates(prevStates => ({
                                                ...prevStates,
                                                [item.loan_id]: false
                                            }))}

                                            footer={[

                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                                                    <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                                                </div>
                                            ]}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ flex: 1 }}>
                                                    <p>Borrower:  {item.principal_name || '-'} </p>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <p>Loan ID: {item.loan_id} </p>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, borderRadius: "10px" }}>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor="offer_amount">Loan Amount</label>
                                                    <br />
                                                    <input type="number" id="offer_amount" name="offer_amount" style={{ borderRadius: "10px" }} onChange={handleLoanAmountChange} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor="offer_interest_rate">Interest Rate (%)</label>
                                                    <br />
                                                    <input type="number" id="offer_interest_rate" name="offer_interest_rate" style={{ borderRadius: "10px" }} onChange={handleInterestRateChange} />
                                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor="offer_period">Loan Period (Month)</label>
                                                    <br />
                                                    <input type="number" id="offer_period" name="offer_period" style={{ borderRadius: "10px" }} onChange={handleLoanPeriodChange} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor="type">Loan Type</label>
                                                    <br />
                                                    <select id="type" name="type" style={{ borderRadius: "10px" }} onChange={handleLoanTypeChange}>
                                                        <option value="simple_loan">Simple Loan</option>
                                                        <option value="effective_loan">Effective Loan</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <hr />

                                            <p>Total cost of Financing: {Number(offer_principal).toFixed(2)}</p>
                                            <p>Expected Monthly Installment: {Number(offer_monthly_installment).toFixed(2)}</p>
                                            <p>Guaranteed Revenue: {Number(offer_revenue).toFixed(2)}</p>
                                            <p>Total Admin Fee: {Number(total_admin_fee).toFixed(2)}</p>
                                        </Modal>
                                    ))}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor="rgba(153, 178, 205, 0)"
                    height="90.5vh"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        // borderBottom={`4px solid ${colors.primary[500]}`}
                        colors={colors.grey[910]}
                        marginTop="-26px"
                        p="15px"
                    >
                        <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px" marginLeft="-10px">
                            Recent Transactions
                        </Typography>
                        <p style={{ marginLeft: '8px', color: "#142C4466", marginTop: "20px", fontSize: "8px" }}>View All &gt;</p>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        backgroundColor="#FFFFFF"
                        borderRadius="20px"
                        marginTop="-10px"
                        p="15px"
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start" // Updated to align to the top
                            colors={colors.grey[910]}
                            // borderBottom={`1px solid ${colors.primary[500]}`}
                            width="45%"
                            textAlign="center"
                            margin="1px 1px 1px 30px"
                            flexDirection="column"
                        >
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" fontFamily="Manrope, sans-serif" fontSize="20px" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                Today
                            </Typography>
                            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3" }} />
                        </Box>
                        {data &&
                            data
                                .filter((item) => isToday(new Date(item.repay_date)))
                                .map((item, index) => (
                                    <Box
                                        key={`today-${index}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        colors={colors.grey[910]}
                                    >
                                        <ContainerTransaction
                                            title={item.principal_name || "-"}
                                            subtitle="View All >"
                                            label={"$" + item.repay_amount.toFixed(2) || "-"}
                                            description={formatDate(item.repay_date) || "-"}
                                        />
                                    </Box>
                                ))}
                        {!data ||
                            (data.filter((item) => isToday(new Date(item.repay_date))).length === 0 && (
                                <Box
                                    key="today-none"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    colors={colors.grey[910]}
                                >
                                    <ContainerTransaction2
                                        title="No Today's Transaction"
                                        subtitle="-"
                                        label="No Today's Transaction"
                                        description="-"
                                    />
                                </Box>
                            ))}



                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start" // Updated to align to the top
                            colors={colors.grey[910]}
                            // borderBottom={`1px solid ${colors.primary[500]}`}
                            width="45%"
                            textAlign="center"
                            margin="30px 1px 1px 30px"
                            flexDirection="column"
                            marginTop="30px"
                        >
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" fontFamily="Manrope, sans-serif" fontSize="20px">
                                Yesterday
                            </Typography>
                            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3", marginTop: "7px" }} />
                        </Box>
                        {data &&
                            data
                                .filter((item) => isYesterday(new Date(item.repay_date)))
                                .map((item, index) => (
                                    <Box
                                        key={`yesterday-${index}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        colors={colors.grey[910]}
                                    >
                                        <ContainerTransaction
                                            title={item.principal_name || "-"}
                                            subtitle="View All >"
                                            label={"$" + item.repay_amount.toFixed(2) || "-"}
                                            description={formatDate(item.repay_date) || "-"}
                                        />
                                    </Box>
                                ))}
                        {!data ||
                            (data.filter((item) => isYesterday(new Date(item.repay_date))).length === 0 && (
                                <Box
                                    key="yesterday-none"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    colors={colors.grey[910]}
                                >
                                    <ContainerTransaction2
                                        title="No Yesterday's Transaction"
                                        subtitle="-"
                                        label="-"
                                        description="-"
                                    />
                                </Box>
                            ))}





                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start" // Updated to align to the top
                            colors={colors.grey[910]}
                            width="45%"
                            textAlign="center"
                            margin="30px 1px 1px 30px"
                            flexDirection="column"
                            marginTop="30px"
                        >
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" fontFamily="Manrope, sans-serif" fontSize="20px">
                                Two Days Ago
                            </Typography>
                            <div style={{ borderBottom: "2px solid black", width: "190%", opacity: "0.3", marginTop: "7px" }} />
                        </Box>
                        {data &&
                            data
                                .filter((item) => isTwoDaysAgo(new Date(item.repay_date)))
                                .map((item, index) => (
                                    <Box
                                        key={`twoDaysAgo-${index}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        colors={colors.grey[910]}
                                    >
                                        <ContainerTransaction
                                            title={item.principal_name || "-"}
                                            subtitle="View All >"
                                            label={"$" + item.repay_amount.toFixed(2) || "-"}
                                            description={formatDate(item.repay_date) || "-"}
                                        />
                                    </Box>
                                ))}
                        {!data ||
                            (data.filter((item) => isTwoDaysAgo(new Date(item.repay_date))).length === 0 && (
                                <Box
                                    key="twoDaysAgo-none"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    colors={colors.grey[910]}
                                >
                                    <ContainerTransaction2
                                        title="No Two Days Ago Transaction"
                                        subtitle="-"
                                        label="-"
                                        description="-"
                                    />
                                </Box>
                            ))}

                    </Box>







                    {/* {mockTransactions.map((transaction, i) => (
                        <Box
                            key={`${transaction.txId}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            // borderBottom={`4px solid ${colors.primary[500]}`}
                            backgroundColor="white"
                            borderRadius="20px"
                            p="15px"
                        >
                            <Box>
                                <Typography
                                    color={colors.greenAccent[500]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    {transaction.txId}
                                </Typography>
                                <Typography color={colors.grey[100]}>
                                    {transaction.user}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{transaction.date}</Box>
                            <Box
                                backgroundColor={colors.greenAccent[500]}
                                p="5px 10px"
                                borderRadius="4px"
                            >
                                ${transaction.cost}
                            </Box>
                        </Box>
                    ))} */}
                </Box>




            </Box>
        </Box>
    );
};

export default AdminLoan;
