import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Integrated from "./img/integrated_singpass.png";
import Apply from "./img/borrowers_apply.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Typography, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "react-tooltip";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import "./App.css";
import HomeHeader from "./Headesr";
import tiktokIcon from "./img/tik-tok.png";
import telegramicon from "./img/telegram.png";
import linkedinicon from "./img/linkedin.png";
import whatsappicon from "./img/whatsapp.png";

const ContactUs = () => {
  const history = useHistory();
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    setPageLoad(true);
  }, []);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {/* Header bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          alignItems: "center",
        }}
      >
        <HomeHeader />
      </div>

      {/* first segment */}
      <div style={{ padding: "80px 20px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginLeft: pageLoad ? "0px" : "-300px",
            opacity: pageLoad ? "1" : "0",
            transition: "all 0.8s ease-in-out",
          }}
        >
          <div style={{ width: screenWidth > 1200 ? "70%" : "100%" }}>
            <div
              style={{
                fontSize: screenWidth > 1200 ? "65px" : "40px",
                fontWeight: "700",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Contact Us
            </div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "400",
                width: screenWidth > 1200 ? "70%" : "100%",
                marginTop: "20px",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Get in touch with us, right here right now. We want to answer your
              queries when you want it, and when you need it. Thus you can now
              directly get in contact with our agents with our chat system.
            </div>
          </div>
        </div>
        <hr
          style={{
            width: "70%",
            height: "0.01rem solid grey",
            marginLeft: screenWidth > 1200 ? "15%" : 0,
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: screenWidth > 1200 ? "70%" : "100%",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Tour our FAQ
              </div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "10px",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                FAQs are a great way to start to help answer all your questions,
                as they are filled with some of the most frequently asked
                question that are sometimes just very simple.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                  cursor: "pointer",
                }}
                className="mui-button"
                onClick={() => {
                  history.push("/frequentlyaskedquestions");
                  window.scrollTo(0, 0);
                }}
              >
                Get Started
                <ArrowForwardIcon
                  style={{ marginLeft: "5px", fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr
          style={{
            width: "70%",
            height: "0.01rem solid grey",
            marginLeft: screenWidth > 1200 ? "15%" : 0,
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              width: screenWidth > 1200 ? "70%" : "100%",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Talk to our agents.
              </div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "10px",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Now you can reach our agents with just a simple message, no more
                contact forms or whatnot, our agent typically replies in 5-10
                minutes to all your queries, whether you need help in creating a
                project to as simple as tying your shoe-laces, our agents are
                here for you.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                  cursor: "pointer",
                }}
                className="mui-button"
                onClick={() => {
                  window.location.href = "https://wa.me/6588288147";
                }}
              >
                Get Started
                <ArrowForwardIcon
                  style={{ marginLeft: "5px", fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr
          style={{
            width: "70%",
            height: "0.01rem solid grey",
            marginLeft: screenWidth > 1200 ? "15%" : 0,
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: screenWidth > 1200 ? "70%" : "100%",
              flexDirection: screenWidth > 1200 ? "row" : "column",
            }}
          >
            <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Careers
              </div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "10px",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Join our team and be part of a dynamic workplace! With a yearly
                hiring growth of 20% and quarterly hiring, we are committed to
                finding talented individuals to join our team. Check out the
                link on the right for available positions. We welcome fresh
                graduates as we believe new minds bring fresh ideas. Join us and
                be part of a company that values growth and innovation.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                }}
                className="mui-button"
                data-tooltip-id="my-tooltip"
                data-tooltip-html="There are no current hiring positions available,<br /> stick around to find out more."
              >
                No Availability
              </div>
              <Tooltip
                id="my-tooltip"
                place="left"
                style={{ fontSize: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer section */}
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          padding: screenWidth > 1200 ? "40px 150px" : "40px 0px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: screenWidth > 1200 ? "left" : "center",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "60%" : "100%" }}>
          <div
            style={{
              width: screenWidth > 1200 ? "40%" : "100%",
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
          >
            SmartLend.
          </div>
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Apply Once, Access All.
          </div>
          <div
            style={{
              fontWeight: "300",
              fontSize: "13px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            Smart Towkay Ventures Pte. Ltd., SmartLend Financial Pte. Ltd., are
            part of the Smart Towkay Brand.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "18px", marginTop: "15px" }}
          >
            Singapore HQ
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "3px",
              width: screenWidth > 1200 ? "100%" : "80%",
              marginLeft: screenWidth > 1200 ? "0%" : "10%",
            }}
          >
            114 Lavender St., #07-77 CT Hub 2, Singapore 338729
          </div>
          <div
            style={{ fontWeight: "400", fontSize: "14px", marginTop: "15px" }}
          >
            Folow us and Stay updated
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: screenWidth > 1200 ? "flex-start" : "center",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <TwitterIcon
              className="menu_button"
              style={{
                marginRight: "12px",
                color: "rgba(0,0,0,1)",
                cursor: "pointer",
              }}
            />
            <img
              className="menu_button"
              src={tiktokIcon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={telegramicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={linkedinicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
            <img
              className="menu_button"
              src={whatsappicon}
              style={{
                width: 18,
                height: 18,
                objectFit: "cover",
                marginRight: "12px",
              }}
            />
          </div>
        </div>
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>Sitemap</div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/home");
                window.scrollTo(0, 0);
              }}
            >
              Home
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/partner-with-us");
                window.scrollTo(0, 0);
              }}
            >
              Partner With Us
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
            >
              Frequently Asked Questions
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/news-events");
                window.scrollTo(0, 0);
              }}
            >
              News & Events
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "14px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </div>
          </div>
        ) : null}
        {screenWidth > 1200 ? (
          <div style={{ width: "15%" }}>
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Enterprise
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "15px" }}
            >
              SmartLend for Business
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              SmartAds
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "5px" }}
            >
              Express Loan
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          background: "rgba(0,0,0,0.85)",
          justifyContent: "center",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: screenWidth > 1200 ? "30%" : "80%",
            color: "rgba(256,256,256,.9)",
            fontSize: screenWidth > 1200 ? "14px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Terms and Conditions</div>
          <div style={{ width: "10px" }}>•</div>
          <div>© SmartLend Pte Ltd 2023</div>
          <div style={{ width: "10px" }}>•</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
