import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SgIcon from "./img/197496.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const HomeHeader = () => {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1280);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "85%",
      }}
    >
      <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
        <div
          style={{
            fontWeight: "700",
            fontSize: "35px",
            color: "#256199",
          }}
          className="menu_button"
          onClick={() => {
            history.push("/home");
            window.scrollTo(0, 0);
          }}
        >
          SmartLend.
        </div>
      </div>
      {screenWidth > 1200 ? (
        <div
          style={{
            width: "70%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          <div
            className="menu_button"
            onClick={() => {
              history.push("/home");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/home" ? "700" : "500",
              color:
                location.pathname === "/home" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            Home
          </div>

          <div
            className="menu_button"
            onClick={() => {
              history.push("/partner-with-us");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/partner-with-us" ? "700" : "500",
              color:
                location.pathname === "/partner-with-us" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            Partner With Us
          </div>
          <div
            className="menu_button"
            onClick={() => {
              history.push("/frequentlyaskedquestions");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/frequentlyaskedquestions" ? "700" : "500",
              color:
                location.pathname === "/frequentlyaskedquestions" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            Frequently Asked Questions
          </div>
          <div
            className="menu_button"
            onClick={() => {
              history.push("/roadmap");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/roadmap" ? "700" : "500",
              color:
                location.pathname === "/roadmap" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            Roadmap
          </div>
          <div
            className="menu_button"
            onClick={() => {
              history.push("/news-events");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/news-events" ? "700" : "500",
              color:
                location.pathname === "/news-events" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            News & Events
          </div>
          <div
            className="menu_button"
            onClick={() => {
              history.push("/contactus");
              window.scrollTo(0, 0);
            }}
            style={{
              fontWeight: location.pathname === "/contactus" ? "700" : "500",
              color:
                location.pathname === "/contactus" ? "#256199" : "rgba(0,0,0,0.7)",
            }}
          >
            Contact Us
          </div>
          <hr
            style={{
              width: "2px",
              height: "20px",
              margin: "0px",
              outline: "none",
              border: "none",
              background: "rgba(0,0,0,0.3)",
            }}
          />
          <div
            className="menu_button"
            onClick={() => {
              history.push("/auth/signin");
              window.scrollTo(0, 0);
            }}
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "white",
              border: "2px solid #256199",
              background: "#256199",
              padding: "5px 40px",
              borderRadius: "15px",
            }}
          >
            Sign In
          </div>
          <div
            className="menu_button"
            onClick={() => {
              history.push("/");
              window.scrollTo(0, 0);
            }}
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "#256199",
              border: "none",
              background: "white",
              padding: "5px 15px 5px 5px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={SgIcon} style={{ height: "18px", marginRight: "10px" }} />
            <div>SG</div>
          </div>
        </div>
      ) : (
        <div>
          <MenuIcon
            style={{ fontSize: 35 }}
            onClick={() => setOpenMenu(true)}
          />
        </div>
      )}
      <div
        style={{
          width: screenWidth,
          height: screenHeight,
          position: "fixed",
          background: "white",
          zIndex: 200,
          top: 0,
          left: openMenu ? 0 : screenWidth,
          transition: "0.3s all ease-in-out",
        }}
      >
        <div>
          <CloseIcon
            style={{ fontSize: 35, position: "absolute", top: 30, right: 30 }}
            onClick={() => setOpenMenu(false)}
          />
        </div>
        <div
          style={{ margin: "30px 0px 0px 20px", width: "calc(100% - 40px)" }}
        >
          <div
            style={{
              fontWeight: "700",
              fontSize: "35px",
              color: "#256199",
            }}
            onClick={() => {
              history.push("/home");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
          >
            SmartLend.
          </div>
          <div
            onClick={() => {
              history.push("/home");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
            style={{
              fontWeight: "500",
              color: "rgba(0,0,0,0.7)",
              marginTop: "25px",
            }}
          >
            Home
          </div>
          <div
            onClick={() => {
              history.push("/partner-with-us");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
            style={{
              fontWeight: "500",
              color: "rgba(0,0,0,0.7)",
              marginTop: "15px",
            }}
          >
            Partner With Us
          </div>
          <div
            onClick={() => {
              history.push("/frequentlyaskedquestions");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
            style={{
              fontWeight: "500",
              color: "rgba(0,0,0,0.7)",
              marginTop: "15px",
            }}
          >
            Frequently Asked Questions
          </div>
          <div
            onClick={() => {
              history.push("/news-events");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
            style={{
              fontWeight: "500",
              color: "rgba(0,0,0,0.7)",
              marginTop: "15px",
            }}
          >
            News & Events
          </div>
          <div
            onClick={() => {
              history.push("/contactus");
              window.scrollTo(0, 0);
              setOpenMenu(false);
            }}
            style={{
              fontWeight: "500",
              color: "rgba(0,0,0,0.7)",
              marginTop: "15px",
            }}
          >
            Contact Us
          </div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                history.push("/auth/signin");
                window.scrollTo(0, 0);
                setOpenMenu(false);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                border: "2px solid #256199",
                background: "#256199",
                padding: screenWidth > 1200 ? "5px 40px" : "10px 20px",
                borderRadius: "15px",
                marginTop: "30px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Sign In
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
