import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../src/theme";
import {
  DownloadOutlined,
  PrinterOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Download, Print, Edit } from "@mui/icons-material";
import { Space } from "antd";
import printIcon from "../../src/assets/img/print-icon.png";
import downloadIcon from "../../src/assets/img/download-icon.png";
import Cookies from "js-cookie";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { message } from "antd";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const BorrowerAgreements = () => {
  const [sortBy, setSortBy] = useState("default");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [generateAgreement, setGenerateAgreement] = useState([]);
  const [pollAdobe, setPollAdobe] = useState([]);
  const location = useLocation();
  const loan_id = location.state?.loan_id;
  const history = useHistory();
  const [signAgreement, setSignAgreement] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const updateSignStatus = async (loanId, date, type) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "PUT",
        headers: headers,
      };

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/agreement/adobeSign/updateSignStatus?loan_id=${loanId}&date=${date}&type=${type}`,
          options
        );

        if (!response.ok) {
          throw new Error("Failed to update sign status");
        }

        const data = await response.json();
        // Process the response data here
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAgreementEvent = async (loanId) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/agreement/adobeSign/getAgreementEvent?loan_id=${loanId}`,
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Assuming the response data contains date, loan_id and type
        const { date, loan_id, type } = data;
        updateSignStatus(loan_id, date, type);
      } catch (error) {
        console.error(error);
      }
    };

    if (document.referrer === "https://smart-lend.com/agreements") {
      console.log("User came from lender agreement screen!");
      const loanId = fetchAgreementEvent(loanId); // your loan id here
    } else {
      console.log("User came from somewhere else.");
    }
  }, []);

  useEffect(() => {
    async function scrollToLoanBox() {
      if (loan_id) {
        console.log("loan_id agreement", loan_id);

        const checkForLoanBox = setInterval(() => {
          const loanBox = document.querySelector(`#loan-${loan_id}`);
          if (loanBox) {
            clearInterval(checkForLoanBox);
            loanBox.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      }
    }

    scrollToLoanBox();
  }, [loan_id]);

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  async function handleSignAgreement(loan_id) {
    const loanId = loan_id;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/adobeSign/agreementSignUrls?loan_id=${loanId}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      // Redirect the user to the returned URL
      if (data["Sign lender URL"]) {
        window.location.href = data["Sign lender URL"];
      } else {
        console.error("URL not found in API response");
        return; // Exiting early since the URL isn't found.
      }

      // When the user comes back to our system, this will trigger
      window.onfocus = async function () {
        try {
          const eventResponse = await fetch(
            `https://www.smart-lend.com/api/agreement/adobeSign/getAgreementEvent?loan_id=${loanId}`,
            options
          );

          if (!eventResponse.ok) {
            throw new Error("Failed to fetch agreement event");
          }

          const eventData = await eventResponse.json();

          const eventResponse2 = await fetch(eventData.Url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: eventData.Authorization,
            },
          });

          if (!eventResponse2.ok) {
            throw new Error("Failed to fetch agreement event");
          }

          const eventData2 = await eventResponse2.json();

          const email = Cookies.get("email");

          // Find the event that matches the email and type criteria
          const matchingEvent = eventData2.events.find(
            (event) =>
              event.participantEmail === email &&
              event.type === "ACTION_COMPLETED_HOSTED"
          );

          if (!matchingEvent) {
            console.error(
              "Matching event not found based on email and type criteria."
            );
            return;
          }

          // Extract the date value
          const date = matchingEvent.date;
          console.log("date value", date);
          const formattedDate = formatDate(date);

          const eventResponse3 = await fetch(
            `https://www.smart-lend.com/api/agreement/adobeSign/updateSignStatus?loan_id=${loanId}&date=${formattedDate}&type=lender`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            }
          );

          if (!eventResponse3.ok) {
            throw new Error("eventResponse3 error");
          }

          const eventData3 = await eventResponse3.json();
          console.log("eventData3 value:", eventData3);
        } catch (error) {
          console.error("Error in onfocus:", error);
        }
      };
    } catch (error) {
      console.error("Error in handleSignAgreement:", error);
    }
  }

  async function handleDownloadClick(loan_id) {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/agreement/generateAgreement",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({ loan_id }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const agreementData = await response.json();
      const location = agreementData.location;

      setTimeout(async () => {
        const activityData = await fetchActivityLog(location, loan_id);
        setPollAdobe((prevActivityLog) => [...prevActivityLog, activityData]);
      }, 5000); // 5-second delay before running the pollAdobe API
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }

    // Fetch activity log data
    async function fetchActivityLog(location, loan_id) {
      console.log("location value", location);
      console.log("loan_id fetchActivityLog", loan_id);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/agreement/pollAdobe?location=${location}`,
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        await postDownloadFileAdobe(data.asset.downloadUri, loan_id);
        return data;
      } catch (error) {
        console.error(error);
      }
    }

    // POST to downloadFileAdobe API
    async function postDownloadFileAdobe(url, loan_id) {
      console.log("loan_id postDownloadFileAdobe", loan_id);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          url: url,
          loan_id: loan_id,
        }),
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/downloadFileAdobe",
          options
        );

        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }

        const data = await response.json();
        console.log("Download File Adobe response:", data);
      } catch (error) {
        console.error("Error fetching download file data:", error);
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/viewListAgreement",
          options
        );

        if (response.status === 403) {
          throw new Error("Document is not ready yet");
        }

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data["Accepted Loan"]);
      } catch (error) {
        console.error("Error fetching download file data:", error);
        setErrorMessage(error.message);
      }
    }
    fetchData();
  }, []);

  const getSignatureMessage = (item) => {
    if (
      item.agreement_id !== null &&
      item.lender_sign === 1 &&
      item.borrower_sign === 0
    ) {
      return "Waiting for your signature";
    } else if (
      item.agreement_id === null &&
      item.lender_sign === 0 &&
      item.borrower_sign === 0
    ) {
      return "Agreement are not generated yet";
    } else if (
      item.agreement_id !== null &&
      item.lender_sign === 0 &&
      item.borrower_sign === 0
    ) {
      return "Waiting for lender signature";
    } else if (
      item.agreement_id !== null &&
      item.lender_sign === 1 &&
      item.borrower_sign === 1
    ) {
      return "Agreement Processing";
    } else if (
      item.agreement_id === null &&
      item.lender_sign === 1 &&
      item.borrower_sign === 1
    ) {
      return "Agreement Approved";
    }
    return null; // return null if none of the conditions are met
  };

  const fetchLoanAgreement = async (loan_id, lender_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/getAgreementDocument?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading Agreement");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Agreement.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            marginLeft: "25px",
            height: "40px",
            borderRadius: "20px",
            marginRight: "10px",
          }}
        />
        <div>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "17px",
              color: "#142C44CC",
              flex: 1,
              marginTop: "3px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "200",
              fontSize: "11px",
              color: "#142C44CC",
              marginTop: "-9px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerAgreement = ({
    title,
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    title7,
  }) => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
            width: "140px",
          }}
        >
          {title}
        </h3>

        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: screenWidth < 800 ? "30px" : "40px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "45px",
          }}
        >
          {title1}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title2}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title3}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title4}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title5}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title6}
        </h3>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "#142C44",
            margin: "0px 0px 0px 0px",
            lineHeight: "18px",
          }}
        >
          {title7}
        </h3>
      </div>
    </div>
  );

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);

  const handleFileSelect = (event, loanId, lender_id) => {
    const file = event.target.files[0]; // Get the first selected file
    setFileUploading(true);

    if (file) {
      // Create a FormData object
      const formData = new FormData();
      formData.append("loan_id", loanId); // Replace loan_id with your actual loan ID
      formData.append("lender_id", lender_id); // Replace loan_id with your actual loan ID
      formData.append("agreement", file);

      // Send a POST request with FormData
      fetch("https://www.smart-lend.com/api/agreement/uploadAgreement", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            message.success(data.message);
          } else if (data.status === 400) {
            message.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading agreement file:", error);
        });
      console.log(data);
      const changingData = [...data];
      const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
      changingData[dataIndex].document = 1;
      setData(changingData);
      setFileUploading(false);
    }
  };

  return (
    <Box m="4px 20px 20px 20px">
      <Box
        gridColumn="span 8"
        overflow="auto"
        gridRow="span 2"
        height={isMobile ? "78vh":"88.7vh"}
        borderRadius="10px"
        style={{
          background:
            "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={isMobile ? "90%" : "60%"}
          margin="20px auto 20px auto"
          textAlign="left"
        >
          <Typography
            color={colors.grey[920]}
            variant="h5"
            fontWeight="800"
            fontFamily="Manrope, sans-serif"
            fontSize="20px"
            textAlign="left"
            flexGrow="1"
          >
            Loan Agreements
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "250px",
            }}
          >
            <label
              htmlFor="sort-by"
              style={{
                marginRight: "7px",
                marginLeft: "10px",
                color: "#142C44CC",
                fontWeight: "800",
                fontSize: "13px",
                fontFamily: "Manrope, sans-serif",
              }}
            >
              Sort by:
            </label>
            <div style={{ width: "60%" }}>
              <select
                id="sort-by"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  color: "#0E5AA5",
                  backgroundColor: "#ECF0F5",
                  border: "1px solid #3E536733",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                }}
              >
                <option value="default">Date & Time</option>
                <option value="loan-amount">Loan Amount</option>
                <option value="date">Date</option>
              </select>
            </div>
          </div>
        </Box>

        {data.length > 0 ? (
          data.map((item, index) => (
            <div
              key={index}
              id={`loan-${item.loan_id}`} // Id for redirect to box
              display="flex"
              width="100%"
              style={{
                marginTop: index > 0 ? "30px" : "0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(255,255,255,0.7)",
                  borderRadius: "10px",
                  width: isMobile ? "90%" : "60%",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  <ContainerAgreement
                    title="Loan Amount:"
                    title1={item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  />

                  <div
                    style={{
                      color: "white",
                      backgroundColor:
                        item.status === "pending"
                          ? "#B7C2CC"
                          : item.status === "ongoing"
                          ? "#CEBA12"
                          : item.status === "complete"
                          ? "#D6F2D8"
                          : item.status === "rejected"
                          ? "#DC9080"
                          : item.status === "approved"
                          ? "#759CBC"
                          : "#000000",
                      border: "1px solid transparent",
                      boxShadow: "none",
                      borderRadius: "10px",
                      width: "120px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    {item.status.charAt(0).toUpperCase() +
                      item.status.slice(1) || "-"}
                  </div>
                </div>
                <div
                  style={{
                    background: "white",
                    padding: "15px",
                    borderRadius: "10px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        title="Lender Name:"
                        title2={item.lender || "-"}
                      />
                    </div>

                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        title="Borrower Name:"
                        title3={
                          isMobile
                            ? item.borrower.length > 23
                              ? item.borrower.slice(0, 23) + "..."
                              : item.borrower
                            : item.borrower || "-"
                        }
                      />
                    </div>

                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        title="Monthly Payment:"
                        title4={
                          item.monthly_installment.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          }) || "-"
                        }
                      />
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        title="Interest Rate:"
                        title5={item.interest_rate + "%" || "-"}
                      />
                    </div>

                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        // title="Loan Period:" title6="18 Months"
                        title="Loan Period:"
                        title6={item.period + " Months" || "-"}
                      />
                    </div>

                    <div style={{ padding: "7px 0px" }}>
                      <ContainerAgreement
                        // title="Initial Payment Date:" title7="24th Feb 2023"
                        title="Initial Payment Date:"
                        title7="Pending"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                  }}
                >
                  {item.lender_sign ? (
                    <button
                      className="all_button"
                      onClick={() =>
                        fetchLoanAgreement(item.loan_id, item.lender_id)
                      }
                      style={{
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: "#0E5AA5",
                        color: "white",
                        padding: "7px 25px",
                        fontSize: "12px",
                      }}
                    >
                      Download Agreement
                    </button>
                  ) : null}
                  {item.lender_sign ? (
                    !item.borrower_uploaded_agreement ? (
                      <label
                        htmlFor={`fileInput${index}`}
                        className="all_button"
                        style={{
                          background: "#1557a9",
                          color: "white",
                          border: "none",
                          borderRadius: "10px",
                          padding: "7px 25px",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          marginLeft: "15px",
                        }}
                      >
                        {selectedFile ? "Send File" : "Upload Signed Agreement"}
                        <input
                          type="file"
                          id={`fileInput${index}`}
                          accept=".pdf"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileSelect(e, item.loan_id, item.lender_id)
                          }
                        />
                      </label>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              width: "100%",
              padding: "0px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "60%",
                padding: "0px 15px",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "10px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Manrope, sans-serif",
                fontSize: "0.8em",
                fontWeight: "600",
                color: "#00000066",
              }}
            >
              You have no loan request
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default BorrowerAgreements;
