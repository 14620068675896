import { Space, Table, Tag, Radio, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';







const TableBorrowerPayment = ({ onRowClick, onSelectionChange }) => {
  const [data, setData] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");


  const handleDownloadDocument = async (userId) => {
    try {
      const response = await fetch(`https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=delete`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get('token'),
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Refresh your table data here
      console.log("User deleted successfully");
  
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.smart-lend.com/api/payment/viewLatestRepay', {
          headers: {
            "Authorization": "Bearer " + Cookies.get('token'),
            "Accept": "application/json",
          }
        });
        const json = await response.json();
        setData(json['Latest Repay']);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [

 
    {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Borrower Name</span>,
        dataIndex: 'borrower_id',
        key: 'borrower_id',
        render: (text) => (
          <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{ '-'}</span>
        ),
      },
      {
        title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Date & Time</span>,
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => (
          <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text}` : '-'}</span>
        ),
      },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Amount</span>,
      dataIndex: 'repay_total_amount',
      key: 'repay_total_amount',
      render: (text) => (
        <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text.toLocaleString('en-US', { style: 'currency', currency: 'USD'})}` : '-'}</span>
      ),
    },
    {
      title: <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px", fontWeight: "600" }}>Status</span>,
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
         <span style={{ fontFamily: "Poppins, sans-serif", fontSize: "14px", fontWeight: "600" }}>{text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : '-'}</span>
         ),
    },
   
 
  ];

  return (<div>

    <Table
      rowKey="loan_id"
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 5 }}
      scroll={{
        x: 1000,
        
      }}
      onRow={(record) => ({
        onClick: (event) => {
          if (event.target.type !== "checkbox") {
            onRowClick(record);
          }
        },
      })}
      style={{
        borderRadius: '10px 10px 10px 10px',
        cursor: 'pointer',
        margin: screenWidth > 1200  ?'0px 20px' : "0px",
          width: screenWidth > 1200 ? 'auto' : '100%',
      }}
    />
  </div>)
};

export default TableBorrowerPayment;


