import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import TinyLineChart from "../../components/TinyLineChart";
import HalfPieChart from "../../components/HalfPieChart";
import { Progress, Avatar, Button, Upload, message } from "antd";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { format } from "date-fns";
import defaultIcon from "../../assets/img/Profile.png";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});


const props = {
  action: "https://smart-lend.com/accepted-loans",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};

const getInitials = (name) => {
  const textToSplit = name.split(" ");
  const initials =
    textToSplit?.[0]?.[0].toUpperCase() +
    (textToSplit?.[1]?.[0].toUpperCase() || "");
  return initials;
};

const ContainerActivity2 = ({ noData }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "12.5px",
      border: "1px solid none",
      borderRadius: "20px",
      backgroundColor: "#FFFFFFB3",
      height: "60px",
    }}
  >
    <p
      style={{
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "16px",
        color: "#142C44CC",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      {noData}
    </p>
  </div>
);

const AcceptedLoans = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [sortBy1, setSortBy1] = useState("all");
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const history = useHistory();
  const [fileList, setFileList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [confirmAgreementModal, setConfirmAgreementModal] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);
  const [messageForBorrower, setMessageForBorrower] = useState("");
  const [liveModalAgreementId, setLiveModalAgreementId] = useState("");

  const handleFileSelect = (event, loanId) => {
    const file = event.target.files[0]; // Get the first selected file
    setFileUploading(true);

    if (file) {
      // Create a FormData object
      const formData = new FormData();
      formData.append("loan_id", loanId); // Replace loan_id with your actual loan ID
      formData.append("agreement", file);

      // Send a POST request with FormData
      fetch("https://www.smart-lend.com/api/agreement/uploadAgreement", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status.ok) {
          } else if (data.status === 400) {
            message.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading agreement file:", error);
        });
      console.log(data);
      message.success("Agreement uploaded");
      const changingData = [...data];
      const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
      changingData[dataIndex].document = 1;
      setData(changingData);
      setFileUploading(false);
    }
  };

  const handleFileSelectSlip = async (event, loanId, title) => {
    const file = event.target.files[0]; // Get the first selected file
    setFileUploading(true);

    if (file) {
      // Create a FormData object
      const formData = new FormData();
      formData.append("loan_id", loanId); // Replace loan_id with your actual loan ID
      formData.append("receipt", file);

      // Send a POST request with FormData
      fetch("https://www.smart-lend.com/api/loan/uploadPaymentReceipt", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
          } else if (data.status === 400) {
            message.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading agreement file:", error);
        });
      console.log(data);
      message.success("Loan disbursement updated");
      const changingData = [...data];
      const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
      changingData[dataIndex].receipt = 1;
      setData(changingData);
      setFileUploading(false);
    }
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1 and pad with leading zero if needed
      const day = String(currentDate.getDate()).padStart(2, "0"); // Pad with leading zero if needed
      const loan_receive = `${year}-${month}-${day}`;
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/createRepay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            loan_id: loanId,
            loan_receive: loan_receive,
          }),
        }
      );

      if (response.status === 200) {
        console.log("Loan repayment generated");
        console.log(data);
        message.success("Repayment schedule generated for borrower");
        const changingData = [...data];
        const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
        changingData[dataIndex].status = "ongoing";
        setData(changingData);
        message.success(`Loan ${loanId} status has been updated to ongoing`);
        message.success(`Borrower ${title} has been updated.`);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  // Function to handle file upload
  const handleFileUpload = () => {
    // You can use the selectedFile state variable for further processing or API requests
    if (selectedFile) {
      console.log("Selected file:", selectedFile);
      // Perform your API request here
    } else {
      console.log("No file selected.");
    }
  };

  const agreeToAgreement = async () => {
    console.log("set Agree to agreement", liveModalAgreementId);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/updateBorrowerSign?loan_id=${liveModalAgreementId}&borrower_sign=1`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("borrowerAgreement marked as signed");
        const signedAgreementIndex = data.findIndex(
          (loan) => loan.loan_id === liveModalAgreementId
        );
        const changingData = [...data];
        changingData[signedAgreementIndex].borrower_sign = true;
        setData(changingData);
        setConfirmAgreementModal(false);
        setSomethingWrong(false);
        setMessageForBorrower("");
        setLiveModalAgreementId("");
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const amendAgreement = async () => {
    console.log("set disagree and reverse to agreement", liveModalAgreementId);
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/updateStatusNote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            loan_id: liveModalAgreementId,
            status_notes: messageForBorrower,
          }), // Convert the object to a JSON string
        }
      );

      if (response.status === 200) {
        console.log("borrowerAgreement marked as signed");
        const signedAgreementIndex = data.findIndex(
          (loan) => loan.loan_id === liveModalAgreementId
        );
        const changingData = [...data];
        changingData[signedAgreementIndex].borrower_uploaded_agreement = false;
        setData(changingData);
        setConfirmAgreementModal(false);
        setSomethingWrong(false);
        setMessageForBorrower("");
        setLiveModalAgreementId("");
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    index,
    loanId,
    borrowerId,
    lenderId,
    borrowerNotSigned,
    documentExist,
    previousDocumentExist,
    createdAt,
    remainingPeriod,
    outstandingAmount,
    item,
  }) => {
    const shouldAddMarginTop = index > 3;
    const history = useHistory();

    const viewAgreement = (loanId, event) => {
      console.log("loanId viewAgreement", loanId);
      event.preventDefault();
      history.push("/agreements", { loanId: loanId });
    };
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 10px)",
            textAlign: "center",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: screenWidth > 1200 ? "40vh" : "auto",
            padding: "15px",
            position: "relative",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "55px",
                  height: "55px",
                  borderRadius: "50%",
                  background: "rgb(140,140,170)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "white",
                  fontSize: 20,
                  fontWeight: 600,
                  position: "relative",
                }}
                onClick={() =>{
                  console.log(item);
                  setBadgeVerified(item.badge_verified)
                  const dataToPush = [
                    {
                      name: JSON.parse(item.singpass_data)?.person?.name?.value,
                      cbs_data: item.credit_report_data,
                    },
                  ];
                  for (const dat of item.loan_authorizations) {
                    dataToPush.push({
                      name: dat.director_name,
                      cbs_data: dat.credit_report_data,
                    });
                  }
                  console.log(dataToPush);
                  setAllCBSData(dataToPush);
                  singpassDetails(
                    item.borrower_id,
                    item.authorization_required,
                    item.loan_id,
                    item.keyman
                  )}
                }
              >
                {title ? getInitials(title) : "SL"}
                {item.badge_verified ? (
                  <div
                    className="tooltip-container"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgb(14, 90, 165)",
                    }}
                  >
                    <LocalPoliceIcon
                      className="tooltip-target"
                      style={{ color: "white", fontSize: "14px" }}
                    />
                    <span className="tooltip-text">
                      Verified borrowers have undergone SmartLend's
                      authentication process, demonstrating legitimate business
                      operations. This includes providing evidence such as
                      current tenancy agreements, business premises signage, or
                      recent invoices matching their bank statements
                    </span>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  width: "calc(100% - 70px)",
                }}
              >
                <h3
                  style={{
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    color: "#142C44CC",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {isMobile
                    ? title.length > 23
                      ? title.slice(0, 23) + "..."
                      : title
                    : title}
                </h3>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {item.badge_verified ? subtitle : null}
                </p>
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description}
              </p>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Disburstment Amount
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {parseFloat(
                  item.amount * (1 - item.processing_fee)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "SGD",
                })}
              </p>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Processing Fee
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {parseFloat(item.amount * item.processing_fee).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "SGD",
                  }
                )}
              </p>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label2}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description2}
              </p>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Remaining Period
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {remainingPeriod + " Months"}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Outstanding Amount
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {outstandingAmount}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Applied on
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {formatDate(createdAt)}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                {label3}
              </p>
            </div>
          </div>
          <div style={{ margin: "10px 0px 0px 0px" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                padding: "6px 0px",
                fontSize: "12px",
                margin: "0px",
                color: "white",
                width: "100%",
                marginTop: "10px",
                textAlign: "center",
                borderRadius: "30px",
                marginBottom: "10px",
                background:
                  description3 === "Review"
                    ? "grey"
                    : description3 === "Ongoing"
                    ? "#E7D011"
                    : description3 === "Complete"
                    ? "#139F2ACC"
                    : description3 === "Rejected"
                    ? "red"
                    : description3 === "Approved"
                    ? "0875DF"
                    : "rgba(0,0,0,0.5)",
              }}
            >
              {description3 === "Ongoing"
                ? "On-going Loan"
                : item
                ? item.receipt
                  ? "Pending Validation"
                  : description3
                : description3}
            </p>
            {borrowerNotSigned ? (
              item.receipt ? (
                <div style={{ width: "100%" }}>
                  {description3 !== "Review" && (
                    <div>
                      {description3 !== "Review" && (
                        <div
                          onClick={() => fetchReceipt(loanId, item.lender_id)}
                          className="all_button"
                          style={{
                            background: "transparent",
                            color: "grey",
                            width: "100%",
                            border: "2px solid lightgrey",
                            borderRadius: "50px",
                            padding: "4px 0px",
                            fontSize: "12px",
                            margin: "0px 0px 0px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          View Transfer Slip
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  {description3 !== "Review" && (
                    <div>
                      {description3 !== "Review" && (
                        <label
                          onClick={() => console.log(loanId)}
                          htmlFor={`fileInputSlip${index}`}
                          className="all_button"
                          style={{
                            background: "transparent",
                            color: "grey",
                            width: "100%",
                            border: "2px solid lightgrey",
                            borderRadius: "50px",
                            padding: "4px 0px",
                            fontSize: "12px",
                            margin: "0px 0px 0px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          Upload Fund Transfer
                          <input
                            type="file"
                            id={`fileInputSlip${index}`}
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleFileSelectSlip(e, loanId, title)
                            }
                          />
                        </label>
                      )}
                    </div>
                  )}
                </div>
              )
            ) : null}
            {documentExist ? (
              <div>
                {description3 !== "Review" && (
                  <>
                    <button
                      onClick={() => fetchLoanAgreement(loanId, item.lender_id)}
                      className="all_button"
                      style={{
                        background: "#1557a9",
                        color: "white",
                        width: "100%",
                        border: "none",
                        borderRadius: "50px",
                        padding: "6px 0px",
                        fontSize: "12px",
                        margin: "10px 0px 0px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.borrower_uploaded_agreement
                        ? "View Signed Agreement"
                        : "View Agreement"}
                    </button>

                    {item.borrower_sign ? null : item.borrower_uploaded_agreement ? (
                      <button
                        onClick={() => {
                          setLiveModalAgreementId(loanId);
                          setConfirmAgreementModal(true);
                        }}
                        className="all_button"
                        style={{
                          background: "transparent",
                          color: "grey",
                          width: "100%",
                          border: "2px solid lightgrey",
                          borderRadius: "50px",
                          padding: "4px 0px",
                          fontSize: "12px",
                          margin: "10px 0px 0px 0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        Approve Agreement
                      </button>
                    ) : null}
                  </>
                )}
              </div>
            ) : borrowerNotSigned ? null : previousDocumentExist ? (
              <div>
                {description3 !== "Review" && (
                  <label
                    onClick={() => console.log(loanId)}
                    htmlFor={`fileInput${index}`}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {selectedFile ? "Send File" : "Upload Agreement"}
                    <input
                      type="file"
                      id={`fileInput${index}`}
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, loanId)}
                    />
                  </label>
                )}
              </div>
            ) : (
              <div>
                {description3 !== "Review" && (
                  <label
                    onClick={() => console.log(loanId)}
                    htmlFor={`fileInput${index}`}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {selectedFile ? "Send File" : "Upload Agreement"}
                    <input
                      type="file"
                      id={`fileInput${index}`}
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, loanId)}
                    />
                  </label>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleUpload = async (loanId, borrowerId, lenderId) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("image", file.originFileObj); // Use the original File instance
    });
    formData.append("loan_id", loanId.toString()); // Convert the loanId to string

    console.log("value form data", formData);

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/uploadPaymentReceipt",
        {
          method: "POST",
          headers: headers,
          body: formData,
        }
      );

      if (response.status === 200) {
        try {
          let item = {
            user_id: borrowerId,
            channel: "system",
            subject: "Fund Transfer",
            message: `Lender ${lenderId} has made a fund transfer for the loan ${loanId}`,
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("File uploaded:", data);
        } catch (error) {
          console.error("Error uploading file:", error);
        }

        try {
          let item = {
            user_id: "A00001",
            channel: "system",
            subject: "Fund Transfer",
            message: `Lender ${lenderId} has made a fund transfer for the loan ${loanId}`,
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("File uploaded:", data);
        } catch (error) {
          console.error("Error uploading file:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog?description=You had upload a fund transfer for the loan ${loanId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );
          const data = await response.json();
          console.log("File uploaded:", data);
        } catch (error) {
          console.error("rror uploading file:", error);
        }
      } else if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      setFileList([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewAcceptedLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const parsedData = data["Accepted Loan"].map((item)=> {
        
          const updatedItem = {
            ...item,
            credit_report_data: JSON.parse(item.credit_report_data),
          };
  
          // Parse credit_report_data in loan_authorizations array
          const updatedLoanAuthorizations = item.loan_authorizations.map(
            (authorization) => ({
              ...authorization,
              credit_report_data: JSON.parse(authorization.credit_report_data),
            })
          );
  
          // Update the item with the updated loan_authorizations array
          updatedItem.loan_authorizations = updatedLoanAuthorizations;
  
          return updatedItem;
        })
        setData(parsedData);
      } catch (error) {
        console.error(error);
      }
    }

    // call the fetchData function
    fetchData();
  }, []);

  const fetchLoanAgreement = async (loan_id, lender_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/getAgreementDocument?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading Agreement");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Agreement.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const fetchReceipt = async (loan_id, lender_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewPaymentReceipt?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading receipt");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "receipt.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        const reversedData = data.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: screenWidth > 1200 ? "200px" : "160px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dppicture, setdppicture] = useState([]);

  const fillAllLenderPpicture = async (index, lenderId) => {
    // try {
    //   const response = await fetch(
    //     `https://www.smart-lend.com/api/user/getLenderPicture?lender_id=${lenderId}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Cookies.get("token")}`,
    //       },
    //     }
    //   );
    //   const blob = await response.blob();
    //   const imageUrl = URL.createObjectURL(blob);
    //   setdppicture((prevDppicture) => {
    //     const updatedDppicture = [...prevDppicture]; // Create a copy of the array
    //     updatedDppicture[index] = { profilepicture: imageUrl }; // Update the specific index
    //     console.log(updatedDppicture);
    //     return updatedDppicture;
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      data
        .slice(0, 6)
        .sort((a, b) => {
          if (sortBy === "loan-amount") {
            return a.offer_amount - b.offer_amount;
          } else if (sortBy === "date") {
            return new Date(a.created_at) - new Date(b.created_at);
          } else {
            // Default sorting option
            return new Date(b.created_at) - new Date(a.created_at);
          }
        })
        .map((item, index) => {
          console.log(item);
          fillAllLenderPpicture(index, item.borrower_id);
        });
    }
  }, [data, sortBy]);
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return [];
    }
  };

  return (
    <Box m="4px 20px 20px 20px" style={{ position: "relative" }}>
    <Modal
      width={isMobile ? "100%" : "60%"}
      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
      open={dataSingpassModal}
      onCancel={() => {
        setDataSingpassModal(false);
        setSingpassData(null);
        setOpenDataIndex(null);
        setKeyman("");
      }}
      footer={null}
    >
      {singpassData ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "20px",
              color: "rgba(20, 44, 68, 0.8)",
              fontWeight: 900,
              fontFamily: "Manrope, sans-serif",
              fontSize: 20,
            }}
          >
            <h2> Borrower information</h2>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                background: "rgba(14, 90, 165, 0.1)",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 800,
                fontFamily: "Manrope, sans-serif",
                fontSize: 16,
                padding: "3px 15px",
                borderRadius: 7,
              }}
            >
              Loan keyman: {keyman ? keyman : ""}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              borderTop: "1px solid lightgrey",
              borderBottom: "1px solid lightgrey",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 25,
              gap: "20px",
              padding: "10px 0px",
              overflowX: isMobile ? "auto" : "hidden",
            }}
          >
            <div
              className="all_button"
              style={{
                cursor: "pointer",
                color:
                  viewingUser === 0
                    ? "rgba(20, 44, 68, 0.8)"
                    : "rgba(0,0,0,0.4)",
                fontSize: viewingUser === 0 ? "14px" : "12px",
                fontWeight: viewingUser === 0 ? "800" : "400",
                transition: "0.3s all ease-in-out",
              }}
              onClick={() => {
                setViewingUser(0);
                console.log(singpassData);
                setLiveUserData(singpassData);
              }}
            >
              {singpassData?.person?.name?.value !== undefined
                ? singpassData?.person?.name?.value
                : null}
            </div>

            {otherAuthoriserDetails
              ? otherAuthoriserDetails.map((aut, index) => (
                  <div
                    className="all_button"
                    style={{
                      cursor: "pointer",
                      color:
                        viewingUser === index + 1
                          ? "rgba(20, 44, 68, 0.8)"
                          : "rgba(0,0,0,0.4)",
                      fontSize: viewingUser === index + 1 ? "14px" : "12px",
                      fontWeight: viewingUser === index + 1 ? "800" : "400",
                      transition: "0.3s all ease-in-out",
                    }}
                    onClick={() => {
                      setViewingUser(index + 1);
                      console.log(JSON.parse(aut.singpass_data));
                      setLiveUserData(JSON.parse(aut.singpass_data));
                    }}
                    key={index}
                  >
                    {aut.director_name}
                  </div>
                ))
              : null}
          </div>
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid lightgrey",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
              padding: "10px 0px",
              width: "100%",
              overflowX: isMobile ? "auto" : "hidden",
            }}
          >
            {availableTab.map((view, index) => (
              <div
                onClick={() => {
                  setViewingTab(index);
                }}
                className="all_button"
                style={{
                  cursor: "pointer",
                  color:
                    viewingTab === index
                      ? "rgba(20, 44, 68, 0.8)"
                      : "rgba(0,0,0,0.4)",
                  fontSize: viewingTab === index ? "13px" : "12px",
                  fontWeight: viewingTab === index ? "700" : "400",
                  transition: "0.3s all ease-in-out",
                }}
                key={index}
              >
                {view.name}
              </div>
            ))}
          </div>
          <div
            style={{
              padding: "15px 0px",
              overflowX: isMobile ? "auto" : "hidden",
            }}
          >
            {liveUserData ? (
              viewingTab === 0 ? (
                <div
                  style={{
                    padding: "15px 15px",
                    borderRadius: "8px",
                    width: "100%",
                    background: "rgba(14, 90, 165, 0.1)",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2px 20px",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      NRIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        min={0}
                        // className={nricError ? "error-border" : ""}
                        value={liveUserData?.person?.uinfin?.value}
                      />
                      {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Full Name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        value={liveUserData?.person?.name?.value}
                        // required
                      />
                      {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Sex
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        value={liveUserData?.person?.sex?.desc}
                        // required
                      />
                      {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Race
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.race?.desc}
                      />
                      {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Nationality
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.nationality?.desc}
                      />
                      {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Date of Birth
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={liveUserData?.person?.dob?.value}
                      />
                      {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Country of Birth
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.birthcountry?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Residential Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.residentialstatus?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.passtype?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.passstatus?.value}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass Expiry Date
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={liveUserData?.person?.passexpirydate?.value}
                      />
                      {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Ownership of Private Property Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.ownerprivate?.value}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Email
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        type="email"
                        disabled
                        // required
                        value={liveUserData?.person?.email?.value}
                      />
                      {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Mobile Number
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="number"
                        // required
                        value={liveUserData?.person?.mobileno?.nbr?.value}
                      />
                      {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registered Address
                    </div>
                    <div style={{ width: "60%" }}>
                      <textarea
                        rows="2"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        // required
                        value={
                          liveUserData?.person?.regadd
                            ? (liveUserData?.person?.regadd?.unit?.value !==
                              null
                                ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.floor?.value !==
                              null
                                ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.block?.value !==
                              null
                                ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.street?.value !==
                              null
                                ? `${liveUserData?.person?.regadd?.street?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.postal?.value !==
                              null
                                ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.country?.desc !==
                              null
                                ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                : "")
                            : ""
                        }
                      ></textarea>
                      {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Housing Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.housingtype?.desc}
                      />
                      {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Marital Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        value={liveUserData?.person?.marital?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Occupation
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.occupation?.value}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Employer's name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={""}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Employment Sector
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={""}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.hdbtype?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Housing Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.housingtype?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Ownership - Address
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.person?.regadd
                            ? (liveUserData?.person?.regadd?.unit?.value !==
                              null
                                ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.floor?.value !==
                              null
                                ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.block?.value !==
                              null
                                ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.street?.value !==
                              null
                                ? `${liveUserData?.person?.regadd?.street?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.postal?.value !==
                              null
                                ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                : "") +
                              (liveUserData?.person?.regadd?.country?.desc !==
                              null
                                ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                : "")
                            : ""
                        }
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Ownership - Outstanding HDB Loan Balance
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.hdbownership[0]?.outstandingloanbalance?.value?.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Ownership - Type of HDB Dwelling
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.person?.hdbownership[0]?.hdbtype?.desc
                        }
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Ownership - Monthly Loan Installment
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.hdbownership[0]?.monthlyloaninstalment?.value?.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "SGD",
                          }
                        )}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Vehicles - Vehicles Model
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.person?.vehicles[0]?.model?.value
                        }
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Vehicles - Vehicles Make
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={liveUserData?.person?.vehicles[0]?.make?.value}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                </div>
              ) : viewingTab === 1 ? (
                <div
                  style={{
                    padding: "15px 15px",
                    borderRadius: "8px",
                    width: "100%",
                    background: "rgba(14, 90, 165, 0.1)",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2px 20px",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      UEN
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"]?.uen?.value
                        }
                      />
                      {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"]["entity-name"]
                            ?.value
                        }
                      />
                      {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"]["entity-type"]
                            ?.value
                        }
                      />
                      {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"][
                            "entity-status"
                          ]?.value
                        }
                      />
                      {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registered Address
                    </div>
                    <div style={{ width: "60%" }}>
                      <textarea
                        rows="2"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                          resize: "none",
                        }}
                        disabled
                        // required
                        value={
                          liveUserData?.entity?.addresses["addresses-list"][0]
                            ? (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.unit?.value !== undefined
                                ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                : "") +
                              (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.floor?.value !== undefined
                                ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                : "") +
                              (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.block?.value !== undefined
                                ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                : "") +
                              (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.street?.value !== undefined
                                ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                : "") +
                              (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.postal?.value !== undefined
                                ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                : "") +
                              (liveUserData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.country?.desc !== undefined
                                ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                : "")
                            : ""
                        }
                      ></textarea>
                      {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registration Date
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"][
                            "registration-date"
                          ]?.value
                        }
                      />
                      {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Primary SSIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"][
                            "primary-activity"
                          ]?.desc
                        }
                      />
                      {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Secondary SSIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          liveUserData?.entity["basic-profile"][
                            "secondary-activity"
                          ]?.desc
                        }
                      />
                      {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                    </div>
                  </div>
                  {liveUserData?.entity?.appointments[
                    "appointments-list"
                  ]?.map((appointment, index) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "2px 20px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          Appointment {index + 1} - Position
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            value={appointment.position.desc}
                            // Add onChange logic if needed
                          />
                        </div>
                      </div>
                      {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          Appointment {index + 1} - NRIC
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            value={appointment["person-reference"].idno.value}
                            // Add onChange logic if needed
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          Appointment {index + 1} - Full Name
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            value={
                              appointment["person-reference"]["person-name"]
                                .value
                            }
                            // Add onChange logic if needed
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          Appointment {index + 1} - Appointment Date
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="date"
                            value={appointment["appointment-date"].value}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : viewingTab === 2 ? (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderRadius: "10px",
                    overflow: "hidden",
                    padding: "10px",
                    borderSpacing: "0px",
                    background: "#0e5aa51A",
                  }}
                >
                  <thead
                    style={{
                      borderRadius: "15px 15px 0px 0px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    <tr
                      style={{
                        border: "none",
                        borderRadius: "8px",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 12,
                        borderRadius: 8,
                      }}
                    >
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Category
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Employment
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Interest
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Rent
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Tax Clearance
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Trade
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Years of Assessment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUserData?.person?.noahistory?.noas?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.amount?.value?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.category?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.employment?.value?.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.interest?.value?.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.rent?.value?.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.taxclearance?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.trade?.value?.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.yearofassessment?.value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : viewingTab === 3 ? (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderRadius: "10px",
                    overflow: "hidden",
                    padding: "10px",
                    borderSpacing: "0px",
                    background: "#0e5aa51A",
                  }}
                >
                  <thead
                    style={{
                      borderRadius: "15px 15px 0px 0px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    <tr
                      style={{
                        border: "none",
                        borderRadius: "8px",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 12,
                        borderRadius: 8,
                      }}
                    >
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Capital Type
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Currency
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Issued Capital Amount
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Paid Up Capital Amount
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Share Alloted Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUserData?.entity?.capitals["capitals-list"]?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["capital-type"]?.desc}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.currency?.desc}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.[
                              "issued-capital-amount"
                            ]?.value?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.[
                              "paid-up-capital-amount"
                            ]?.value?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.[
                              "share-allotted-amount"
                            ]?.value?.toLocaleString("en-US")}
                          </td>
                          {/* Add other data cells */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : viewingTab === 4 ? (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderRadius: "10px",
                    overflow: "hidden",
                    padding: "10px",
                    borderSpacing: "0px",
                    background: "#0e5aa51A",
                  }}
                >
                  <thead
                    style={{
                      borderRadius: "15px 15px 0px 0px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    <tr
                      style={{
                        border: "none",
                        borderRadius: "8px",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 12,
                        borderRadius: 8,
                      }}
                    >
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Company Profit Loss After Tax
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Company Profit Loss Before Tax
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Company Revenue
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Currency
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Current Period End Date
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Current Period Start Date
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Group Capital Paid Up Capital Amount
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Group Profit Loss After Tax
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Group Profit Loss Before Tax
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Group Revenue
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Is Audited
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUserData?.entity?.financials?.highlights?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["company-profit-loss-after-tax"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["company-profit-loss-before-tax"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["company-revenue"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.currency?.desc}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["current-period-end-date"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["current-period-start-date"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {
                              item?.["group-capital-paid-up-capital-amount"]
                                ?.value
                            }
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["group-profit-loss-after-tax"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["group-profit-loss-before-tax"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["group-revenue"]?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["is-audited"]?.value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : viewingTab === 5 ? (
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderRadius: "10px",
                    overflow: "hidden",
                    padding: "10px",
                    borderSpacing: "0px",
                    background: "#0e5aa51A",
                  }}
                >
                  <thead
                    style={{
                      borderRadius: "15px 15px 0px 0px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    <tr
                      style={{
                        border: "none",
                        borderRadius: "8px",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 12,
                        borderRadius: 8,
                      }}
                    >
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Allocation
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Category
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        NRIC
                      </th>
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Currency
                      </th>
                      {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                      <th
                        style={{
                          padding: "5px 10px",
                        }}
                      >
                        Share Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUserData?.entity?.shareholders[
                      "shareholders-list"
                    ] ? (
                      liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ]?.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.allocation?.value?.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.category?.desc}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {
                              item?.["person-reference"]?.["person-name"]
                                ?.value
                            }
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["person-reference"]?.idno?.value}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.currency?.desc}
                          </td>
                          {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item?.["share-type"]?.desc}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            textAlign: "center",
                            padding: "5px 10px",
                          }}
                          colSpan="11"
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <PDFViewer style={{ width: "100%", height: "80vh" }}>
                    <Document>
                      <Page size="A4">
                        {/**Letterhead section */}
                        <View
                          style={{
                            paddingVertical: 15,
                          }}
                        >
                          <View
                            style={{
                              paddingHorizontal: 40,
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: 10,
                              // borderBottom: 1,
                              // borderBottomColor: "grey",
                              paddingBottom: 10,
                            }}
                          >
                            <View
                              style={{
                                padding: 8,
                                borderWidth: 3,
                                borderColor: "#2881c4",
                                borderRadius: "50%",
                                marginTop: 15,
                              }}
                            >
                              <Image
                                source={logo}
                                style={{ width: 50, objectFit: "contain" }}
                              />
                            </View>
                            <View>
                              <Text
                                style={{
                                  fontSize: 18,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                SmartLend Financial Pte. Ltd.
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 4,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: -3,
                                }}
                              >
                                <Text style={{ fontSize: 7 }}>
                                  UEN: 202327568G
                                </Text>
                                <Text style={{ fontSize: 7 }}>
                                  Tel: +603-12345678
                                </Text>
                                <Text style={{ fontSize: 7 }}>
                                  Email: support@smart-lend.com
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: "lightgrey",
                              height: 2,
                              width: "100%",
                              marginTop: 20,
                            }}
                          />
                          <View
                            style={{
                              backgroundColor: "#2881c4",
                              height: 4,
                              width: "76%",
                              marginTop: -3,
                              marginLeft: "12%",
                              marginBottom: 15,
                            }}
                          />
                          <View
                            style={{ marginTop: 15, paddingHorizontal: 40 }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                Company Profile
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                padding: 8,
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                              }}
                            >
                              <View style={{ width: "50%" }}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Entity Name:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity["basic-profile"][
                                        "entity-name"
                                      ]?.value
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    UEN:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity["basic-profile"]
                                        ?.uen?.value
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Registration Date:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity?.[
                                        "basic-profile"
                                      ]?.["registration-date"]?.value
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Primary Activity:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity["basic-profile"][
                                        "primary-activity"
                                      ]?.desc
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Ownership:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity?.[
                                        "basic-profile"
                                      ]?.["ownership"]?.desc
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Entity Status:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity?.[
                                        "basic-profile"
                                      ]?.["entity-status"]?.value
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Business Constitution:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "65%" }}>
                                    {
                                      singpassData?.entity?.[
                                        "basic-profile"
                                      ]?.["company-type"]?.desc
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{ flexDirection: "row", gap: 10 }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Business Verification By Smartlend:
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                  {badgeVerified ? "Verified" : "Un-verified"}
                                  </Text>
                                </View>
                              </View>
                              <View style={{ width: "50%" }}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    ACRA Address:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {singpassData?.entity?.addresses[
                                      "addresses-list"
                                    ][0]
                                      ? (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.unit?.value !== undefined
                                          ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                          : "") +
                                        (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.floor?.value !== undefined
                                          ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                          : "") +
                                        (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.block?.value !== undefined
                                          ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                          : "") +
                                        (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.street?.value !== undefined
                                          ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                          : "") +
                                        (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.postal?.value !== undefined
                                          ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                          : "") +
                                        (singpassData?.entity?.addresses?.[
                                          "addresses-list"
                                        ]?.[0]?.country?.desc !== undefined
                                          ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                          : "")
                                      : ""}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Capital Type:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {
                                      singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.["capital-type"]?.desc
                                    }
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Paid Up Capital:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {singpassData?.entity?.["capitals"]?.[
                                      "capitals-list"
                                    ]?.[0]?.[
                                      "paid-up-capital-amount"
                                    ]?.value?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "SGD",
                                    })}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Share Allocated Amount:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {singpassData?.entity?.["capitals"]?.[
                                      "capitals-list"
                                    ]?.[0]?.[
                                      "share-allotted-amount"
                                    ]?.value?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "SGD",
                                    })}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Issued Capital Amount:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {singpassData?.entity?.["capitals"]?.[
                                      "capitals-list"
                                    ]?.[0]?.[
                                      "issued-capital-amount"
                                    ]?.value?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "SGD",
                                    })}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Keyman Email:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {keyman
                                      ? singpassData?.entity?.shareholders?.[
                                          "shareholders-list"
                                        ].filter(
                                          (holder) =>
                                            holder?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value === keyman
                                        )?.[0]?.["corppass-email"]?.value
                                      : null}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 10,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Keyman Mobile:
                                  </Text>
                                  <Text style={{ fontSize: 7, width: "60%" }}>
                                    {keyman
                                      ? singpassData?.entity?.shareholders?.[
                                          "shareholders-list"
                                        ].filter(
                                          (holder) =>
                                            holder?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value === keyman
                                        )?.[0]?.["corppass-mobileno"]?.value
                                      : null}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{ marginTop: 15, paddingHorizontal: 40 }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                Financial Highlight
                              </Text>
                            </View>
                            <View
                              style={{
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#c7dcec",
                                  opacity: 0.7,
                                  padding: 4,
                                }}
                              >
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Revenue</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Profit pre-tax</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Profit post-tax</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Start date</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>End date</Text>
                                </View>
                              </View>
                              {singpassData?.entity?.financials?.highlights?.map(
                                (data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                    }}
                                  >
                                    <View
                                      style={{ width: "19%", fontSize: 7 }}
                                    >
                                      <Text>
                                        {data?.[
                                          "company-revenue"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{ width: "19%", fontSize: 7 }}
                                    >
                                      <Text>
                                        {data?.[
                                          "company-profit-loss-before-tax"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{ width: "19%", fontSize: 7 }}
                                    >
                                      <Text>
                                        {data?.[
                                          "company-profit-loss-after-tax"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={{ width: "19%", fontSize: 7 }}
                                    >
                                      <Text>
                                        {
                                          data?.["current-period-start-date"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{ width: "19%", fontSize: 7 }}
                                    >
                                      <Text>
                                        {
                                          data?.["current-period-end-date"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                )
                              )}
                            </View>
                          </View>
                          <View
                            style={{ marginTop: 15, paddingHorizontal: 40 }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                Grants
                              </Text>
                            </View>
                            <View
                              style={{
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#c7dcec",
                                  opacity: 0.7,
                                  padding: 4,
                                  gap: 10,
                                }}
                              >
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Category</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Functional Area</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Grant Type</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Grant Status</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Approved Amount</Text>
                                </View>
                              </View>
                              {singpassData?.entity?.grants?.[
                                "grants-list"
                              ]?.map((data, index) => (
                                <View
                                  key={index}
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor:
                                      index % 2
                                        ? "rgba(199, 220, 236, 0.2)"
                                        : "transparent",
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>
                                      {data?.["development-category"]?.desc}
                                    </Text>
                                  </View>
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>
                                      {data?.["functional-area"]?.desc}
                                    </Text>
                                  </View>
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>{data?.["grant-type"]?.desc}</Text>
                                  </View>
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>
                                      {data?.["grant-status"]?.desc}
                                    </Text>
                                  </View>
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>
                                      {data?.[
                                        "approved-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                </View>
                              ))}
                            </View>
                          </View>
                          <View
                            style={{ marginTop: 15, paddingHorizontal: 40 }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                Officer(S) / Owners
                              </Text>
                            </View>
                            <View
                              style={{
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#c7dcec",
                                  opacity: 0.7,
                                  padding: 4,
                                  gap: 10,
                                }}
                              >
                                <View
                                  style={{
                                    width: "25%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Officer Name</Text>
                                </View>
                                <View
                                  style={{
                                    width: "13%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Identity No.</Text>
                                </View>
                                <View
                                  style={{
                                    width: "13%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Position</Text>
                                </View>
                                <View
                                  style={{
                                    width: "13%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Appointment Date</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Nationality</Text>
                                </View>
                              </View>
                              {singpassData?.entity?.appointments?.[
                                "appointments-list"
                              ]?.map((data, index) => (
                                <View
                                  key={index}
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor:
                                      index % 2
                                        ? "rgba(199, 220, 236, 0.2)"
                                        : "transparent",
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View style={{ width: "25%", fontSize: 7 }}>
                                    <Text>
                                      {
                                        data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View style={{ width: "13%", fontSize: 7 }}>
                                    <Text>
                                      {
                                        data?.["person-reference"]?.["idno"]
                                          ?.value
                                      }
                                    </Text>
                                  </View>
                                  <View style={{ width: "13%", fontSize: 7 }}>
                                    <Text>{data?.["position"]?.desc}</Text>
                                  </View>
                                  <View style={{ width: "13%", fontSize: 7 }}>
                                    <Text>
                                      {data?.["appointment-date"]?.value}
                                    </Text>
                                  </View>
                                  <View style={{ width: "19%", fontSize: 7 }}>
                                    <Text>
                                      {
                                        data?.["person-reference"]?.[
                                          "nationality"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                </View>
                              ))}
                            </View>
                          </View>
                          <View
                            style={{ marginTop: 15, paddingHorizontal: 40 }}
                          >
                            <View
                              style={{
                                backgroundColor: "#c7dcec",
                                alignItems: "center",
                                padding: 6,
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                }}
                              >
                                Shareholders
                              </Text>
                            </View>
                            <View
                              style={{
                                borderColor: "#c7dcec",
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderBottomWidth: 2,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#c7dcec",
                                  opacity: 0.7,
                                  padding: 4,
                                  gap: 10,
                                }}
                              >
                                <View
                                  style={{
                                    width: "25%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Shareholder Name / Address</Text>
                                </View>
                                <View
                                  style={{
                                    width: "13%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Identity No.</Text>
                                </View>
                                <View
                                  style={{
                                    width: "18%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Share Type</Text>
                                </View>
                                <View
                                  style={{
                                    width: "13%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>No of Share</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Nationality</Text>
                                </View>
                                <View
                                  style={{
                                    width: "15%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Date of birth</Text>
                                </View>
                                <View
                                  style={{
                                    width: "19%",
                                    fontSize: 7,
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <Text>Corppass Contact</Text>
                                </View>
                              </View>
                              {singpassData?.entity?.shareholders?.[
                                "shareholders-list"
                              ]?.map((data, index) => (
                                <View
                                  key={index}
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor:
                                      index % 2
                                        ? "rgba(199, 220, 236, 0.2)"
                                        : "transparent",
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>
                                      {data?.["person-reference"]?.[
                                        "person-name"
                                      ]?.value
                                        ? data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value +
                                          " (" +
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.sex?.code +
                                          ")"
                                        : data?.["entity-reference"]?.[
                                            "entity-name"
                                          ]?.value + " (Corporate)"}
                                    </Text>
                                    <Text>
                                      {getShareholderAddress(
                                        data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                      )?.[0]?.person?.regadd
                                        ? (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.unit
                                            ?.value !== null
                                            ? `Unit ${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd?.unit
                                                  ?.value
                                              }, `
                                            : "") +
                                          (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.floor
                                            ?.value !== null
                                            ? `Floor ${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd?.floor
                                                  ?.value
                                              }, `
                                            : "") +
                                          (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.block
                                            ?.value !== null
                                            ? `Block ${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd?.block
                                                  ?.value
                                              }, `
                                            : "") +
                                          (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.street
                                            ?.value !== null
                                            ? `${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd?.street
                                                  ?.value
                                              }, `
                                            : "") +
                                          (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.postal
                                            ?.value !== null
                                            ? `${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd?.postal
                                                  ?.value
                                              }, `
                                            : "") +
                                          (getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.regadd?.country
                                            ?.desc !== null
                                            ? `${
                                                getShareholderAddress(
                                                  data?.[
                                                    "person-reference"
                                                  ]?.["person-name"]?.value
                                                )?.[0]?.person?.regadd
                                                  ?.country?.desc
                                              }.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>
                                      {data?.["person-reference"]?.["idno"]
                                        ?.value
                                        ? data?.["person-reference"]?.["idno"]
                                            ?.value
                                        : data?.["entity-reference"]?.["uen"]
                                            ?.value + " (UEN)"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>{data?.["share-type"]?.desc}</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>{data?.["allocation"]?.value}</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>
                                      {
                                        data?.["person-reference"]?.[
                                          "nationality"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>
                                      {
                                        getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.dob?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                    }}
                                  >
                                    <Text>
                                      {
                                        getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.email?.value
                                      }
                                    </Text>
                                    <Text>
                                      {
                                        getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.mobileno?.nbr?.value
                                      }
                                    </Text>
                                  </View>
                                </View>
                              ))}
                            </View>
                          </View>
                        </View>
                        <View></View>
                      </Page>
                      {combinedSingpassData
                        ? combinedSingpassData.map((data, index) => (
                            <Page key={index} size="A4">
                              {/**Letterhead section */}
                              <View
                                style={{
                                  paddingVertical: 15,
                                }}
                              >
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {data?.person?.name?.value +
                                        " | Personal Information"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      padding: 8,
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                    }}
                                  >
                                    <View style={{ width: "50%" }}>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Ownership of Private Residential
                                          Property:
                                        </Text>
                                        <Text
                                          style={{
                                            fontSize: 7,
                                          }}
                                        >
                                          {data?.person?.hdbownership
                                            ? data?.person?.hdbownership
                                                ?.length > 0
                                              ? "Yes"
                                              : "No"
                                            : "No"}
                                        </Text>
                                      </View>
                                      {data?.person?.hdbownership
                                        ? data?.person?.hdbownership?.length >
                                          0
                                          ? data?.person?.hdbownership?.map(
                                              (hdb, index3) => (
                                                <View key={index3}>
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      gap: 10,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      HDB {index3 + 1}
                                                    </Text>
                                                  </View>

                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      HDB Ownership:
                                                    </Text>
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      {hdb?.address
                                                        ? (hdb?.address?.unit
                                                            ?.value !== null
                                                            ? `Unit ${hdb?.address?.unit?.value}, `
                                                            : "") +
                                                          (hdb?.address?.floor
                                                            ?.value !== null
                                                            ? `Floor ${hdb?.address?.floor?.value}, `
                                                            : "") +
                                                          (hdb?.address?.block
                                                            ?.value !== null
                                                            ? `Block ${hdb?.address?.block?.value}, `
                                                            : "") +
                                                          (hdb?.address
                                                            ?.street
                                                            ?.value !== null
                                                            ? `${hdb?.address?.street?.value}, `
                                                            : "") +
                                                          (hdb?.address
                                                            ?.postal
                                                            ?.value !== null
                                                            ? `${hdb?.address?.postal?.value}, `
                                                            : "") +
                                                          (hdb?.address
                                                            ?.country
                                                            ?.desc !== null
                                                            ? `${hdb?.address?.country?.desc}.`
                                                            : "")
                                                        : ""}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      Outstanding HDB Loan
                                                      Balance:
                                                    </Text>
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        width: "30%",
                                                      }}
                                                    >
                                                      {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          style: "currency",
                                                          currency: "SGD",
                                                        }
                                                      )}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      Type of HDB:
                                                    </Text>
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      {hdb?.hdbtype?.desc}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      Monthly Loan
                                                      Installment:
                                                    </Text>
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                        "en-US",
                                                        {
                                                          style: "currency",
                                                          currency: "SGD",
                                                        }
                                                      )}
                                                    </Text>
                                                  </View>
                                                </View>
                                              )
                                            )
                                          : "No"
                                        : "No"}
                                    </View>
                                    <View style={{ width: "50%" }}>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Marital Status:
                                        </Text>
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            width: "65%",
                                          }}
                                        >
                                          {data?.person?.marital?.desc}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Vehicles:
                                        </Text>
                                        <View>
                                          {data?.person?.vehicles?.map(
                                            (veh, index4) => (
                                              <View key={index4}>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    width: "65%",
                                                  }}
                                                >
                                                  {veh?.make?.value +
                                                    ": " +
                                                    veh?.model?.value}
                                                </Text>
                                              </View>
                                            )
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {data?.person?.name?.value +
                                        " | Notice of Assessments"}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor: "#c7dcec",
                                        opacity: 0.7,
                                        padding: 4,
                                        gap: 10,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Year of Assesment</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Amount</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Employment</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Interest</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Rent</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Trade</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "14%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Tax Clearance</Text>
                                      </View>
                                    </View>
                                    {data?.person?.noahistory?.noas?.map(
                                      (data2, index2) => (
                                        <View
                                          key={index2}
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor:
                                              index2 % 2
                                                ? "rgba(199, 220, 236, 0.2)"
                                                : "transparent",
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {
                                                data2?.["yearofassessment"]
                                                  ?.value
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.[
                                                "amount"
                                              ]?.value?.toLocaleString(
                                                "en-us",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.[
                                                "employment"
                                              ]?.value?.toLocaleString(
                                                "en-us",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.[
                                                "interest"
                                              ]?.value?.toLocaleString(
                                                "en-us",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.[
                                                "rent"
                                              ]?.value?.toLocaleString(
                                                "en-us",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.[
                                                "trade"
                                              ]?.value?.toLocaleString(
                                                "en-us",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "14%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data2?.["taxclearance"]?.value}
                                            </Text>
                                          </View>
                                        </View>
                                      )
                                    )}
                                  </View>
                                </View>
                                {allCBSData?.filter(
                                  (dat2) =>
                                    dat2?.name === data?.person?.name?.value
                                )?.[0] ? (
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Credit Bureau | Personal Commitement"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Secured Credit Limit:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Credit Score:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {
                                              allCBSData?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]?.cbs_data?.CreditScore
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Probability of Default:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {
                                              allCBSData?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]?.cbs_data
                                                ?.ProbabilityofDefault
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Unsecured Credit Utilized:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Unsecured Credit Utilization in
                                            percentage:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData?.filter(
                                              (dat2) =>
                                                dat2?.name ===
                                                data?.person?.name?.value
                                            )?.[0]?.cbs_data
                                              ?.UnsecuredCreditUtilizationinpercentage +
                                              "%"}
                                          </Text>
                                        </View>
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Unsecured Credit Limit:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Risk Grade:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {
                                              allCBSData?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]?.cbs_data?.RiskGrade
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Secured Credit Utilized:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Secured Credit Utilization in
                                            percentage:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData?.filter(
                                              (dat2) =>
                                                dat2?.name ===
                                                data?.person?.name?.value
                                            )?.[0]?.cbs_data
                                              ?.SecuredCreditUtilizationinpercentage +
                                              "%"}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Monthly Commitment/Installment:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.MonthlyCommitment?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "30%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Loan / Product Type</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "30%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Grantor / Bank</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "20%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Outstanding Balance</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "20%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Monthly Installment</Text>
                                        </View>
                                      </View>

                                      {allCBSData
                                        ?.filter(
                                          (dat2) =>
                                            dat2?.name ===
                                            data?.person?.name?.value
                                        )?.[0]
                                        ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                          (imp, index10) => (
                                            <View
                                              key={index10}
                                              style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                backgroundColor:
                                                  index10 % 2
                                                    ? "rgba(199, 220, 236, 0.4)"
                                                    : "rgba(199, 220, 236, 0.15)",
                                                padding: 4,
                                                gap: 10,
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "30%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>
                                                  {imp.includes(
                                                    "private residential purchase",
                                                    ""
                                                  )
                                                    ? "Private Residential Purchase"
                                                    : imp.includes(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                    ? "Motor Vehicle Loan"
                                                    : imp.includes(
                                                        "hdb loan",
                                                        ""
                                                      )
                                                    ? "HDB Loan"
                                                    : ""}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "30%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>
                                                  {imp
                                                    .replace(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                    .replace(
                                                      "motor vehicle loan",
                                                      ""
                                                    )
                                                    .replace("hdb loan", "")
                                                    .trim()
                                                    .split(" ")[0]
                                                    .toUpperCase()}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "20%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>
                                                  {parseFloat(
                                                    imp
                                                      .split("   ")
                                                      [
                                                        imp.split("   ")
                                                          .length - 4
                                                      ].replace(/,/g, "")
                                                  ).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  })}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "20%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>
                                                  {allCBSData?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name
                                                        ?.value
                                                  )?.[0]?.cbs_data
                                                    ?.CBSImpLoanSupplementaryInstallment?.[
                                                    index10
                                                  ]
                                                    ? (
                                                        parseFloat(
                                                          allCBSData
                                                            ?.filter(
                                                              (dat2) =>
                                                                dat2?.name ===
                                                                data?.person
                                                                  ?.name
                                                                  ?.value
                                                            )?.[0]
                                                            ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                              index10
                                                            ].split("   ")
                                                            [
                                                              allCBSData
                                                                ?.filter(
                                                                  (dat2) =>
                                                                    dat2?.name ===
                                                                    data
                                                                      ?.person
                                                                      ?.name
                                                                      ?.value
                                                                )?.[0]
                                                                ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                  index10
                                                                ].split("   ")
                                                                .length - 4
                                                            ]?.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                        ) +
                                                        parseFloat(
                                                          allCBSData
                                                            ?.filter(
                                                              (dat2) =>
                                                                dat2?.name ===
                                                                data?.person
                                                                  ?.name
                                                                  ?.value
                                                            )?.[0]
                                                            ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                              index10
                                                            ].split("   ")
                                                            [
                                                              allCBSData
                                                                ?.filter(
                                                                  (dat2) =>
                                                                    dat2?.name ===
                                                                    data
                                                                      ?.person
                                                                      ?.name
                                                                      ?.value
                                                                )?.[0]
                                                                ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                  index10
                                                                ].split("   ")
                                                                .length - 3
                                                            ]?.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                        )
                                                      ).toLocaleString(
                                                        "en-US",
                                                        {
                                                          style: "currency",
                                                          currency: "SGD",
                                                        }
                                                      )
                                                    : null}
                                                </Text>
                                              </View>
                                            </View>
                                          )
                                        )}
                                    </View>
                                    <View
                                      style={{
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Last 6 Months (Unsecured) Credit
                                          Utilization Graph:
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "100%",
                                          fontSize: 7,
                                        }}
                                      >
                                        <View
                                          style={{ flexDirection: "row" }}
                                        >
                                          <View
                                            style={{
                                              width: "5%",
                                              position: "relative",
                                            }}
                                          >
                                            <View style={{ height: "15px" }}>
                                              <Text
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name
                                                        ?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    }
                                                  )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{ height: "220px" }}
                                            ></View>
                                          </View>
                                          <View
                                            style={{
                                              width: "95%",
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "100%",
                                                flexDirection: "row",
                                                marginTop: "25px",
                                                borderBottom:
                                                  "1px solid lightgrey",
                                                height: "200px",
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  height: "200px",
                                                  borderLeft:
                                                    "1px solid lightgrey",
                                                }}
                                              />
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                  (dat30, index30) => (
                                                    <View
                                                      key={index30}
                                                      style={{
                                                        marginLeft: "4%",
                                                        marginRight: "4%",
                                                        width: "8%",
                                                        borderTopLeftRadius:
                                                          "4px",
                                                        borderTopRightRadius:
                                                          "4px",
                                                        height: `${
                                                          (parseFloat(
                                                            dat30.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ) /
                                                            allCBSData?.filter(
                                                              (dat2) =>
                                                                dat2?.name ===
                                                                data?.person
                                                                  ?.name
                                                                  ?.value
                                                            )?.[0]?.cbs_data
                                                              ?.Last6MonthsGraphChartMaxData) *
                                                          100
                                                        }%`,
                                                        backgroundColor:
                                                          "rgba(217, 238, 255, 1)",
                                                      }}
                                                    ></View>
                                                  )
                                                )}
                                            </View>
                                            <View
                                              style={{
                                                marginTop: "8px",
                                                flexDirection: "row",
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                  (dat, index) => (
                                                    <View
                                                      key={index}
                                                      style={{
                                                        width: "16%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontWeight: 600,
                                                          fontSize: 7,
                                                        }}
                                                      >
                                                        {dat.date
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          dat.date.slice(1)}
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontWeight: 600,
                                                          fontSize: 7,
                                                        }}
                                                      >
                                                        {parseFloat(
                                                          dat.value.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  )
                                                )}
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                ) : null}
                              </View>
                            </Page>
                          ))
                        : null}
                    </Document>
                  </PDFViewer>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Modal>
      {fileUploading ? (
        <div
          style={{
            position: "fixed",
            background: "rgba(0,0,0,0.6)",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
            top: 0,
            left: 0,
          }}
        >
          Loading
        </div>
      ) : null}

      <Modal
        width={isMobile ? "100%" : "40vw"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={confirmAgreementModal}
        onCancel={() => {
          setConfirmAgreementModal(false);
          setSomethingWrong(false);
          setMessageForBorrower("");
        }}
        footer={[
          <React.Fragment key="footer-elements">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {somethingWrong ? (
                <>
                  <button
                    onClick={() => {
                      console.log(liveModalAgreementId);
                      amendAgreement();
                    }}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 5px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "180px",
                    }}
                  >
                    Send
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      console.log(liveModalAgreementId);
                      agreeToAgreement();
                    }}
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 5px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "180px",
                    }}
                  >
                    Agree
                  </button>
                  <button
                    onClick={() => setSomethingWrong(true)}
                    className="all_button"
                    style={{
                      background: "transparent",
                      color: "grey",
                      border: "2px solid lightgrey",
                      borderRadius: "50px",
                      padding: "4px 0px",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px 0px 0px",
                      cursor: "pointer",
                      width: "180px",
                    }}
                  >
                    Something is wrong
                  </button>
                </>
              )}
            </div>
          </React.Fragment>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 16,
            borderRadius: "10px",
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {somethingWrong ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                textAlign: "center",
                width: "70%",
                justifyContent: "center",
                fontSize: "14px",
              }}
            >
              Please leave a note to the borrower to tell them what is wrong
              with the agreement, leave clear instructions to ensure that
              borrower can ammend accordingly.
              <textarea
                value={messageForBorrower}
                onChange={(e) => setMessageForBorrower(e.target.value)}
                style={{
                  width: "100%",
                  height: "100px",
                  outline: "none",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  resize: "none",
                  textAlign: "center",
                  marginTop: "10px",
                  fontSize: "11px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "10px",
                textAlign: "center",
                width: "70%",
                justifyContent: "center",
                fontSize: "14px",
              }}
            >
              Do you confirm that the agreement signed back from the borrowers
              are all correct ?
            </div>
          )}
        </div>
      </Modal>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 9"}
          gridRow="span 2"
          borderRadius="10px"
          opacity="0.95"
          paddingBottom="100px"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px 15px 50px 15px",
          }}
        >
          <Box gridColumn="span 4" gridRow="span 2" opacity="0.95">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
              borderRadius="10px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Accepted Loans
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexDirection: isMobile ? "row-reverse" : "row",
                  width: isMobile ? "auto" : "400px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <label
                    htmlFor="sort-by"
                    style={{
                      marginRight: "7px",
                      marginLeft: "10px",
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "13px",
                      fontFamily: "Manrope, sans-serif",
                      display: isMobile ? "none" : "block",
                    }}
                  >
                    View:
                  </label>
                  <div style={{ width: isMobile ? "90%" : "60%" }}>
                    <select
                      id="sort-by"
                      value={sortBy1}
                      onChange={(e) => setSortBy1(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        color: "#0E5AA5",
                        backgroundColor: "#ECF0F5",
                        border: "1px solid #3E536733",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      <option value="all">All Loans</option>
                      <option value="pending">Pending Loans</option>
                      <option value="ongoing">Ongoing Loans</option>
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <label
                    htmlFor="sort-by"
                    style={{
                      marginRight: "7px",
                      marginLeft: "10px",
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "13px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Sort by:
                  </label>
                  <div style={{ width: "60%" }}>
                    <select
                      id="sort-by"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        color: "#0E5AA5",
                        backgroundColor: "#ECF0F5",
                        border: "1px solid #3E536733",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      <option value="default">Date & Time</option>
                      <option value="loan-amount">Loan Amount</option>
                    </select>
                  </div>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                {data && data.length > 0 ? (
                  data
                    .slice(0, 6)
                    .filter((item) => {
                      if (sortBy1 === "all") {
                        return true; // Show all items
                      } else if (sortBy1 === "pending") {
                        return item.status === "pending"; // Show only pending items
                      } else if (sortBy1 === "ongoing") {
                        return item.status === "ongoing"; // Show only ongoing items
                      } else {
                        return true; // Default to showing all items
                      }
                    })
                    .sort((a, b) => {
                      if (sortBy === "loan-amount") {
                        return a.offer_amount - b.offer_amount;
                      } else if (sortBy === "date") {
                        return new Date(a.created_at) - new Date(b.created_at);
                      } else {
                        // Default sorting option
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                    })
                    .map((item, index) => (
                      <Container
                        key={index}
                        icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        title={item.principal_name || "-"}
                        subtitle={
                          item.express === 1
                            ? "Express Applicant"
                            : "Approved Borrower"
                        }
                        label="Loan Amount"
                        description={
                          item.amount
                            ? item.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label1="Monthly Amount"
                        description1={(
                          (item.amount *
                            (item.interest_rate * item.period + 1)) /
                          item.period
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                        label2="Period"
                        description2={item.period + " Months"}
                        label3="Loan Status"
                        description3={
                          item.status.charAt(0).toUpperCase() +
                            item.status.slice(1) || "-"
                        }
                        label4="Interest Rate Per Month"
                        description4={
                          (item.interest_rate * 100).toFixed(1) + " %"
                        }
                        createdAt={item.created_at}
                        remainingPeriod={
                          item.period_left.length > 0
                            ? item.period_left[0].loan_period_left
                            : item.period
                        }
                        outstandingAmount={(
                          ((item.amount *
                            (item.interest_rate * item.period + 1)) /
                            item.period) *
                          (item.period_left
                            ? item.period_left[0]
                              ? item.period_left[0].loan_period_left
                                ? item.period_left[0].loan_period_left + 1
                                : item.period
                              : item.period
                            : item.period)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                        index={index} // pass the index prop to each Container instance
                        loanId={item.loan_id}
                        borrowerId={item.borrower_id}
                        lenderId={item.lender_id}
                        borrowerNotSigned={item.borrower_sign}
                        documentExist={item.document}
                        previousDocumentExist={item.lender_sign}
                        item={item}
                      />
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No loans accepted
                    </div>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            // backgroundColor="rgba(153, 178, 205, 0)"
            overflow="auto"
            height="90vh"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Analytics
              </Typography>
            </Box>
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              // marginBottom="10px"
            >
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="170px"
              >
                <ContainerLineChart></ContainerLineChart>
              </Box>
              <Box
                width="49%"
                opacity="1.00"
                display="flex"
                alignItems="left"
                justifyContent="left"
                height="200px"
                marginLeft="10px"
              >
                <ContainerPieChart></ContainerPieChart>
              </Box>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: "10px" }}
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Activity Log
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <Box
                    gridColumn="span 1"
                    // backgroundColor={colors.grey[910]}
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      icon={item.icon} // use the icon URL from the API
                      title={item.principal_name || "-"}
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </Box>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "48vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No Activity Log
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default AcceptedLoans;
