import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import cookies from "js-cookie";

const TinyLineChart = ({ title, subtitle }) => {
  const [data, setData] = useState([]);
  const [averageInterestRate, setAverageInterestRate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://www.smart-lend.com/api/analytics/marketInterestRate",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const allInterestRate = json["All Interest Rate"];
      const averageInterestRate = json["Average Interest Rate"];
      setData(allInterestRate);
      setAverageInterestRate(averageInterestRate);
    };

    fetchData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "grey",
        border: "1px solid none",
        backgroundColor: "#FFFFFFB3",
        borderRadius: "10px",
        height: "170px",
        justifyContent: "space-between",
        alignItems: "left",
        position: "relative",
      }}
    >
      <div
        style={{ marginTop: "20px", marginBottom: "0px", marginLeft: "20px" }}
      >
        <ResponsiveContainer
          width="85%"
          height={100}
          margin={{ top: -10, bottom: 10 }}
        >
          <LineChart data={data} margin={{ top: 30, bottom: 10 }} height={120}>
            <defs>
              <linearGradient id="line-gradient" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="rgba(138, 156, 197, 0.8)" />
                <stop offset="20%" stopColor="rgba(190, 201, 227, 0.8)" />
                <stop offset="100%" stopColor="rgba(21,87,169,1)" />
              </linearGradient>
            </defs>
            <Line
              type="linear"
              dataKey="interest_rate"
              // stroke="url(#line-gradient)"
              strokeWidth={5}
              dot={{ r: 3 }}
              stroke="#115CA6"
              label={{
                position: "top",
                fontSize: 12,
                fontWeight: 600,
                fill: "#142C44CC",
              }}
            />
            {/* <Tooltip/> */}
          </LineChart>
        </ResponsiveContainer>
      </div>{" "}
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <h5
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "2.0em",
            fontWeight: "800",
            color: "#142C44",
            letterSpacing: "-1px",
            fontSize: "25px",
            margin: "0px",
          }}
        >
          {averageInterestRate
            ? `${averageInterestRate.toLocaleString()}%`
            : "-"}
        </h5>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "0.8em",
            fontWeight: "600",
            color: "#00000066",
            margin: "0px 0px 5px 0px",
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default TinyLineChart;
