import { Space, Table, Tag, Radio, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const TableUserLender = ({
  onRowClick,
  onSelectionChange,
  reloadTable,
  setReloadTable,
}) => {
  const [data, setData] = useState([]);
  const [dataRowClick, setDataRowClick] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});
  const [modalStates4, setModalStates4] = useState({});
  const [screenWidth, setScreenWidth] = useState("1920");

  const fetchData = async () => {
    try {
        const response = await fetch("https://www.smart-lend.com/api/user/viewAllUser", {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                Accept: "application/json",
            },
        });
        const json = await response.json();
        setData(json["Lender"]);

        if (response.status === 401) {
            Cookies.remove("token");
            window.location.href = "/auth/signin";
        }
    } catch (error) {
        console.error(error);
    }
};

useEffect(() => {
    fetchData();
}, [reloadTable]);

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=delete`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      // setReloadTable(true); // <-- trigger table reload
      fetchData();  // <-- directly fetch data after successful delete
      console.log("User deleted successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to delete user", error);
    }
  };

  const handleDeactivate = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=deactivate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      // setReloadTable(true); // <-- trigger table reload
      fetchData();  // <-- directly fetch data after successful delete
      console.log("User deactivate successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to deactivate user", error);
    }
  };

  useEffect(() => {});

  const handleRowClick = async (record) => {
    const userId = record.user_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewUserDetail?user_id=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setDataRowClick(json["User"]);
      showModal4(userId);
    } catch (error) {
      console.error("Error retrieving User:", error);
    }
  };

  const handleActivate = async (userId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserStatus?user_id=${userId}&type=activate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh your table data here
      // setReloadTable(true); // <-- trigger table reload
      fetchData();  // <-- directly fetch data after successful delete
      console.log("User activate successfully");

      const data = await response.json();
      // Display the message from the Ant Design library
      message.success(data.message);
    } catch (error) {
      console.log("Failed to activate user", error);
    }
  };

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    const initialStates3 = {};
    const initialStates4 = {};

    data.forEach((item) => {
      initialStates[item.userId] = false;
      initialStates2[item.userId] = false;
      initialStates3[item.userId] = false;
      initialStates4[item.userId] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
    setModalStates3(initialStates3);
    setModalStates4(initialStates4);
  }, [data]);

  const showModal = (userId) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal2 = (userId) => {
    setModalStates2((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal3 = (userId) => {
    setModalStates3((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  const showModal4 = (userId) => {
    console.log("value in showModal4", userId);
    setModalStates4((prevStates) => ({
      ...prevStates,
      [userId]: true,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Email
        </span>
      ),
      width:"24%",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Lender Name
        </span>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
        </span>
      ),
    },
    // {
    //   title: (
    //     <span
    //       style={{
    //         fontFamily: "Poppins, sans-serif",
    //         fontWeight: 700,
    //         fontSize: "13px",
    //         color: "#435669",
    //         margin: "0px",
    //       }}
    //     >
    //       User Account Type
    //     </span>
    //   ),
    //   dataIndex: "user_account_type",
    //   key: "user_account_type",
    //   render: (text) => (
    //     <span
    //       style={{
    //         fontFamily: "Poppins, sans-serif",
    //         fontWeight: 700,
    //         fontSize: "13px",
    //         color: "#435669",
    //         margin: "0px",
    //       }}
    //     >
    //       {text ? `${text.charAt(0).toUpperCase() + text.slice(1)}` : "-"}
    //     </span>
    //   ),
    // },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          NRIC
        </span>
      ),
      dataIndex: "nric",
      key: "nric",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Mobile No.
        </span>
      ),
      dataIndex: "mobile_no",
      key: "mobile_no",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          Credit Score
        </span>
      ),
      dataIndex: "credit_score",
      key: "credit_score",
      render: (text) => (
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {text ? `${text}` : "-"}
        </span>
      ),
    },
    {
      fixed: "right",
      key: "action",
      width: 110,
      render: (text, record) => (
        <Button type="primary" onClick={(event) => {
          event.stopPropagation();
          showModal(record.user_id)
        } } danger>
          Delete
        </Button>
      ),
    },
    {
      fixed: "right",
      key: "action",
      width: 150,
      render: (text, record) =>
        record.status === "active" ? (
          <Button
            type="primary"
            onClick={(event) =>{
              event.stopPropagation();
              showModal2(record.user_id )}}
            danger
          >
            Deactivate
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={(event) =>{
              event.stopPropagation();
              showModal3(record.user_id);
            } }
            style={{ backgroundColor: "#00DA5E", width: "90%" }}
          >
            Activate
          </Button>
        ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="user_id"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{
          x: 1000,
        }}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.target.type !== "checkbox" && event.target.type !== "button") {
              handleRowClick(record);
            }
          },
        })}
        style={{
          borderRadius: "10px 10px 10px 10px",
          cursor: "pointer",
          margin: screenWidth > 1200 ? "0px 20px" : "0px",
          width: screenWidth > 1200 ? "auto" : "100%",
        }}
      />
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates[item.user_id]}
            onCancel={() =>
              setModalStates((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleDelete(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to delete this lender?
          </Modal>
        ))}
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates2[item.user_id]}
            onCancel={() =>
              setModalStates2((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleDeactivate(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates2((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to deactivate this lender?
          </Modal>
        ))}
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", margin: "10% auto" }}
            open={modalStates3[item.user_id]}
            onCancel={() =>
              setModalStates3((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleActivate(item.user_id)}
                >
                  Yes
                </Button>
                <Button
                  type="default"
                  onClick={() =>
                    setModalStates3((prevStates) => ({
                      ...prevStates,
                      [item.user_id]: false,
                    }))
                  }
                >
                  No
                </Button>
              </div>,
            ]}
          >
            Are you sure you want to activate this lender?
          </Modal>
        ))}

      {dataRowClick &&
        dataRowClick.length > 0 &&
        dataRowClick.map((item) => (
          <Modal
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={modalStates4[item.user_id]}
            onCancel={() =>
              setModalStates4((prevStates) => ({
                ...prevStates,
                [item.user_id]: false,
              }))
            }
            footer={
              [
                // <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                //   <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                // </div>
              ]
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 16,
                borderRadius: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_amount"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Email
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.email || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_interest_rate"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  NRIC
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.nric || "-"}
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 16,
                borderRadius: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_amount"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Sex
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.sex || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_interest_rate"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Race
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.race || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Date of Birth
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.date_of_birth || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Status
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.status
                    ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    : "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Residential Status
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.residential_status || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Nationality
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.nationality || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Country of Birth
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.country_of_birth || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Bank Name
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.bank_name || "-"}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="offer_period"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Bank Account No.
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.bank_account_no || "-"}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    fontSize: "10px",
                    opacity: ".6",
                  }}
                >
                  Credit Score
                </label>
                <br />
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "grey",
                  }}
                >
                  {item.credit_score || "-"}
                </p>
              </div>
            </div>

            <hr />
          </Modal>
        ))}
    </div>
  );
};

export default TableUserLender;
