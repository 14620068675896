import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useHistory } from "react-router-dom";
import { Button, Modal, message } from "antd";
import React, { useState, useEffect } from "react";
import StarFilled from "@ant-design/icons";
import Cookies from "js-cookie";
import grid_normal from "../../assets/img/Grid_normal.png";
import list_selected from "../../assets/img/List_selected.png";
import Filter from "../../assets/img/Filter.png";
import Countdown from "react-countdown";
import CountdownWithHover from "../../components/CountDownWithHover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

const LoanRequestList = ({ item }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [data, setData] = useState([]);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [type, setLoanType] = useState("simple");
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [view, setView] = useState(false);
  const history = useHistory();
  const [loanOffers, setLoanOffers] = useState([]);
  const [minLoanAmount, setMinLoanAmount] = useState("");
  const [maxLoanAmount, setMaxLoanAmount] = useState("");
  const [creditScoreMin, setCreditScoreMin] = useState("");
  const [creditScoreMax, setCreditScoreMax] = useState("");
  const [LoanPeriodMin, setLoanPeriodMin] = useState("");
  const [LoanPeriodMax, setLoanPeriodMax] = useState("");
  const [loanPeriodFilter, setLoanPeriodFilter] = useState("");
  const [loanCategoryFilters, setLoanCategoryFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [empty, setempty] = useState("0");
  const [notInterestedIds, setNotInterestedIds] = useState([]);
  const [targetDate, setTargetDate] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [loans, setLoans] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [express, setExpress] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [principal_name, setPrincipalName] = useState(null);
  const [add_document_description, setAddDocumentDescription] = useState(null);
  const [documentDescriptions, setDocumentDescriptions] = useState([""]);
  const [screenWidth, setScreenWidth] = useState("1920");

  const handleChange = (event, index) => {
    const newDescriptions = [...documentDescriptions];
    newDescriptions[index] = event.target.value;
    setDocumentDescriptions(newDescriptions);
  };

  const addMore = () => {
    setDocumentDescriptions([...documentDescriptions, ""]);
  };

  const remove = (indexToRemove) => {
    setDocumentDescriptions(
      documentDescriptions.filter((_, index) => index !== indexToRemove)
    );
  };

  const showModalRequestDocument = (
    loan_id,
    express,
    user_id,
    principal_name
  ) => {
    setIsModalOpen(true);
    setLoanId(loan_id);
    setExpress(express);
    setUserId(user_id);
    setPrincipalName(principal_name);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRequestMoreDocument = async (
    loan_id,
    express,
    user_id,
    principal_name
  ) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    console.log("user_id value in handleMakeOffer", user_id); // Debug statement
    console.log("principal_name value in handleMakeOffer", principal_name); // Debug statement
    // rest of the code
    try {
      let path = "";

      let add_documents = documentDescriptions.map((description, index) => ({
        document_name: description,
        document: "",
      }));

      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: parseFloat(parseFloat(admin_fee).toFixed(2)),
        add_document: JSON.stringify(add_documents),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message
        try {
          let item = {
            user_id: user_id,
            channel: "system",
            subject: "Lender offer",
            message: "Lender had make an  offer",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog?description=You had make an offer to ${principal_name}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const timerStyle = {
    color: "grey",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let timeLeft = "";

    if (completed) {
      return <span style={timerStyle}>Time's up!</span>;
    } else {
      if (days > 0) {
        timeLeft = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeLeft = `${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeLeft = `${minutes}m ${seconds}s`;
      }

      return <span style={timerStyle}>{timeLeft}</span>;
    }
  };

  // const handleLoanPeriodFilterChange = (value) => {
  //   setLoanPeriodFilter(value);
  // };

  const handleCreditScoreMinChange = (event) => {
    setCreditScoreMin(event.target.value);
  };

  const handleCreditScoreMaxChange = (event) => {
    setCreditScoreMax(event.target.value);
  };

  const handleLoanPeriodMinChange = (event) => {
    setLoanPeriodMin(event.target.value);
  };

  const handleLoanPeriodMaxChange = (event) => {
    setLoanPeriodMax(event.target.value);
  };

  const handleLoanPeriodChange = (event) => {
    setSelectedLoanPeriod(event.target.value);
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleGridPath = () => {
    history.push("../loan-request-grid");
  };

  const handleListPath = () => {
    // history.push('')
  };

  const handleInterestRateChange = (e) => {
    let value = e.target.value;
    // if (value > 5) {
    //   value = 5;
    //   setErrorMessage("The maximum interest value is 5% only");
    // } else {
    //   setErrorMessage("");
    // }
    setInterestRate(value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const handleSearchFilter = () => {};

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
          0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 *
            0.05 *
            offer_period || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = offer_monthly_installment * 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  //Integration for the onChange Search Filter

  // Use the useEffect hook to filter the data based on the query parameters
  useEffect(() => {
    let filtered = data;

    // Filter the data based on the query parameters
    if (minLoanAmount) {
      filtered = filtered.filter(
        (item) => item.request_amount >= minLoanAmount
      );
    }
    if (maxLoanAmount) {
      filtered = filtered.filter(
        (item) => item.request_amount <= maxLoanAmount
      );
    }
    if (creditScoreMin) {
      filtered = filtered.filter((item) => item.credit_score >= creditScoreMin);
    }
    if (creditScoreMax) {
      filtered = filtered.filter((item) => item.credit_score <= creditScoreMax);
    }
    if (LoanPeriodMin) {
      filtered = filtered.filter(
        (item) => item.request_period >= LoanPeriodMin
      );
    }
    if (LoanPeriodMax) {
      filtered = filtered.filter(
        (item) => item.request_period <= LoanPeriodMax
      );
    }
    if (loanCategoryFilters.length > 0) {
      filtered = filtered.filter((item) =>
        loanCategoryFilters.includes(item.category)
      );
    }
    // Set filteredData only if it's not empty
    if (filtered.length > 0) {
      setFilteredData(filtered);
    } else if (filtered !== data) {
      setFilteredData([]);
      console.log("no");
      setempty("1");
    }
  }, [
    minLoanAmount,
    maxLoanAmount,
    creditScoreMin,
    creditScoreMax,
    LoanPeriodMin,
    LoanPeriodMax,
    loanCategoryFilters,
  ]);

  useEffect(() => {
    const storedNotInterestedIds = Cookies.get("notInterestedIds");
    if (storedNotInterestedIds) {
      setNotInterestedIds(JSON.parse(storedNotInterestedIds));
    }
  }, []);

  const handleLoanAmountCheckbox = (id, min, max) => {
    if (selectedCheckbox === id) {
      setSelectedCheckbox(null);
      setMinLoanAmount("");
      setMaxLoanAmount("");
    } else {
      setSelectedCheckbox(id);
      setMinLoanAmount(min);
      setMaxLoanAmount(max);
    }
  };

  const handleLoanPeriodFilterChange = (value) => {
    setLoanPeriodFilter(value);
  };

  const handleCheckboxChange = (isChecked, category) => {
    if (isChecked) {
      setLoanCategoryFilters([...loanCategoryFilters, category]);
    } else {
      setLoanCategoryFilters(loanCategoryFilters.filter((c) => c !== category));
    }
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const buttonStyleExpress = {
    margin: "20px 20px 00px 30px",
    backgroundColor: "#FED065",
    color: "#FFFF",
    border: "1px solid none",
    borderRadius: "20px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "800",
    fontSize: "12px",
    width: "80%",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    border: "1px solid none",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "800",
    width: "80%",
    color: "#FFFFFF",
    margin: "20px 20px 00px 30px",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    textAlign: "center",
  };

  const buttonStyleNotInterested = {
    fontFamily: "Poppins, sans-serif",
    border: "1px solid none",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "800",
    width: "80%",
    color: "grey",
    margin: "20px 20px 00px -12px",
    background: "transparent",
    textAlign: "center",
    border: "1px solid grey",
  };

  const handleDownloadBankStatement = async (loan_id, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewBankStatement?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.data) {
        window.open(data.data, "_blank"); // open the url in a new tab
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("Data fetched:", data); // Debug statement
      setLoanDetails(data["Loan Detail"]);
    } catch (error) {
      console.error(error);
    }
  };

  const [modalStates, setModalStates] = useState({});
  const [modalStates2, setModalStates2] = useState({});
  const [modalStates3, setModalStates3] = useState({});
  const [modalStates4, setModalStates4] = useState({});

  useEffect(() => {
    const initialStates = {};
    const initialStates2 = {};
    const initialStates3 = {};
    const initialStates4 = {};

    data.forEach((item) => {
      initialStates[item.loan_id] = false;
      initialStates2[item.loan_id] = false;
      initialStates3[item.loan_id] = false;
      initialStates4[item.loan_id] = false;
    });
    setModalStates(initialStates);
    setModalStates2(initialStates2);
    setModalStates3(initialStates3);
    setModalStates4(initialStates4);
  }, [data]);

  const showModal = (loanId) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showModal2 = (loanId) => {
    setModalStates2((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const showModal3 = (loanId) => {
    setModalStates3((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const showModal4 = (loanId) => {
    setModalStates4((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleMakeOffer = async (loan_id, express, user_id, principal_name) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        express,
        offer_amount: parseFloat(parseFloat(offer_amount).toFixed(2)),
        offer_interest_rate: parseFloat(parseFloat(offer_interest_rate / 100)),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(parseFloat(offer_principal).toFixed(2)),
        offer_monthly_installment: parseFloat(
          parseFloat(offer_monthly_installment).toFixed(2)
        ),
        offer_revenue: parseFloat(parseFloat(offer_revenue).toFixed(2)),
        total_admin_fee: parseFloat(parseFloat(total_admin_fee).toFixed(2)),
        admin_fee: parseFloat(parseFloat(admin_fee).toFixed(2)),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        message.success(data.message || "Loan offer created successfully."); // Display success message

        try {
          let item = {
            user_id: user_id,
            channel: "system",
            subject: "Lender offer",
            message: "Lender had make an  offer",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog?description=You had make an offer to ${principal_name}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }
        path = "/pending-acceptance";
        return history.push(path);
      } else if (response.status === 400) {
        message.error(data.message || "Unable to create loan offer."); // Display error message
      } else {
        message.error("An unexpected error has occurred."); // Handle other errors
      }
    } catch (error) {
      message.error("Error creating offer:" + error.message); // Handle network errors or exceptions
      console.error("Error creating offer:", error);
    }
  };

  // Move the fetching functionality into a separate function
  const fetchData = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/viewLoanRequest",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const fetchedData = await response.json();
      const loanDataFromCookies = Cookies.get("loans")
        ? JSON.parse(Cookies.get("loans"))
        : fetchedData["Requested Loan"];

      setData(loanDataFromCookies);

      // Parse the expired_date into a JavaScript Date object and get its timestamp
      const targetDate = new Date(
        loanDataFromCookies[0].expired_date
      ).getTime();
      setTargetDate(targetDate);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNotInterestedModal = (event) => {
    console.log("Event: ", event);
    const value = event.target.value;
    console.log("Value: ", value);
    setSelectedValue(value);
    setShowOtherInput(value === "others");
  };

  const [showOtherInput, setShowOtherInput] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleNotInterested = async (loan_id, selectedValue) => {
    console.log("Not interested");
    const updatedNotInterestedIds = [...notInterestedIds, loan_id];

    // Filter out the loan with the provided loan_id
    const selectedLoan = data.filter((loan) => loan.loan_id === loan_id);

    // Filter out the other loans
    const remainingLoans = data.filter((loan) => loan.loan_id !== loan_id);

    // Concatenate the arrays to move the selected loan to the end
    const updatedLoans = [...remainingLoans, ...selectedLoan];

    // Update the data array and not interested ids
    setData(updatedLoans);
    setNotInterestedIds(updatedNotInterestedIds);

    // Store the updated data in the cookies
    Cookies.set("notInterestedIds", JSON.stringify(updatedNotInterestedIds));
    Cookies.set("loans", JSON.stringify(updatedLoans));

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/notInterestedLoan?loan_id=${loan_id}&reject_reason=${selectedValue}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }

    try {
      let item = {
        user_id: "A00001",
        channel: "system",
        subject: `Not interested Loan`,
        message: `Lender had select an option for not interested with loan ${loan_id}`,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/notification/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      console.log("Offer created:", data);
    } catch (error) {
      console.error("Error creating offer:", error);
    }

    // Fetch data again
    fetchData();
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m="4px 20px 20px 20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        borderRadius="10px"
      >
        <Box
          gridColumn="span 9"
          gridRow="span 2"
          borderRadius="10px"
          opacity="0.95"
          paddingBottom="100px"
          style={{
            overflowY: "scroll",
            height: screenWidth < 1200 ? "80vh" : "100vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
          // padding="20px 20px 20px 20px"
        >
          <Modal
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={() =>
                    handleRequestMoreDocument(
                      loanId,
                      express,
                      user_id,
                      principal_name
                    )
                  }
                >
                  Submit
                </Button>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>,
            ]}
            open={isModalOpen}
            // onOk={handleOk}
            onCancel={handleCancel}
          >
            {" "}
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                marginTop: "10px",
              }}
            >
              Please Insert The Document Name
            </div>
            <div>
              {documentDescriptions.map((description, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "90%",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontWeight: "700",
                        marginTop: "10px",
                      }}
                    >
                      Document Name
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <div>
                      <input
                        style={{
                          width: "90%",
                          padding: "8px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          outline: "none",
                          border: "1px solid rgba(0,0,0,0.2)",
                        }}
                        value={description}
                        placeholder="Document Name"
                        onChange={(event) => handleChange(event, index)}
                      />
                    </div>
                  </div>
                  {documentDescriptions.length > 1 && (
                    <div style={{ width: "10%", textAlign: "right" }}>
                      <Button
                        type="default"
                        onClick={() => remove(index)}
                        style={{
                          backgroundColor: "none",
                          border: "none",
                          color: "#FF0000",
                          boxShadow: "none",
                          marginTop: "10px",
                        }}
                        icon={<MinusOutlined />}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </div>
              ))}
              <div>
                <Button
                  type="default"
                  onClick={addMore}
                  style={{
                    backgroundColor: "none",
                    border: "none",
                    color: "#0E5AA5",
                    marginLeft: "25%",
                    boxShadow: "none",
                    marginTop: "5px",
                  }}
                  icon={<PlusOutlined style={{ marginTop: "-10px" }} />}
                >
                  Add more
                </Button>
              </div>
            </div>
          </Modal>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            borderRadius="10px"
            style={{ marginBottom: "10px" }}
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Requested Loans
            </Typography>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  size="small"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleGridPath}
                >
                  {" "}
                  <img src={grid_normal} alt="" width={16} height={16} />
                </Button>
                <Button
                  size="small"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleListPath}
                >
                  {" "}
                  <img src={list_selected} alt="" width={16} height={16} />
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "63%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Recommend</option>
                    <option value="latest-request">Latest Request</option>
                  </select>
                </div>
              </div>
            </div>
          </Box>
          <Box
            marginTop="25px"
            display="flex"
            width="100%"
            borderRadius="10px"
            justifyContent="left"
            marginLeft="20px"
          >
            <Box
              display="flex"
              flexDirection="column"
              backgroundColor="#FFFFFF"
              borderRadius="20px"
              marginTop="-10px"
              width="95%"
              paddingBottom="50px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                colors={colors.grey[910]}
                backgroundColor="#F5F5F5"
                width="100%"
                borderRadius="20px 20px 20px 20px"
                padding="0px 0px 20px 0px"
              >
                <div style={{ textAlign: "left", flexBasis: 0, flexGrow: 1 }}>
                  <h3
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#435669",
                      margin: "30px 1px 1px 30px",
                      flex: "0 0 auto",
                    }}
                  >
                    Borrower Name
                  </h3>
                </div>
                <div style={{ textAlign: "center", flexBasis: 0, flexGrow: 1 }}>
                  <h3
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#435669",
                      margin: "30px 1px 1px 10px",
                      flex: "0 0 auto",
                    }}
                  >
                    Credit Score
                  </h3>
                </div>
                <div style={{ textAlign: "center", flexBasis: 0, flexGrow: 1 }}>
                  <h3
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#435669",
                      margin: "30px 0px 1px -50px",
                      flex: "0 0 auto",
                    }}
                  >
                    Loan Amount
                  </h3>
                </div>
                <div style={{ textAlign: "center", flexBasis: 0, flexGrow: 1 }}>
                  <h3
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#435669",
                      margin: "30px 0px 1px -60px",
                      flex: "0 0 auto",
                    }}
                  >
                    Loan Period
                  </h3>
                </div>
                <div style={{ textAlign: "right", flexBasis: 0, flexGrow: 1 }}>
                  <h3
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#435669",
                      margin: "30px 70px 1px 0px",
                      flex: "0 0 auto",
                    }}
                  >
                    {/* Date */}
                  </h3>
                </div>
              </Box>

              <div>
                {filteredData.length > 0 ? (
                  filteredData
                    .sort((a, b) => {
                      if (
                        notInterestedIds.includes(a.loan_id) &&
                        !notInterestedIds.includes(b.loan_id)
                      ) {
                        return 1;
                      }
                      if (
                        !notInterestedIds.includes(a.loan_id) &&
                        notInterestedIds.includes(b.loan_id)
                      ) {
                        return -1;
                      }
                      // If express 1 must be top compare to express 0
                      if (a.express !== b.express) {
                        return b.express - a.express;
                      }
                      // Sorting based on the selection at the sort by option
                      if (sortBy === "default") {
                        // if user select the option recommend, then sort the data based on the request_amount from high to low
                        return b.request_amount - a.request_amount;
                      } else if (sortBy === "latest-request") {
                        // if user select option latest request, sort the data by latest created_at to the not latest created_at
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                        width="100%"
                        borderRadius="20px 20px 20px 20px"
                        padding="0px 0px 30px 0px"
                      >
                        <div
                          style={{
                            textAlign: "left",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0px 15px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <img
                              src={
                                "https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                              }
                              style={{
                                width: "55px",
                                height: "55px",
                                borderRadius: "1000px",
                                border: "2px solid #f0f0f0",
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "15px",

                                marginTop: "7px",
                                marginRight: "1px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <h3
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    textAlign: "left",
                                    marginTop: "18px",
                                    color: "#435669",
                                    // cursor: "pointer",
                                  }}
                                  // onClick={(event) =>
                                  //   showModal2(item.loan_id, event)
                                  // }
                                >
                                  {item.principal_name || "-"}
                                </h3>
                                <span
                                  style={{
                                    color: "blue",
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    marginTop: "20px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <StarFilled />
                                </span>
                              </div>
                              {item.express === 1 ? (
                                <tooltip
                                  placement="topRight"
                                  title="This application qualifies for our 24-hour service 
guarantee based on our credit scoring. Be the first to fund them within 24 hours!"
                                >
                                  <p
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: "200",
                                      fontSize: "11px",
                                      textAlign: "left",
                                      marginTop: "-5px",
                                      opacity: ".6",
                                      color: "#3366CC",
                                    }}
                                  >
                                    Express Applicant
                                  </p>
                                </tooltip>
                              ) : (
                                <p
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "200",
                                    fontSize: "11px",
                                    textAlign: "left",
                                    marginTop: "-5px",
                                    opacity: ".6",
                                    color: "#3366CC",
                                  }}
                                >
                                  Approved Borrower
                                </p>
                              )}

                              <p
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-9px",
                                  color: "grey",
                                }}
                              >
                                {item.loan_offer_count + " Offering"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px 0px 0px 50px",
                              flex: "0 0 auto",
                              cursor: "pointer",
                            }}
                            onClick={(event) => showModal3(item.loan_id, event)}
                          >
                            {item.credit_score || "-"}
                          </h3>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px -30px 0px 0px",
                              flex: "0 0 auto",
                            }}
                          >
                            {item.request_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }) || "-"}
                          </h3>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px -90px 0px 0px",
                              flex: "0 0 auto",
                            }}
                          >
                            {item.request_period + " Months" || "-"}
                          </h3>
                        </div>
                        <div
                          key={item.loan_id}
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "-10px 1px 0px 20px",
                              flex: "0 0 auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "14px",
                              }}
                            >
                              <Button
                                type="primary"
                                size="middle"
                                onClick={() => showModal(item.loan_id)}
                                className={
                                  item.express === 1
                                    ? "buttonExpressList"
                                    : "buttonBorrowerList"
                                }
                              >
                                Make an offer
                              </Button>
                              {(item.reject_reason === null ||
                                item.reject_reason === "") && (
                                <button
                                  type="default"
                                  style={{ width: "100%" }}
                                  onClick={() => showModal4(item.loan_id)}
                                  className="buttonNotInterestedList"
                                >
                                  Not Interested?
                                </button>
                              )}
                            </div>
                            <div style={{ width: "100%" }}>
                              <Button
                                type="default"
                                onClick={(event) =>
                                  handleDownloadBankStatement(
                                    item.loan_id,
                                    event
                                  )
                                }
                                style={{
                                  borderRadius: "15px",
                                  marginTop: "10px",
                                }}
                              >
                                Download Bank Statement
                              </Button>
                            </div>
                            <tooltip
                              placement="topRight"
                              title=" Number of hours the request is live"
                            >
                              <CountdownWithHover
                                targetDate={targetDate}
                                renderer={renderer}
                              />
                            </tooltip>
                            <Modal
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0)",
                              }}
                              open={modalStates[item.loan_id]}
                              onCancel={() =>
                                setModalStates((prevStates) => ({
                                  ...prevStates,
                                  [item.loan_id]: false,
                                }))
                              }
                              footer={[
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 16,
                                  }}
                                >
                                  {offer_amount.length > 0 &&
                                    offer_interest_rate.length > 0 &&
                                    offer_period.length > 0 &&
                                    type.length > 0 && (
                                      <div>
                                        <Button
                                          type="primary"
                                          onClick={() =>
                                            handleMakeOffer(
                                              item.loan_id,
                                              item.express,
                                              item.user_id,
                                              item.principal_name
                                            )
                                          }
                                        >
                                          Make Offer
                                        </Button>

                                        <Button
                                          type="default"
                                          style={{ marginLeft: "10px" }}
                                          onClick={() =>
                                            showModalRequestDocument(
                                              item.loan_id,
                                              item.express,
                                              item.user_id,
                                              item.principal_name
                                            )
                                          }
                                        >
                                          Request More Document
                                        </Button>
                                      </div>
                                    )}
                                </div>,
                              ]}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <p>Borrower: {item.principal_name || "-"} </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>Loan ID: {item.loan_id} </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: 16,
                                  borderRadius: "10px",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <label htmlFor="offer_amount">
                                    Loan Amount
                                  </label>
                                  <br />
                                  <input
                                    type="number"
                                    id="offer_amount"
                                    name="offer_amount"
                                    style={{
                                      borderRadius: "10px",
                                      paddingLeft: "10px",
                                    }}
                                    onChange={handleLoanAmountChange}
                                  />
                                </div>
                                <div style={{ flex: 1 }}>
                                  <label htmlFor="offer_interest_rate">
                                    Interest Rate (%)
                                  </label>
                                  <br />
                                  <input
                                    type="number"
                                    id="offer_interest_rate"
                                    name="offer_interest_rate"
                                    style={{
                                      borderRadius: "10px",
                                      paddingLeft: "10px",
                                    }}
                                    onChange={handleInterestRateChange}
                                  />
                                  {errorMessage && (
                                    <p style={{ color: "red" }}>
                                      {errorMessage}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: 16,
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <label htmlFor="offer_period">
                                    Loan Period (Month)
                                  </label>
                                  <br />
                                  <input
                                    type="number"
                                    id="offer_period"
                                    name="offer_period"
                                    style={{
                                      borderRadius: "10px",
                                      paddingLeft: "10px",
                                    }}
                                    onChange={handleLoanPeriodChange}
                                  />
                                </div>
                                <div style={{ flex: 1 }}>
                                  <label htmlFor="type">Loan Type</label>
                                  <br />
                                  <select
                                    id="type"
                                    name="type"
                                    style={{
                                      borderRadius: "10px",
                                      width: "73%",
                                    }}
                                    onChange={handleLoanTypeChange}
                                  >
                                    <option value="simple">Simple Loan</option>
                                    <option value="effective">
                                      Effective Loan
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <hr />

                              <p>
                                Total cost of Financing:{" "}
                                {Number(offer_principal).toFixed(2)}
                              </p>
                              <p>
                                Expected Monthly Installment:{" "}
                                {Number(offer_monthly_installment).toFixed(2)}
                              </p>
                              <p>
                                Guaranteed Revenue:{" "}
                                {Number(offer_revenue).toFixed(2)}
                              </p>
                              <p>
                                Total Admin Fee:{" "}
                                {Number(total_admin_fee).toFixed(2)}
                              </p>
                            </Modal>
                          </h3>
                        </div>
                      </Box>
                    ))
                ) : data && data.length > 0 && empty == "0" ? (
                  data
                    .sort((a, b) => {
                      if (
                        notInterestedIds.includes(a.loan_id) &&
                        !notInterestedIds.includes(b.loan_id)
                      ) {
                        return 1;
                      }
                      if (
                        !notInterestedIds.includes(a.loan_id) &&
                        notInterestedIds.includes(b.loan_id)
                      ) {
                        return -1;
                      }
                      // If express 1 must be top compare to express 0
                      if (a.express !== b.express) {
                        return b.express - a.express;
                      }
                      // Sorting based on the selection at the sort by option
                      if (sortBy === "default") {
                        // if user select the option recommend, then sort the data based on the request_amount from high to low
                        return b.request_amount - a.request_amount;
                      } else if (sortBy === "latest-request") {
                        // if user select option latest request, sort the data by latest created_at to the not latest created_at
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        colors={colors.grey[910]}
                        width="100%"
                        borderRadius="20px 20px 20px 20px"
                        padding="0px 0px 30px 0px"
                      >
                        <div
                          style={{
                            textAlign: "left",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0px 15px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <img
                              src={
                                "https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                              }
                              style={{
                                width: "55px",
                                height: "55px",
                                borderRadius: "1000px",
                                border: "2px solid #f0f0f0",
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "15px",
                                marginBottom: "10px",
                                marginTop: "7px",
                                marginRight: "1px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <h3
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    textAlign: "left",
                                    marginTop: "18px",
                                    color: "#435669",
                                    // cursor: "pointer",
                                  }}
                                  // onClick={(event) =>
                                  //   showModal2(item.loan_id, event)
                                  // }
                                >
                                  {item.principal_name || "-"}
                                </h3>
                                <span
                                  style={{
                                    color: "blue",
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    marginTop: "20px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <StarFilled />
                                </span>
                              </div>
                              {item.express === 1 ? (
                                <tooltip
                                  placement="topRight"
                                  title="This application qualifies for our 24-hour service 
guarantee based on our credit scoring. Be the first to fund them within 24 hours!"
                                >
                                  <p
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: "200",
                                      fontSize: "11px",
                                      textAlign: "left",
                                      marginTop: "-5px",
                                      opacity: ".6",
                                      color: "#3366CC",
                                    }}
                                  >
                                    Express Applicant
                                  </p>
                                </tooltip>
                              ) : (
                                <p
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "200",
                                    fontSize: "11px",
                                    textAlign: "left",
                                    marginTop: "-5px",
                                    opacity: ".6",
                                    color: "#3366CC",
                                  }}
                                >
                                  Approved Borrower
                                </p>
                              )}

                              <p
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  marginTop: "-9px",
                                  color: "grey",
                                }}
                              >
                                {item.loan_offer_count + " Offering"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px 1px 0px 50px",
                              flex: "0 0 auto",
                              cursor: "pointer",
                            }}
                            onClick={(event) => showModal3(item.loan_id, event)}
                          >
                            {item.credit_score || "-"}
                          </h3>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px 1px 0px 20px",
                              flex: "0 0 auto",
                            }}
                          >
                            {item.request_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }) || "-"}
                          </h3>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              color: "#142C44CC",
                              margin: "5px -90px 0px 0px",
                              flex: "0 0 auto",
                            }}
                          >
                            {item.request_period + " Months" || "-"}
                          </h3>
                        </div>
                        <div
                          key={item.loan_id}
                          style={{
                            textAlign: "center",
                            flexBasis: 0,
                            flexGrow: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "14px",
                            }}
                          >
                            <Button
                              type="primary"
                              size="middle"
                              onClick={() => showModal(item.loan_id)}
                              className={
                                item.express === 1
                                  ? "buttonExpressList"
                                  : "buttonBorrowerList"
                              }
                            >
                              Make an offer
                            </Button>
                            {(item.reject_reason === null ||
                              item.reject_reason === "") && (
                              <button
                                type="default"
                                style={{ width: "100%" }}
                                onClick={() => showModal4(item.loan_id)}
                                className="buttonNotInterestedList"
                              >
                                Not Interested?
                              </button>
                            )}
                          </div>
                          <div style={{ width: "100%" }}>
                            <Button
                              type="default"
                              size="middle"
                              onClick={(event) =>
                                handleDownloadBankStatement(item.loan_id, event)
                              }
                              style={{
                                borderRadius: "15px",
                                marginTop: "10px",
                              }}
                            >
                              Download Bank Statement
                            </Button>
                          </div>
                          <tooltip
                            placement="topRight"
                            title=" Number of hours the request is live"
                          >
                            <CountdownWithHover
                              targetDate={targetDate}
                              renderer={renderer}
                            />
                          </tooltip>
                          <Modal
                            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                            open={modalStates[item.loan_id]}
                            onCancel={() =>
                              setModalStates((prevStates) => ({
                                ...prevStates,
                                [item.loan_id]: false,
                              }))
                            }
                            footer={[
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: 16,
                                }}
                              >
                                {offer_amount.length > 0 &&
                                  offer_interest_rate.length > 0 &&
                                  offer_period.length > 0 &&
                                  type.length > 0 && (
                                    <div>
                                      <Button
                                        type="primary"
                                        onClick={() =>
                                          handleMakeOffer(
                                            item.loan_id,
                                            item.express,
                                            item.user_id,
                                            item.principal_name
                                          )
                                        }
                                      >
                                        Make Offer
                                      </Button>

                                      <Button
                                        type="default"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() =>
                                          showModalRequestDocument(
                                            item.loan_id,
                                            item.express,
                                            item.user_id,
                                            item.principal_name
                                          )
                                        }
                                      >
                                        Request More Document
                                      </Button>
                                    </div>
                                  )}
                              </div>,
                            ]}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <p>Borrower: {item.principal_name || "-"} </p>
                              </div>
                              <div style={{ flex: 1 }}>
                                <p>Loan ID: {item.loan_id} </p>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 16,
                                borderRadius: "10px",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_amount">
                                  Loan Amount
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_amount"
                                  name="offer_amount"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                  }}
                                  onChange={handleLoanAmountChange}
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_interest_rate">
                                  Interest Rate (%)
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_interest_rate"
                                  name="offer_interest_rate"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                  }}
                                  onChange={handleInterestRateChange}
                                />
                                {errorMessage && (
                                  <p style={{ color: "red" }}>{errorMessage}</p>
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 16,
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label htmlFor="offer_period">
                                  Loan Period (Month)
                                </label>
                                <br />
                                <input
                                  type="number"
                                  id="offer_period"
                                  name="offer_period"
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                  }}
                                  onChange={handleLoanPeriodChange}
                                />
                              </div>
                              <div style={{ flex: 1 }}>
                                <label htmlFor="type">Loan Type</label>
                                <br />
                                <select
                                  id="type"
                                  name="type"
                                  style={{
                                    borderRadius: "10px",
                                    width: "73%",
                                  }}
                                  onChange={handleLoanTypeChange}
                                >
                                  <option value="simple">Simple Loan</option>
                                  <option value="effective">
                                    Effective Loan
                                  </option>
                                </select>
                              </div>
                            </div>

                            <hr />

                            <p>
                              Total cost of Financing:{" "}
                              {Number(offer_principal).toFixed(2)}
                            </p>
                            <p>
                              Expected Monthly Installment:{" "}
                              {Number(offer_monthly_installment).toFixed(2)}
                            </p>
                            <p>
                              Guaranteed Revenue:{" "}
                              {Number(offer_revenue).toFixed(2)}
                            </p>
                            <p>
                              Total Admin Fee:{" "}
                              {Number(total_admin_fee).toFixed(2)}
                            </p>
                          </Modal>
                        </div>
                      </Box>
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No results found
                    </div>
                  </div>
                )}
              </div>

              <div>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates2[item.loan_id]}
                      onCancel={() =>
                        setModalStates2((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={
                        [
                          // <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                          //   <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                          // </div>
                        ]
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Name
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.principal_name || "-"}</p>                        */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan ID
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.loan_id || "-"}</p>                        */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Interest Rate
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_interest_rate + "%" || "-"}</p>                         */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Period
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_period + " Month" || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Status
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.status.charAt(0).toUpperCase() + item.status.slice(1) || "-"}</p>                          */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Type
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.type || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Amount
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>                   */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Admin Fee
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.admin_fee + "%" || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Created At
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{formatDate(item.created_at) || "-"}</p>                         */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Updated At
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{formatDate(item.updated_at) || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Principal
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_principal.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>                       */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Revenue
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>  */}
                        </div>
                      </div>

                      <hr />
                    </Modal>
                  ))}

                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates3[item.loan_id]}
                      onCancel={() =>
                        setModalStates3((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={
                        [
                          // <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                          //   <Button type="primary" onClick={() => handleMakeOffer(item.loan_id)}>Make Offer</Button>
                          // </div>
                        ]
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Name
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.principal_name || "-"}</p>                        */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan ID
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.loan_id || "-"}</p>                        */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Interest Rate
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_interest_rate + "%" || "-"}</p>                         */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_interest_rate"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Period
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_period + " Month" || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Status
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.status.charAt(0).toUpperCase() + item.status.slice(1) || "-"}</p>                          */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Type
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.type || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Loan Amount
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>                   */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Admin Fee
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.admin_fee + "%" || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Created At
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{formatDate(item.created_at) || "-"}</p>                         */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Updated At
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{formatDate(item.updated_at) || "-"}</p>  */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_period"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Principal
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_principal.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>                       */}
                        </div>
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="type"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "400",
                              fontSize: "10px",
                              opacity: ".6",
                            }}
                          >
                            Offer Revenue
                          </label>
                          <br />
                          {/* <p style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600", fontSize: "18px", color:"grey" }}>{item.offer_revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD'}) || "-"}</p>  */}
                        </div>
                      </div>

                      <hr />
                    </Modal>
                  ))}
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates4[item.loan_id]}
                      onCancel={() =>
                        setModalStates4((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }))
                      }
                      footer={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 16,
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() =>
                              handleNotInterested(item.loan_id, selectedValue)
                            }
                          >
                            Submit
                          </Button>
                        </div>,
                      ]}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "18px",
                              opacity: ".6",
                            }}
                          >
                            Are you not interested with this loan?{" "}
                          </label>
                          <br />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "14px",
                              opacity: ".6",
                            }}
                          >
                            Please Select one of the option :{" "}
                          </label>

                          <br />
                          <select
                            id="type"
                            name="type"
                            style={{
                              borderRadius: "10px",
                              width: "auto",
                              marginLeft: "0px",
                            }}
                            onChange={handleNotInterestedModal}
                          >
                            <option value="interest_rate_is_too_low">
                              The interest rate is too low
                            </option>
                            <option value="loan_period_is_too_long">
                              The loan period is too long
                            </option>
                            <option value="credit_score_borrower_not_meet_expectation">
                              The credit score of the borrower does not meet my
                              expectation
                            </option>
                            <option value="others">Others</option>
                          </select>
                        </div>
                      </div>
                      {showOtherInput && (
                        <div style={{ marginTop: "16px" }}>
                          <label
                            htmlFor="other_reason"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "14px",
                              opacity: ".6",
                            }}
                          >
                            Please specify:
                          </label>
                          <br />
                          <input
                            type="text"
                            id="other_reason"
                            name="other_reason"
                            style={{
                              borderRadius: "10px",
                              width: "90%",
                              paddingLeft: "10px",
                            }}
                            onChange={(event) =>
                              setSelectedValue(event.target.value)
                            }
                            value={selectedValue}
                          />
                        </div>
                      )}

                      <hr />
                    </Modal>
                  ))}
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          gridColumn="span 3"
          opacity="1.00"
          display="flex"
          style={{
            height: "100vh",
            padding: "15px",
            background:
              "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          }}
        >
          <div
            style={{
              border: "none",
              margin: "0px",
              padding: "0px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FilterAltIcon
                style={{ color: "#142C44CC", marginRight: "5px" }}
              />
              <h4
                style={{
                  color: "#142C44CC",
                  fontWeight: "800",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "18px",
                  margin: "0px",
                }}
              >
                Search Filter
              </h4>
            </div>
            <div className="title-loan-request">
              <h5
                style={{
                  color: "#142C44CC",
                  fontWeight: "700",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "16px",
                  margin: "15px 0px 0px 0px",
                }}
              >
                Loan Amount
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgba(0,0,0,0.4)",
                margin: "5px 0px 0px 0px",
              }}
            >
              <input
                type="number"
                placeholder="MIN"
                value={minLoanAmount}
                onChange={(e) => setMinLoanAmount(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 10px 0px 0px",
                  width: "40%",
                }}
              />
              -
              <input
                type="number"
                placeholder="MAX"
                value={maxLoanAmount}
                onChange={(e) => setMaxLoanAmount(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 0px 0px 10px",
                  width: "40%",
                }}
              />
            </div>
            <br />
            <div style={{ margin: "0px 0px 20px 0px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox1"
                  checked={selectedCheckbox === "checkbox1"}
                  onChange={() =>
                    handleLoanAmountCheckbox("checkbox1", 0, 1000)
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  for="checkbox1"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  $0 - $1000
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox2"
                  checked={selectedCheckbox === "checkbox2"}
                  onChange={() =>
                    handleLoanAmountCheckbox("checkbox2", 1000, 5000)
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox2"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  $1000 - $5000
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox3"
                  checked={selectedCheckbox === "checkbox3"}
                  onChange={() =>
                    handleLoanAmountCheckbox("checkbox3", 5000, 10000)
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox3"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  $5000 - $10000
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox4"
                  checked={selectedCheckbox === "checkbox4"}
                  onChange={() =>
                    handleLoanAmountCheckbox("checkbox4", 10000, 15000)
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox4"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  $10000 - $15000
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox5"
                  checked={selectedCheckbox === "checkbox5"}
                  onChange={() =>
                    handleLoanAmountCheckbox("checkbox5", 50000, "")
                  }
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox5"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  &gt; $50,000
                </label>
              </div>
            </div>
            <div className="title-loan-request">
              <h5
                style={{
                  color: "#142C44CC",
                  fontWeight: "700",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "16px",
                  margin: "15px 0px 0px 0px",
                }}
              >
                Credit Score
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgba(0,0,0,0.4)",
                margin: "5px 0px 0px 0px",
              }}
            >
              <input
                type="number"
                placeholder="MIN"
                value={creditScoreMin}
                onChange={(e) => setCreditScoreMin(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 10px 0px 0px",
                  width: "40%",
                }}
              />
              -
              <input
                type="number"
                placeholder="MAX"
                value={creditScoreMax}
                onChange={(e) => setCreditScoreMax(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 0px 0px 10px",
                  width: "40%",
                }}
              />
            </div>
            <div className="title-loan-request">
              <h5
                style={{
                  color: "#142C44CC",
                  fontWeight: "700",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "16px",
                  margin: "15px 0px 0px 0px",
                }}
              >
                Loan Period
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgba(0,0,0,0.4)",
                margin: "5px 0px 0px 0px",
              }}
            >
              <input
                type="number"
                placeholder="MIN"
                value={LoanPeriodMin}
                onChange={(e) => setLoanPeriodMin(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 10px 0px 0px",
                  width: "40%",
                }}
              />
              -
              <input
                type="number"
                placeholder="MAX"
                value={LoanPeriodMax}
                onChange={(e) => setLoanPeriodMax(e.target.value)}
                style={{
                  border: "none",
                  outline: "1px solid lightgrey",
                  borderRadius: "5px",
                  textAlign: "center",
                  margin: "0px 0px 0px 10px",
                  width: "40%",
                }}
              />
            </div>
            <div className="title-loan-request">
              <h5
                style={{
                  color: "#142C44CC",
                  fontWeight: "700",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "16px",
                  margin: "15px 0px 0px 0px",
                }}
              >
                Loan Category
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                gap: "0px",
                alignItems: "center",
                margin: "10px 0px 0px 0px",
              }}
            >
              <input
                type="checkbox"
                id="checkbox9"
                onChange={(e) =>
                  handleCheckboxChange(e.target.checked, "business")
                }
                style={{ margin: "0px 10px 0px 0px" }}
              />
              <label
                htmlFor="checkbox9"
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                }}
              >
                Business Loan
              </label>
              {/* <br />
     <input type="checkbox" id="checkbox10" onChange={(e) => handleCheckboxChange(e.target.checked, 'other')} />
     <label htmlFor="checkbox10" style={{ fontWeight: '500', fontFamily: 'Poppins, sans-serif', fontSize: '15px' }}>Other Loan</label> */}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default LoanRequestList;
